import React, {Component, createRef} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import {Formik} from "formik";
import 'react-dropzone-uploader/dist/styles.css'
import {jsonToBlob} from "../../../../../utils/Utils";
import {topAttractionsListPage} from "../../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormField,
    FormFieldDisabled,
    FormSelectField, FormTextAreaField,
    SingleDropZoneUploader,
    SortOrderField
} from "../../../../../components/CMSFormComponents";
import {ModalView} from "../../../../../components/BootstrapModal";
import TopAttractionsService from "../../../../../services/Shop/TopAttractionsService";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import RichTextEditor from "../../../../../components/RichTextEditor";

class TopAttractionsUpdate extends Component {

    state = {
        id: null,
        name: '',
        latitude: 0,
        longitude: 0,
        description: '',
        imageUrl: null,
        imageFile: null,
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id,
        disabled: false
    }

    backButtonRef = createRef();

    dropZone = createRef();

    richText = createRef();

    validationSchema = Yup.object().shape({
        name: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        latitude: Yup.number().min(1, "Too Short").max(255, "Too Long").required("Required"),
        longitude: Yup.number().min(1, "Too Short").max(255, "Too Long").required("Required"),
        description: Yup.string(),
        artistNameId: null,
        file: null
    });

    componentDidMount() {
        if(this.state.isEditMode){
            TopAttractionsService.getTopAttraction(this.props.match.params.id).then(
                item => this.setState({id: item.data.id, name: item.data.name, latitude: item.data.latitude, longitude: item.data.longitude, description: item.data.description, imageUrl: item.data.url, disabled: item.data.disabled})
            )
        }
    }

    handleChangeStatus = ({ meta, file }, status) => {
        if(status === "done"){
            this.setState({imageFile: file})
        }
        if(status === "removed"){this.setState({imageFile: null})}
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        // if(!this.state.imageFile && !this.state.isEditMode){
        //     setFieldError('file', 'Upload a valid File');
        //     setSubmitting(false);
        //     return;
        // }

        let formData = new FormData();
        const jsonPayload = {name: values.name, latitude: values.latitude, longitude: values.longitude, description: values.description, disabled: this.state.disabled, id: this.state.isEditMode ? this.state.id : null};
        formData.append('payload', jsonToBlob(jsonPayload));
        if(this.state.imageFile) formData.append('file', this.state.imageFile);

        const restServiceApi = this.state.isEditMode ? TopAttractionsService.putTopAttraction : TopAttractionsService.postTopAttraction;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.name + " is Updated." : "A New Top Attraction: " + res.data.name + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({ name: '', latitude: 0, description: '', longitude: 0, saveAndNew: false, disabled: false, sortOrder: 100});
                    this.setState({imageFile: null, saveAndNew: false, parentInputValue: '', parentId: -1});
                    this.dropZone.current.files = [];
                    this.dropZone.current.forceUpdate()
                }
                else this.props.history.push(topAttractionsListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        TopAttractionsService.deleteTopAttraction(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.name)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>}
                        <Formik initialValues={{ name: this.state.name, latitude: this.state.latitude, longitude: this.state.longitude, description: this.state.description }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div>{this.state.imageUrl && <ArtistImage photoUrl={this.state.imageUrl} size={5}/>}</div>
                                    <div className="row">
                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>}
                                        <FormField name="Name" required={true} touched={touched.name} errors={errors.name} value={values.name} setFieldValue={setFieldValue} fieldName='name' onBlurHandler={setFieldTouched}/>
                                        <FormField name="Latitude" required={true} touched={touched.latitude} errors={errors.latitude} value={values.latitude} setFieldValue={setFieldValue} fieldName='latitude' onBlurHandler={setFieldTouched}/>
                                        <FormField name="Longitude" required={true} touched={touched.longitude} errors={errors.longitude} value={values.longitude} setFieldValue={setFieldValue} fieldName='longitude' onBlurHandler={setFieldTouched}/>
                                        {/*<FormField name="Description" required={true} touched={touched.description} errors={errors.description} value={values.description} setFieldValue={setFieldValue} fieldName='description' onBlurHandler={setFieldTouched}/>*/}
                                        <FormTextAreaField name="Description" touched={touched.description} errors={errors.description} value={values.description} setFieldValue={setFieldValue} fieldName='description' onBlurHandler={setFieldTouched}/>
                                        <FormSelectField label="Status" value={this.state.disabled ? "Disabled" : "Enabled"} onChange={data => this.setState(data === "Disabled" ? { disabled: true} : { disabled: false})} list={["Enabled", "Disabled"]}/>
                                    </div>
                                    <div className="SingleImageUploadPanel" style={errors.file ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>
                                        <label style={{float: "left", padding: "5px"}}>Thumbnail*</label>
                                        <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.file}</div>
                                        <SingleDropZoneUploader accept="image/*" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={this.state.isEditMode ? "Update Thumbnail" : "Drag Image or Click to Browse"}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.name}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.name}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(TopAttractionsUpdate);