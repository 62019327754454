import React, {Component, createRef} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import {
    AddIcon,
    BackFontAwesome,
    RemoveFontAwesome,
    SaveFontAwesome
} from "../../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import classesTab from '../../../../../components/Store/Product/productImageSmallThumbnail.module.css'
import {Formik} from "formik";
import 'react-dropzone-uploader/dist/styles.css'
import {jsonToBlob} from "../../../../../utils/Utils";
import {hotelsListPage,} from "../../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FacilitiesCMSComponent,
    FormField,
    FormFieldDisabled,
    FormSelectField,
    ImageUploaderCustom, LocationCMSComponent, SingleAutoCompleteCMSComponent,
    SortOrderField,
    TopAttractionsCMSComponent
} from "../../../../../components/CMSFormComponents";
import {ModalView} from "../../../../../components/BootstrapModal";
import RichTextEditor from "../../../../../components/RichTextEditor";
import FacilityService from "../../../../../services/Shop/FacilityService";
import HotelPhoneNumber from "../../../../../components/Store/HotelPhoneNumber";
import HotelService from "../../../../../services/Shop/HotelService";
import DnDImageList from "../../../../../components/Store/DnDImageList";
import TopAttractionsService from "../../../../../services/Shop/TopAttractionsService";
import HotelSocialLink from "../../../../../components/Store/HotelSocialLink";
import Room from "../../../../../components/Store/Room";
import UserAdminService from "../../../../../services/UserAdminService";

class HotelUpdate extends Component {

    state = {
        id: null,
        name: '',
        location: '',
        shortDescription: '',
        imageFiles: [{file: null, localUrl: null}],
        imageUrls: [],
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id,
        disabled: false,
        sortOrder: 100,
        averageRating: 0,
        starRating: "Undefined",
        latitude: 0,
        longitude: 0,
        actualLocation: true,
        facilities: [],
        description: '',
        hotelRules: '',
        email: '',
        // STOCK STATUS
        hotelStatus: 'OPEN',
        // ATTRIBUTES
        phoneNumbers: [{phoneNumber: "", description: ""}],
        rooms: [{name: "", price: "", description: ""}],
        socialLinks: [{socialType: "FACEBOOK", url: ""}],
        // TAGS
        topAttractionsInputValue: '',
        topAttractions: [],
        // TAGS
        relatedProductsInputValue: '',
        relatedProducts: [],
        //AUTOCOMPLETE
        userInputValue: '',
        userId: -1,

    }

    backButtonRef = createRef();
    richText = createRef();

    generalTab = createRef();
    roomsTab = createRef();
    linksTab = createRef();
    attributesTab = createRef();
    imagesTab = createRef();

    validationSchema = Yup.object().shape({
        name: Yup.string(),
        averageRating: Yup.number().min(1).max(5),
        latitude: Yup.number().min(-90).max(90),
        longitude: Yup.number().min(-180).max(180),
        file: null
    });

    componentDidMount() {
        if(this.state.isEditMode){
            HotelService.getHotel(this.props.match.params.id).then(
                item => {
                    this.setState({id: item.data.id, name: item.data.name, location: item.data.location, shortDescription: item.data.shortDescription, userInputValue: item.data.user ? item.data.user.username : '', userId: item.data.user ? item.data.user.id : -1, disabled: item.data.disabled, sortOrder: item.data.sortOrder, description: item.data.hotelDetail.description, hotelRules: item.data.hotelDetail.hotelRules, email: item.data.hotelDetail.email, topAttractions: item.data.hotelDetail.topAttractions, relatedProducts: item.data.relatedProducts, averageRating: item.data.averageRating, latitude: item.data.latitude, longitude: item.data.longitude, actualLocation: item.data.actualLocation, hotelStatus: item.data.hotelStatus,
                        phoneNumbers: item.data.hotelDetail.phoneNumbers,
                        rooms: item.data.rooms,
                        socialLinks: item.data.hotelDetail.socialLinks,
                        imageUrls: item.data.hotelImages});
                    switch (item.data.starRating) {
                        case "0":
                            this.setState({starRating: "Undefined"});
                            break;
                        default: this.setState({starRating: item.data.starRating + " Star"});
                    }
                    let facilitiesSelected = item.data.hotelDetail.facilityDescriptions;
                    FacilityService.getPagedFacilities({page: 0, sortField: 'id', sortOrder: 'asc'}).then(
                        res => {
                            let facilities = res.data.content;
                            facilities = facilities.map(f => {
                                let el = facilitiesSelected.find(fs => f.id === fs.facility.id);
                                if(el) return {id: el.id, facilityId: f.id, name: f.name, checked: true, description: el.description};
                                return {facilityId: f.id, name: f.name, checked: false, description: ''};
                            });
                            this.setState({facilities: facilities});
                        }
                    );
                }
            )
        } else {
            FacilityService.getPagedFacilities({page: 0, sortField: 'id', sortOrder: 'asc'}).then(
                res => {
                    let facilityTemp = [];
                    res.data.content.map(facility => {
                        facilityTemp.push({facilityId: facility.id, name: facility.name, checked: false, description: ''});
                    });
                    this.setState({facilities: facilityTemp});
                }
            );
        }
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);

        // GENERAL
        if(!this.state.location || this.state.location === ""){
            toast.error("General > Please provide Location!");
            setSubmitting(false);
            this.generalTab.current.click();
            return;
        }
        if(values.latitude === 0 || values.longitude === 0){
            toast.error("Data > Please provide Latitude & Longitude!");
            setSubmitting(false);
            return;
        }

        //Images
        if(!this.state.imageFiles[0].file && this.state.imageUrls.length === 0){
            toast.error("Images: Upload at least an Image!");
            setSubmitting(false);
            this.imagesTab.current.click();
            return;
        }

        let facilitiesDescTemp = [];
        this.state.facilities.map(facility => {
            if(facility.checked) {
                facilitiesDescTemp.push({id: facility.id, facility: {id: facility.facilityId}, description: facility.description});
            }
        });

        const socialLinks = this.state.socialLinks.filter(sl => sl.url !== null && sl.url !== "");
        let phoneNumbers = this.state.phoneNumbers.filter(pn => pn.phoneNumber !== null && pn.phoneNumber !== "");
        phoneNumbers = phoneNumbers.map(pn => { pn.phoneNumber = parseInt(pn.phoneNumber.toString().replace(/\D/g, '')); return pn;});
        if(phoneNumbers.length === 0){
            toast.error("Attributes: Please provide at least one phone number!");
            setSubmitting(false);
            this.linksTab.current.click();
            return;
        }

        let rooms = this.state.rooms.filter(room => room.price !== null && room.price !== "" && room.name != null && room.name != "");
        rooms = this.state.rooms.map(room => {
            return room
        });
        // this.state.rooms.forEach(room => {
        //     if((room.name == "" && room.price != "") || (room.name != "" && room.price == "")){
        //         toast.error("Rooms: Please provide both Name and Price!");
        //         setSubmitting(false);
        //         this.roomsTab.current.click();
        //         return;
        //     }
        // })
        // if(rooms.length === 0){
        //     toast.error("Rooms: Please provide at least one room!");
        //     setSubmitting(false);
        //     this.linksTab.current.click();
        //     return;
        // }
        let tempStarRating;
        if(this.state.starRating === "Undefined") tempStarRating = 0;
        else tempStarRating = this.state.starRating.substr(0,  1);

        let formData = new FormData();
        let jsonPayload = {name: values.name, location: this.state.location, shortDescription: this.state.shortDescription, sortOrder: this.state.sortOrder, rooms: rooms, disabled: this.state.disabled, hotelDetail: {description: this.state.description, hotelRules: this.state.hotelRules, email: this.state.email, phoneNumbers: phoneNumbers, socialLinks: socialLinks, topAttractions: this.state.topAttractions, facilityDescriptions: facilitiesDescTemp}, averageRating: values.averageRating, latitude: values.latitude, longitude: values.longitude, actualLocation: this.state.actualLocation, relatedProducts: this.state.relatedProducts, hotelStatus: this.state.hotelStatus, starRating: tempStarRating, hotelImages: this.state.imageUrls, id: this.state.isEditMode ? this.state.id : null};
        if(this.state.userId !== -1) jsonPayload.user = {id: this.state.userId}

        formData.append('payload', jsonToBlob(jsonPayload));
        // if(this.state.imageFiles) formData.append('file', this.state.imageFiles[0].file);

        this.state.imageFiles.forEach(image => {
            if(image.file){
                formData.append('images[]', image.file)
            }
        });

        const restServiceApi = this.state.isEditMode ? HotelService.putHotel : HotelService.postHotel;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.name + " is Updated." : "A New Product: " + res.data.name + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({ saveAndNew: false, topAttractionsInputValue: '', relatedProductsInputValue: '', userId: -1, location: '', userInputValue: "", shortDescription: '', topAttractions: [], relatedProducts: [], disabled: false, sortOrder: 100, description: '', hotelRules: '', email: ''});
                    this.richText.current.reset();
                    this.dropZone.current.files = [];
                    this.dropZone.current.forceUpdate()
                }
                else this.props.history.push(hotelsListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        HotelService.deleteHotel(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.name)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    handlePhoneNumberChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...this.state.phoneNumbers];
        list[index][name] = value;
        this.setState({phoneNumbers: list});
    }

    handlePhoneNumberRemove = (index) => {
        const list = [...this.state.phoneNumbers];
        list.splice(index, 1);
        this.setState({phoneNumbers: list});
    }

    handleRoomChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...this.state.rooms];
        list[index][name] = value;
        this.setState({rooms: list});
    }

    handleRoomDescriptionChange = (val, index) => {
        const list = [...this.state.rooms];
        list[index]["description"] = val;
        this.setState({rooms: list});
    }

    handleRoomRemove = (index) => {
        const list = [...this.state.rooms];
        list.splice(index, 1);
        this.setState({rooms: list});
    }

    handleSocialLinkChange = (data, index, field) => {
        const list = [...this.state.socialLinks];
        list[index][field] = data;
        this.setState({socialLinks: list});
    }

    handleSocialLinkRemove = (index) => {
        const list = [...this.state.socialLinks];
        list.splice(index, 1);
        this.setState({socialLinks: list});
    }

    handleFacilitiesChecked = (id, e) => {
        let list = [...this.state.facilities];
        list.map(f => {
            if(f.facilityId !== id) return f;
            f.checked = !f.checked;
            return f;
        })
        this.setState({facilities: list});
    }

    handleFacilitiesDescriptionChange = (id, e) => {
        let list = [...this.state.facilities];
        list.map(f => {
            if(f.facilityId !== id) return f;
            f.description = e.target.value;
            return f;
        })
        this.setState({facilities: list});
    }

    topAttractionsRemove = (index) => {
        const list = this.state.topAttractions;
        list.splice(index, 1);
        this.setState({topAttractions: list});
    }

    relatedProductsRemove = (index) => {
        const list = this.state.relatedProducts;
        list.splice(index, 1);
        this.setState({relatedProducts: list});
    }

    handleImageChange = (event, index) => {
        let list = [...this.state.imageFiles];

        for (const [key, value] of Object.entries(event.target.files)) {
            list[list.length - 1]["file"] = value;
            list[list.length - 1]["localUrl"] = URL.createObjectURL(value);
            list = [...list, {file: null, localUrl: null}];
        }

        // for (const [key, value] of Object.entries(event.target.files)) {
        //     list[list.length - 1]["file"] = value;
        //     list[list.length - 1]["localUrl"] = URL.createObjectURL(value);
        //     this.setState({imageFiles: [...list, {file: null, localUrl: null}]});
        //     index++;
        // }
        // list[index]["file"] = event.target.files[0];
        // list[index]["localUrl"] = URL.createObjectURL(event.target.files[0]);
        // this.setState({imageFiles: [...list, {file: null, localUrl: null}]});
        this.setState({imageFiles: list});
    }

    handleImageRemove = (index) => {
        const list = [...this.state.imageFiles];
        list.splice(index, 1);
        this.setState({imageFiles: [...list]});
    }

    handleImageOnDragEnd =(result) => {
        if (!result.destination || result.source.index === result.destination.index) return;

        const items = Array.from(this.state.imageUrls);

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        this.setState({imageUrls: items});
    }

    onRemoveImagePreview = (index) => {
        const items = Array.from(this.state.imageUrls);
        items.splice(index, 1);
        this.setState({imageUrls: items});
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove Hotel</button>}
                        <Formik initialValues={{ name: this.state.name, averageRating: this.state.averageRating, latitude: this.state.latitude, longitude: this.state.longitude }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a ref={this.generalTab} className={"nav-link active " + classesTab.Tabs} id="general-tab" data-toggle="tab" href="#general" role="tab"
                                               aria-controls="general" aria-selected="true">General</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + classesTab.Tabs} id="data-tab" data-toggle="tab" href="#data" role="tab"
                                               aria-controls="data" aria-selected="false">Data</a>
                                        </li>
                                        <li className="nav-item">
                                            <a ref={this.roomsTab} className={"nav-link " + classesTab.Tabs} id="rooms-tab" data-toggle="tab" href="#rooms" role="tab"
                                               aria-controls="rooms" aria-selected="false">Rooms</a>
                                        </li>
                                        <li className="nav-item">
                                            <a ref={this.linksTab} className={"nav-link " + classesTab.Tabs} id="links-tab" data-toggle="tab" href="#links" role="tab"
                                               aria-controls="links" aria-selected="false">Links</a>
                                        </li>
                                        <li className="nav-item">
                                            <a ref={this.attributesTab} className={"nav-link " + classesTab.Tabs} id="data-attributes" data-toggle="tab" href="#attributes" role="tab"
                                               aria-controls="attributes" aria-selected="false">Attributes</a>
                                        </li>
                                        <li className="nav-item">
                                            <a ref={this.imagesTab} className={"nav-link " + classesTab.Tabs} id="data-images" data-toggle="tab" href="#images" role="tab"
                                               aria-controls="images" aria-selected="false">Images</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {/*GENERAL*/}
                                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    General Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>}
                                                        <FormField name="Product Name" required={true} autocomplete="off" touched={touched.name} errors={errors.name} value={values.name} setFieldValue={setFieldValue} fieldName='name' onBlurHandler={setFieldTouched}/>
                                                        <LocationCMSComponent title="Location" searchText={this.state.location} autocomplete="off" setSearchText={value => this.setState({location: value})} errors={errors.location}
                                                            fieldName={"location"} valueSelectField={"id"}
                                                            valueSelectCallback={(value) => {this.setState({location: value})}}
                                                            entityServiceSearchCall={HotelService.searchForHotelLocations}/>
                                                        <div className="col-md-6" style={{marginTop: "10px"}}>
                                                            <label htmlFor="shortDescription" style={{float: "left"}}>Short Description</label>
                                                            <textarea value={this.state.shortDescription} id="shortDescription" onChange={(e) => this.setState({shortDescription: e.target.value})} className={"form-control"} placeholder="Short Description"/>
                                                        </div>
                                                        <RichTextEditor value={this.state.description} ref={this.richText} onChange={value => this.setState({description: value})} title="Description*" placeholder="Description..."/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Data*/}
                                        <div className="tab-pane fade" id="data" role="tabpanel" aria-labelledby="data-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Data Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <FormField name="Latitude" required={true} type="number" touched={touched.latitude} errors={errors.latitude} value={values.latitude} setFieldValue={setFieldValue} fieldName='latitude' onBlurHandler={setFieldTouched}/>
                                                        <FormField name="Longitude" required={true} type="number" touched={touched.longitude} errors={errors.longitude} value={values.longitude} setFieldValue={setFieldValue} fieldName='longitude' onBlurHandler={setFieldTouched}/>
                                                        <FormSelectField label="Actual Location?" value={this.state.actualLocation ? "Yes" : "No"} onChange={data => this.setState(data === "No" ? { actualLocation: false} : { actualLocation: true})} list={["Yes", "No"]}/>
                                                        <FormField name="Average Rating" required={true} type="number" touched={touched.averageRating} errors={errors.averageRating} value={values.averageRating} setFieldValue={setFieldValue} fieldName='averageRating' onBlurHandler={setFieldTouched}/>
                                                        <FormSelectField label="Hotel Status" value={this.state.hotelStatus} onChange={data => this.setState({ hotelStatus: data})} list={["OPEN", "NOT_OPEN", "HOLIDAY", "OPENING_SOON"]}/>
                                                        <FormSelectField label="Star" value={this.state.starRating} onChange={data => this.setState({ starRating: data})} list={["Undefined", "1 Star", "2 Star", "3 Star", "4 Star", "5 Star"]}/>
                                                        <SortOrderField value={this.state.sortOrder} onChange={value => this.setState({sortOrder: value})}/>
                                                        <FormSelectField label="Status" value={this.state.disabled ? "Disabled" : "Enabled"} onChange={data => this.setState(data === "Disabled" ? { disabled: true} : { disabled: false})} list={["Enabled", "Disabled"]}/>
                                                        <SingleAutoCompleteCMSComponent title="Hotel Manager (Username)" autocomplete="off" searchText={this.state.userInputValue} setSearchText={value => this.setState({userInputValue: value})} errors={errors.userId}
                                                            fieldName={"username"} valueSelectField={"id"}
                                                            selectedId={this.state.userId}
                                                            valueSelectCallback={(id, value) => {this.setState({userId: id}); this.setState({userInputValue: value});}}
                                                            valueClearCallback={() => {this.setState({userId: -1}); this.setState({userInputValue: ''});}}
                                                            entityServiceSearchCall={UserAdminService.searchForUsersByUsername}/>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/*Rooms*/}
                                        <div className="tab-pane fade" id="rooms" role="tabpanel" aria-labelledby="rooms-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Rooms Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12" style={{marginTop: "10px"}}>
                                                            <div className="card-body">
                                                            <div className="row" style={{marginBottom: "20px"}}>
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Room Name</th>
                                                                        <th scope="col">Price</th>
                                                                        <th scope="col"></th>
                                                                        <th></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.rooms.map((room, index) =>
                                                                        <Room key={index} length={this.state.rooms.length} onChange={e => this.handleRoomChange(e, index)} onDescriptionChange={(val, index) => this.handleRoomDescriptionChange(val, index)} index={index} name={room.name} price={room.price} description={room.description} onRemove={index => this.handleRoomRemove(index)}/>
                                                                    )}
                                                                    </tbody>
                                                                </table>
                                                                <button type="button" style={{margin: "0 auto"}} onClick={() => this.setState({rooms: [...this.state.rooms, {name: "", price: "", description: ""}]})} className="btn btn-primary"><AddIcon/> Add a Room</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Links*/}
                                        <div className="tab-pane fade" id="links" role="tabpanel" aria-labelledby="links-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Links Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12" style={{marginTop: "10px"}}>
                                                            <label htmlFor="email" style={{float: "left"}}>Email</label>
                                                            <input value={this.state.email} id="email" onChange={(e) => this.setState({email: e.target.value})} className={"form-control"} placeholder="Email"/>
                                                            <div className="card-body">
                                                                <div className="row" style={{marginBottom: "20px"}}>
                                                                    <table className="table">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Phone Number</th>
                                                                            <th scope="col">Description</th>
                                                                            <th></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.phoneNumbers.map((phoneNumber, index) =>
                                                                            <HotelPhoneNumber key={index} length={this.state.phoneNumbers.length} onChange={e => this.handlePhoneNumberChange(e, index)} index={index} attribute={phoneNumber.phoneNumber} text={phoneNumber.description} onRemove={index => this.handlePhoneNumberRemove(index)}/>
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                    <button type="button" style={{margin: "0 auto"}} onClick={() => this.setState({phoneNumbers: [...this.state.phoneNumbers, {phoneNumber: "", description: ""}]})} className="btn btn-primary"><AddIcon/> Add a Phone Number</button>
                                                                </div>
                                                                <div className="row">
                                                                    <table className="table">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Social Type</th>
                                                                            <th scope="col">URL</th>
                                                                            <th></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.socialLinks.map((socialLink, index) =>
                                                                            <HotelSocialLink key={index} length={this.state.socialLinks.length} onChange={(data, i, field) => this.handleSocialLinkChange(data, i, field)} index={index} attribute={socialLink.socialType} text={socialLink.url} onRemove={index => this.handleSocialLinkRemove(index)}/>
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                    <button type="button" style={{margin: "0 auto"}} onClick={() => this.setState({socialLinks: [...this.state.socialLinks, {socialType: "FACEBOOK", url: ""}]})} className="btn btn-primary"><AddIcon/> Add a Social Link</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<RelatedMultiAutoCompleteCMSComponent required title="Related Products" autocomplete="off" searchText={this.state.relatedProductsInputValue} setSearchText={value => this.setState({relatedProductsInputValue: value})} errors={errors.relatedProducts}*/}
                                                        {/*       fieldName={"name"} valueSelectField={"id"}*/}
                                                        {/*       selectedIds={this.state.relatedProducts}*/}
                                                        {/*       valueSelectCallback={(id, value) => {this.setState({relatedProducts: [...this.state.relatedProducts, {id, name: value}]}); this.setState({relatedProductsInputValue: ""});}}*/}
                                                        {/*       onRemove={(index) => this.relatedProductsRemove(index)}*/}
                                                        {/*       valueClearCallback={() => {this.setState({relatedProducts: []}); this.setState({relatedProductsInputValue: ''});}}*/}
                                                        {/*       entityServiceSearchCall={HotelService.searchForArtist}/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Attributes*/}
                                        <div className="tab-pane fade" id="attributes" role="tabpanel" aria-labelledby="attributes-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Attributes
                                                </div>
                                                <TopAttractionsCMSComponent title="Top Attractions" searchText={this.state.topAttractionsInputValue} autocomplete="off" setSearchText={value => this.setState({topAttractionsInputValue: value})} errors={errors.topAttractions}
                                                    fieldName={"name"} valueSelectField={"id"}
                                                    addNewTag={() => {this.setState({topAttractions: [...this.state.topAttractions, {id: -1, name: this.state.topAttractionsInputValue}]}); this.setState({topAttractionsInputValue: ""});}}
                                                    selectedIds={this.state.topAttractions}
                                                    valueSelectCallback={(id, value) => {this.setState({topAttractions: [...this.state.topAttractions, {id, name: value}]}); this.setState({topAttractionsInputValue: ""});}}
                                                    onRemove={(index) => this.topAttractionsRemove(index)}
                                                    valueClearCallback={() => {this.setState({topAttractions: []}); this.setState({topAttractionsInputValue: ''});}}
                                                    entityServiceSearchCall={TopAttractionsService.searchForTopAttractions}/>
                                                <FacilitiesCMSComponent
                                                    facilities={this.state.facilities}
                                                    facilitiesSelected={this.state.facilitiesSelected}
                                                    onCheck={this.handleFacilitiesChecked}
                                                    onDescriptionChange={this.handleFacilitiesDescriptionChange}/>
                                                <RichTextEditor value={this.state.hotelRules} ref={this.richText} onChange={value => this.setState({hotelRules: value})} title="Hotel Rules" placeholder="Hotel Rules..."/>
                                            </div>
                                        </div>
                                        {/*IMAGES*/}
                                        <div className="tab-pane fade" id="images" role="tabpanel" aria-labelledby="images-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Images Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <table className="table">
                                                            <tbody>
                                                                {/*{this.state.imageUrls.map((image, index) =>*/}
                                                                {/*    <DnDImageList image={image} index={index}/>*/}
                                                                {/*)}*/}
                                                                <DnDImageList imageUrls={this.state.imageUrls} onRemoveImagePreview={this.onRemoveImagePreview} imageOnDragEnd={this.handleImageOnDragEnd}/>
                                                                <h2>Image Uploader</h2>
                                                                {this.state.imageFiles.map((image, index) =>
                                                                    <ImageUploaderCustom onChange={e => this.handleImageChange(e, index)} localUrl={image.localUrl} index={index} size={this.state.imageFiles.length} file={image.file} sortOrder={image.sortOrder} onRemove={index => this.handleImageRemove(index)}/>
                                                                    // <ImageUploaderCustom onChange={e => this.handleAttributeChange(e, index)} index={index} attribute={attribute.attribute} text={attribute.text} onRemove={index => this.handleAttributeRemove(index)}/>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="SingleImageUploadPanel" style={errors.file ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>*/}
                                    {/*    <label style={{float: "left", padding: "5px"}}>Thumbnail*</label>*/}
                                    {/*    <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.file}</div>*/}
                                    {/*    <SingleDropZoneUploader accept="image/*" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={this.state.isEditMode ? "Update Thumbnail" : "Drag Image or Click to Browse"}/>*/}
                                    {/*</div>*/}
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.name}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.name}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(HotelUpdate);