import React from 'react';
import {PRIMARY} from '../../constants/Colors'

const Spinner = (props) => {
    return (
        <div className="text-center">
            <div className="spinner-border" style={{width: props.size + "rem", height: props.size + "rem", color: PRIMARY}} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Spinner;