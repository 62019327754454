import React, {Component} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import Pagination from "../../../../../components/DashboardComponents/Pagination";
import {RemoveFontAwesome, SortIcon} from "../../../../../components/FontAwesomeComponent";
import classes from '../../Artists/styles/artist.module.css'
import queryString from 'query-string';
import {Link, NavLink} from "react-router-dom";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from 'react-redux'

import {
    DateFormatCMSComponent,
    DateTimePicker, InputCMSComponent,
    NepaliCurrency,
    ResetCMSButton,
    SearchCMSComponent, SelectCMSComponent,
    SubmitCMSButton
} from "../../../../../components/CMSFormComponents";
import UserAdminService from "../../../../../services/UserAdminService";
import OrderService from "../../../../../services/Shop/OrderService";
import {ModalView} from "../../../../../components/BootstrapModal";

class Orders extends Component{

    state = {
        entities: [],
        page: 0,
        sortField: 'id',
        sortOrder: 'desc',
        totalPages: 0,
        noEntity: false,
        deleteEntity: {id: -1, username: ''},
        // USERNAME
        usernameInputValue: '',
        username: '',
        // AUDIO-PRODUCTION-TYPE
        townInputValue: '',
        town: '',
        // DATE
        startDateInputValue: null,
        startDate: null,
        endDateInputValue: null,
        endDate: null,
        // STATUS
        orderStatus: '',
        orderStatusInputValue: '',
        orderStatusList: []
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        if(JSON.stringify(params) !== '{}'){
            let tempState = {};
            if(params.username){
                tempState.username = params.username;
            }
            if(params.town){
                tempState.town = params.town;
            }
            if(params.sort){
                const values = params.sort.split(",");
                tempState.sortField = values[0];
                tempState.sortOrder = values[1];
            }
            if(params.page){
                tempState.page = +params.page;
            }
            if(params.startDate){
                tempState.startDate = params.startDate;
            }
            if(params.endDate){
                tempState.endDate = params.endDate;
            }
            if(params.orderStatus){
                tempState.orderStatus = params.orderStatus;
            }
            this.setState(tempState);
            if(tempState.page === 0 ) this.getEntities()
        } else {
            this.getEntities();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.page !== this.state.page || prevState.sortField !== this.state.sortField || prevState.sortOrder !== this.state.sortOrder || prevState.username !== this.state.username || prevState.town !== this.state.town || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate || prevState.orderStatus !== this.state.orderStatus){
            this.getEntities();
            let url = `${this.props.location.pathname}?page=${this.state.page}&sort=${this.state.sortField},${this.state.sortOrder}`;
            url += this.state.username && this.state.username !== '' ? `&username=${this.state.username}`: '';
            url += this.state.town && this.state.town !== '' ? `&town=${this.state.town}`: '';
            url += this.state.startDate && this.state.startDate !== '' ? `&startDate=${this.state.startDate}`: '';
            url += this.state.endDate && this.state.endDate !== '' ? `&endDate=${this.state.endDate}`: '';
            url += this.state.orderStatus && this.state.orderStatus !== '' ? `&orderStatus=${this.state.orderStatus}`: '';
            this.props.history.push(url);
        }
    }

    getEntities = () => {
        this.setState({noEntity: false})
        OrderService.getPagedOrders({page: this.state.page, sortField: this.state.sortField, sortOrder: this.state.sortOrder, username: this.state.username, town: this.state.town, startDate: this.state.startDate, endDate: this.state.endDate, orderStatus: this.state.orderStatus}).then(
            res => {
                this.setState({entities: [...res.data.content], totalPages: res.data.totalPages, noEntity: res.data.content.length === 0 ? true : false});
            }
        );
        OrderService.getOrderStatuses().then(res => this.setState({orderStatusList: ["Select Status", ...res.data]}));
    }

    handlePageClick = ({selected}) => {
        this.setState({page: selected});
    }

    onSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.setState({page: 0, username: this.state.usernameInputValue, town: this.state.townInputValue, startDate: this.state.startDateInputValue, endDate: this.state.endDateInputValue, orderStatus: this.state.orderStatusInputValue});
    }

    onResetSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.setState({page: 0, sortField: 'id', sortOrder: 'desc', usernameInputValue: '', username: '', townInputValue: '', town: '', startDateInputValue: null, startDate: null, endDateInputValue: null, endDate: null, orderStatus: ''});
    }

    sortByHandler = (field) => {
        if(this.state.sortField === field){
            this.setState({sortOrder: this.state.sortOrder === 'asc' ? 'desc' : 'asc', page : 0});
        } else {
            this.setState({sortField: field, sortOrder: 'asc', page: 0});
        }
    }

    deleteEntity = (id) => {
        OrderService.deleteOrder(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted Order: " + res.data.id)
                this.getEntities();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    TopToolbar = () => {
        return (<>
                <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", clear: "both"}}>
                    <SearchCMSComponent title="Username" searchText={this.state.usernameInputValue} fieldName={"username"} setSearchText={(value) => this.setState({usernameInputValue: value})} entityServiceSearchCall={UserAdminService.searchForUsersByUsername}/>
                    <SearchCMSComponent title="Town" searchText={this.state.townInputValue} fieldName={"town"} setSearchText={(value) => this.setState({townInputValue: value})}/>
                    <DateTimePicker label="Start Date" selected={this.state.startDateInputValue} onChange={date => this.setState({startDateInputValue: date})}/>
                    <DateTimePicker label="End Date" selected={this.state.endDateInputValue} onChange={date => this.setState({endDateInputValue: date})}/>
                    <SelectCMSComponent label="Status" onChange={data => data != "Select Status" ? this.setState({orderStatusInputValue: data}) : this.setState({orderStatusInputValue: ''})} list={this.state.orderStatusList}/>
                </div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                    <SubmitCMSButton onSearch={this.onSearchHandler}/>
                    <ResetCMSButton onResetSearch={this.onResetSearchHandler}/>
                </div>
            </>
        );
    }

    render() {

        if(this.state.entities.length === 0 && this.state.noEntity){
            return (
                <>
                    {this.TopToolbar()}
                    <div className="card" style={{width: "80%", textAlign: "center", margin: "0 auto", maxWidth: "600px"}}>
                        <div className="card-body">
                            <h5 className="card-title">No Such Item Found</h5>
                            <button onClick={this.onResetSearchHandler} className="btn btn-primary">Reset</button>
                        </div>
                    </div>
                </>
            )
        }

        if(this.state.entities.length == 0){
            return (
                <Spinner/>
            );
        }

        return (
            <>
                {this.TopToolbar()}
                <Pagination handlePageClick={this.handlePageClick} totalPages={this.state.totalPages} currentPage={this.state.page}/>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th onClick={this.sortByHandler.bind(this, 'id')} className={classes.clickableTableHeader} style={this.state.sortField === 'id' ? {backgroundColor: "#ccc"}: null} scope="col"># <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'user.username')} className={classes.clickableTableHeader} style={this.state.sortField === 'user.username' ? {backgroundColor: "#ccc"}: null} scope="col">Username <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'grandTotal')} className={classes.clickableTableHeader} style={this.state.sortField === 'grandTotal' ? {backgroundColor: "#ccc"}: null} scope="col">Grand Total <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'shippingAddress.town')} className={classes.clickableTableHeader} style={this.state.sortField === 'shippingAddress.town' ? {backgroundColor: "#ccc"}: null} scope="col">Town <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'createdDate')} className={classes.clickableTableHeader} style={this.state.sortField === 'createdDate' ? {backgroundColor: "#ccc"}: null} scope="col">Order Date <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'orderStatus')} className={classes.clickableTableHeader} style={this.state.sortField === 'orderStatus' ? {backgroundColor: "#ccc"}: null} scope="col">Order Status <SortIcon/></th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.entities.map(entity => {
                            return (
                                <tr key={entity.id}>
                                    <th>{entity.id}</th>
                                    <td>{entity.user.username}</td>
                                    <td><NepaliCurrency value={entity.grandTotal}/></td>
                                    <td>{entity.shippingAddressDTO.town}</td>
                                    <td><DateFormatCMSComponent value={entity.createdDate}/></td>
                                    <td><span className={entity.orderStatus === "COMPLETE" ? "badge badge-success" : entity.orderStatus === "PENDING" ? "badge badge-warning" : "badge badge-primary"}>{entity.orderStatus}</span></td>
                                    <td style={{display: "flex", justifyContent: "space-around"}}>
                                        <Link to={`${this.props.match.url}/${entity.id}`}><button className="btn btn-primary">Views</button></Link>
                                        <Link to={`${this.props.match.url}/${entity.id}/edit`}><button className="btn btn-warning">Edit</button></Link>
                                        <button onClick={() => this.setState({deleteEntity: {id: entity.id, username: entity.username}})} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
                <ModalView title={`Delete Order: ${this.state.deleteEntity.username}`} id={this.state.deleteEntity.id} body={`Are you sure you want to remove this Order: ${this.state.deleteEntity.id}?`} callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>} buttonTheme="btn-danger"/>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(Orders);

