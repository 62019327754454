import React, {Component} from 'react';
import ArtistService from '../../services/ArtistService';
import Spinner from '../../components/Spinnner/Spinner'

class ArtistSong extends Component {
    
    state = {
        artist : null
    };

    componentDidMount(){
        ArtistService.getArtist(this.props.match.params.id).then(res => {
            this.setState({artist : res.data})
        });
    }

    onPressSongHandler = (id) => {
        this.props.history.push('/song/' + id);
    }


    render(){
        if(!this.state.artist){
            return (
                <Spinner/>
            )
        }
        return(
            <div style={{maxWidth: "600px", margin: "0 auto", paddingBottom: "100px"}}>
                <div className="card" style={{marginBottom: "10px"}}>
                    <div className="card-body" style={{margin: "0 auto"}}>
                        <span style={{float: "left"}}><img style={{width: "4em", borderRadius: "100px"}} src={'https://res.cloudinary.com/dzubzqlj3/image/upload/' + this.state.artist.photoUrl} alt={this.state.artist.name}/></span>
                        <div style={{float: "left", margin: "10px 15px"}}><b style={{fontWeight: "500"}}>{this.state.artist.name}</b>
                        <p style={{fontSize: "0.8em", marginBottom: "0"}}>{this.state.artist.songs.length} songs</p></div>
                    </div>
                </div>
                {this.state.artist.songs.map(song => {
                    return (
                        <li key={song.id} onClick={this.onPressSongHandler.bind(this, song.id)} className={"list-group-item Item"} style={{cursor: "pointer"}}>
                            <p style={{marginBottom: "0", cursor: "pointer"}}>{song.title}</p>
                        </li>
                    )
                })}
            </div>
        )
    }

}

export default ArtistSong;