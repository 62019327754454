import React, {useRef} from 'react';
import {PRIMARY} from '../../constants/Colors'
import Icon from '../../assets/icon.png'
import './NavBar.css'
import {NavLink, Link} from 'react-router-dom';

const NavBar = () => {

    const toggler = useRef(null);

    const toggleNavbar = () => {
        if(window.innerWidth < 992){
            toggler.current.click();
        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{backgroundColor: PRIMARY}}>
            <Link className="navbar-brand" to="/"><img src={Icon} alt="Logo" style={{height: "28px", marginTop: "-4px"}}/> Hotel Daraz</Link>
            <button ref="tooltip" ref={toggler} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto" style={{margin: "0 auto"}}>
                {/*<li className="nav-item">*/}
                {/*    <NavLink className="nav-link" style={{color: "white"}} to="/" exact onClick={toggleNavbar}>Login</NavLink>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <NavLink className="nav-link" style={{color: "white"}} to="/songs" onClick={toggleNavbar}>Register</NavLink>*/}
                {/*</li>*/}
                </ul>
                <ul className="navbar-nav navbar-right">
                    <li className="nav-item"><NavLink className="nav-link" style={{color: "white"}} to="/login" onClick={toggleNavbar}>Login</NavLink></li>
                    <li className="nav-item"><NavLink className="nav-link" style={{color: "white"}} to="/register" onClick={toggleNavbar}>Register</NavLink></li>
                </ul>
                {/* <SearchBar/> */}
            </div>
        </nav>
    );
}

export default NavBar;
