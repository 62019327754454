import React, {Component} from 'react';
import queryString from 'query-string';
import AuthenticationService from "../../services/AuthenticationService";
import Spinner from "../../components/Spinnner/Spinner";
import {toast} from "react-toastify";
import * as actions from '../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";

class ForgotPassword extends Component {
    state = {
        email: null,
        error: null,
        userId: null,
        isLoading: false,
        verificationNumber: null,
        verificationPage: false,
        newPassword: null,
        retypePassword: null,
        fromUrl: false
    }

    componentDidMount() {

        const params = queryString.parse(this.props.location.search);
        if(params.code){
            const tempUserId = params.code.substr(0, params.code.indexOf('CN'));
            const tempCode = params.code.substr(params.code.indexOf('CN')+2, params.code.length-1);
            this.setState({userId: tempUserId, verificationNumber: tempCode, verificationPage: true, fromUrl: true});
        }
    }

    sendEmail = () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
            this.setState({error: "Invalid Email!"})
            return;
        }
        this.setState({isLoading: true});
        AuthenticationService.requestResetPassword(this.state.email).then(
            res => {
                this.props.sucessToast("Verification sent to your email");
                this.setState({verificationPage: true, userId: res.data, isLoading: false, error: null});
            }
        );
    }

    sendVerificationNumber = () => {

        if(!this.state.verificationNumber || !this.state.newPassword || !this.state.retypePassword){
            this.setState({error: "All the fields are required!"})
            return;
        }
        if(this.state.newPassword.length < 5){
            this.setState({error: "Password too Short!"})
            return;
        }
        if(this.state.newPassword !== this.state.retypePassword){
            this.setState({error: "Confirm Password does not match!"})
            return;
        }
        if(this.state.userId === -1){
            this.setState({error: "Something went wrong!"})
            return;
        }
        AuthenticationService.resetPassword(this.state.userId, this.state.verificationNumber, this.state.newPassword)
            .then(() => {
                this.props.sucessToast("Password Reset Successful");
                this.props.history.push("/login");
            })
            .catch(() => this.setState({error: "Something went wrong!"}))
    }

    render() {
        return (
            <>
                <div className="card" style={{width: "80%", margin: "0 auto", maxWidth: "600px"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textAlign: "center"}}>Forgot your Password?</h5>
                        {this.state.error && <div className="alert alert-danger" role="alert">{this.state.error}</div>}
                        {!this.state.verificationPage ?
                            <>
                                <label htmlFor="email">Email*</label>
                                <input type="email" className={"form-control"} id="email" placeholder="Email"
                                       value={this.props.email}
                                       onChange={(event) => this.setState({email: event.target.value})}/>
                                <button onClick={this.sendEmail} style={{marginTop: "15px", width: "100%"}} disabled={this.state.isLoading} className="btn btn-primary">
                                    {this.state.isLoading ? <Spinner size={1}/> : "Reset Password"}</button>
                            </>
                            :
                            <>
                                {!this.state.fromUrl && <><label htmlFor="number">Verification Code (Sent to your Email Address)</label>
                                    <input type="number" className={"form-control"} style={{marginBottom: "15px"}} id="number" placeholder="Verification Number"
                                    value={this.props.verificationNumber}
                                    onChange={(event) => this.setState({verificationNumber: event.target.value})}/></>}
                                <label htmlFor="newPassword">New Password</label>
                                <input type="password" className={"form-control"} style={{marginBottom: "15px"}} id="newPassword" placeholder="New Password"
                                       value={this.props.newPassword}
                                       onChange={(event) => this.setState({newPassword: event.target.value})}/>
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input type="password" className={"form-control"} id="confirmPassword" placeholder="Retype Password"
                                       value={this.props.retypePassword}
                                       onChange={(event) => this.setState({retypePassword: event.target.value})}/>
                                <button onClick={this.sendVerificationNumber} style={{marginTop: "15px", width: "100%"}} disabled={this.state.isLoading} className="btn btn-primary">
                                    {this.state.isLoading ? <Spinner size={1}/> : "Reset Password"}</button>
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(ForgotPassword);