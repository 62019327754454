import React, {Component, createRef} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import ErrorFormItem from "../../../../components/ErrorFormItem";
import {Formik} from "formik";
import classes from '../Artists/styles/artist.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import IArtist from "../../../../models/IArtist";
import {jsonToBlob} from "../../../../utils/Utils";
import {
    artistListPage,
    videoProductionListPage,
    chordsViewPage,
    songListPage
} from "../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleDropZoneUploader,
    ViewComponentExternalLink,
    SingleAutoCompleteCMSComponent
} from "../../../../components/CMSFormComponents";
import {ModalView} from "../../../../components/BootstrapModal";
import SongService from "../../../../services/SongService";
import {CLOUDINARY_CHORDS_URL} from "../../../../constants/GlobalConstants";
import VideoProductionService from "../../../../services/VideoProductionService";

class VideoProductionUpdate extends Component {

    state = {
        id: null,
        videoProductionType: '',
        price: 0,
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id
    }

    backButtonRef = createRef();

    validationSchema = Yup.object().shape({
        videoProductionType: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        price: Yup.number().min(1, "Too Short").required("Required")
    });

    componentDidMount() {
        if(this.state.isEditMode){
            VideoProductionService.getVideoProduction(this.props.match.params.id).then(
                item => this.setState({id: item.data.id, videoProductionType: item.data.videoProductionType, price: item.data.price})
            )
        }
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);

        let formData = new FormData();
        const jsonPayload = {videoProductionType: values.videoProductionType, price: values.price, id: this.state.isEditMode ? this.state.id : null};
        formData.append('payload', jsonToBlob(jsonPayload));

        const restServiceApi = this.state.isEditMode ? VideoProductionService.putVideoProduction : VideoProductionService.postVideoProduction;
        restServiceApi(jsonPayload)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.videoProductionType + " is Updated." : "A New Video Production Type: " + res.data.videoProductionType + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({saveAndNew: false});
                }
                else this.props.history.push(videoProductionListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        VideoProductionService.deleteVideoProduction(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.videoProductionType)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>}
                        <Formik initialValues={{ videoProductionType: this.state.videoProductionType, price: this.state.price }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>}
                                        <FormField name="Video Production Type" required={true} touched={touched.videoProductionType} errors={errors.videoProductionType} value={values.videoProductionType} setFieldValue={setFieldValue} fieldName='videoProductionType' onBlurHandler={setFieldTouched}/>
                                        <FormField name="Price" required={true} type="number" touched={touched.price} errors={errors.price} value={values.price} setFieldValue={setFieldValue} fieldName='price' onBlurHandler={setFieldTouched}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.videoProductionType}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.videoProductionType}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(VideoProductionUpdate);