import React, {useState} from 'react';
import {MinusIcon} from "../FontAwesomeComponent";
import {FormSelectField} from "../CMSFormComponents";

function HotelSocialLink(props) {

    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>
                <select value={props.attribute} className="form-control mr-sm-2" onChange={event => props.onChange(event.target.value, props.index, 'socialType')}>
                    {["FACEBOOK", "INSTAGRAM", "YOUTUBE", "WEBSITE", "BOOKING", "AGODA", "BOOK MY TRIP"].map(
                        item => {
                            return (
                                <option key={item} value={item}>{item}</option>
                            );
                        }
                    )}
                </select>
            </td>
            <td><input type="url" name="text" value={props.text} onChange={event => props.onChange(event.target.value, props.index, 'url')} className="form-control" placeholder="URL"/></td>
            {props.length === props.index + 1 && <td><button type="button" onClick={() => props.onRemove(props.index)} className="btn btn-danger"><MinusIcon/></button></td>}
        </tr>
    );
}

export default HotelSocialLink;