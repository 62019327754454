import React, {Component} from 'react'
import ArtistService from "../../../../../services/ArtistService";
import Spinner from "../../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../../components/FontAwesomeComponent";
import SongService from "../../../../../services/SongService";
import {
    DateFormatCMSComponent, NepaliCurrency,
    ViewComponentExternalLink,
    ViewComponentLink
} from "../../../../../components/CMSFormComponents";
import {artistListPage, categoriesListPage, chordsViewPage} from "../../../../../constants/routesConstants";
import CategoryService from "../../../../../services/Shop/CategoryService";
import ProductService from "../../../../../services/Shop/HotelService";
import {CLOUDINARY_URL} from "../../../../../constants/GlobalConstants";

class ProductDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        ProductService.getProduct(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        General Properties
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Product Name</td>
                                <th>{this.state.entity.name}</th>
                            </tr>
                            <tr>
                                <td scope="row">Description</td>
                                <td dangerouslySetInnerHTML={{__html: this.state.entity.productDescription}}></td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Data Properties
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Price</td>
                                <th><NepaliCurrency value={this.state.entity.price}/></th>
                            </tr>
                            <tr>
                                <td scope="row">Quantity</td>
                                <th>{this.state.entity.quantity}</th>
                            </tr>
                            <tr>
                                <td scope="row">Available Date</td>
                                <td><DateFormatCMSComponent value={this.state.entity.dateAvailable}/></td>
                            </tr>
                            <tr>
                                <td scope="row">Requires Shipping</td>
                                <td>{this.state.entity.shouldBeShipped ? <span className="badge badge-primary">Yes</span> : <span className="badge btn-danger">No</span>}</td>
                            </tr>
                            <tr>
                                <td scope="row">Out of Stock Status</td>
                                <td>{this.state.entity.stockStatus}</td>
                            </tr>
                            <tr>
                                <td scope="row">Sort Order</td>
                                <th>{this.state.entity.sortOrder} <span className="badge badge-primary">{this.state.entity.sortOrder > 75 ? "VERY LOW" : this.state.entity.sortOrder > 50 ? "LOW" : this.state.entity.sortOrder > 25 ? "HIGH" : "VERY HIGH"}</span></th>
                            </tr>
                            <tr>
                                <td scope="row">Disabled</td>
                                <th>{this.state.entity.disabled ? <span className="badge badge-danger">Yes</span> : <span className="badge badge-primary">No</span>}</th>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Link Properties
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            {this.state.entity.manufacturerName && <tr>
                                <td scope="row">Manufacturer</td>
                                <th>{this.state.entity.manufacturerName}</th>
                            </tr>}
                            <tr>
                                <td scope="row">Categories</td>
                                <th>
                                    {this.state.entity.categoryDTOS.map(cat => (
                                        <>
                                            {cat.categoryChain.slice(1, this.state.entity.categoryChain.length).map(cat =>
                                                <span key={cat.id}>{`${cat.name} > `}</span>)}
                                            <span className="badge badge-primary">{cat.title}</span>
                                            <br/>
                                        </>
                                    ))}
                                </th>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Attribute Properties
                    </div>
                    <div className="card-body">
                        {!this.state.entity.productAttributes && <span className="badge badge-info">No Attributes Defined</span>}
                        <table className="table table-hover">
                            <tbody>
                            {this.state.entity.productAttributes.map(pa => (
                                <tr>
                                    <td>{pa.attribute}</td>
                                    <th>{pa.text}</th>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Option Properties
                    </div>
                    <div className="card-body">
                        {!this.state.entity.productOptionDTOS && <span className="badge badge-info">No Attributes Defined</span>}
                        {this.state.entity.productOptionDTOS.map(po => (
                            <div className="mt-2" style={{padding: "10px", border: "1px solid #ccc", borderRadius: "5px"}}>
                                <h5><b>{po.title} {po.disabled ? <span className="badge badge-danger">Disabled</span> : <span className="badge badge-primary">Enabled</span>}</b></h5><br/>
                                <table className="table table-hover">
                                    <tbody>
                                    {po.types.map(type => (
                                        <tr>
                                            <td>{type.name}</td>
                                            <td>
                                                Price Modified: {type.priceModifier}<NepaliCurrency value={type.priceChanges}/> {type.disabled ? <span className="badge badge-danger">Disabled</span> : <span className="badge badge-primary">Enabled</span>}
                                                {type.imageUrl && <img style={{width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px"}} src={`${CLOUDINARY_URL}${type.imageUrl}`}/>}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Image Properties
                    </div>
                    <div className="card-body">
                        {this.state.entity.productImageDTOS.map(pi => (
                            <img style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                border: "1px solid #ccc",
                                margin: "10px",
                                borderRadius: "5px"
                            }} src={`${CLOUDINARY_URL}/${pi.url}`}/>
                        ))}
                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", padding: "10px 0"}}>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                        <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                    </div>
                </div>

            </div>
        )
    }

}

export default ProductDetail;