import React, {useRef, useState, useEffect} from 'react';
import {
    CategoryMultiAutoCompleteCMSComponent,
    FormField,
    FormFieldDateTimePicker,
    FormFieldDisabled,
    NepaliCurrency,
    SimpleFormField, SingleAutoCompleteCMSComponent
} from "./CMSFormComponents";
import ArtistImage from "./ArtistItem/ArtistImage";
import {CLOUDINARY_URL} from "../constants/GlobalConstants";
import {RefreshFontAwesome, RemoveFontAwesome} from "./FontAwesomeComponent";
import innerclasses from "../containers/Store/css/productScreen.module.css";
import ProductService from "../services/Shop/HotelService";
import Spinner from "./Spinnner/Spinner";
import OrderService from "../services/Shop/OrderService";
import ManufacturerService from "../services/Shop/FacilityService";

export const OrderItemAddModal = (props) => {

    const cancelButtonRef = useRef(null);
    const [item, setItem] = useState({});

    const [quantity, setQuantity] = useState(1);
    // const [total, setTotal] = useState(0);
    const [product, setProduct] = useState({id: -1});
    const [productInputValue, setProductInputValue] = useState('');
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);

    const closeModal = () => {
        setTimeout(() => {
            // props.resetOrderItem();
            // setItem({})
        }, 100);
    }

    const addItemToOrder = () => {
        if(product.quantity <= 0){
            alert( "This item is Out of Stock!" )
            return;
        }

        if(product.quantity < quantity){
            alert( "We only have " + product.quantity + " pieces of this item!" )
            return;
        }

        if(selectedProductOptions.length !== product.productOptionDTOS.length){
            let options = [...product.productOptionDTOS]
            options.map(po => {
                let found = false;
                selectedProductOptions.map(s => {
                    if(s.option === po.id) {
                        found = true;
                    }
                })
                if(!found) alert( "Please select the " + po.title + "!" );
            })
            return;
        }

        let orderItemOptionTypeDTOS = [];
        selectedProductOptions.map(po => orderItemOptionTypeDTOS.push({productOption: {"id": po.option}, productOptionType: {id: po.type}}))
        OrderService.addOrderItemToOrder(props.orderId, product, quantity, orderItemOptionTypeDTOS).then(
            res => {
                props.callback();
                cancelButtonRef.current.click();
            }
        ).catch(() => alert("Something went wrong!"));
    }

    const onClickOptionType = (option, type) => {
        let tempSelectedProductOptions = selectedProductOptions.filter((po, index) => {
            return  po.option !== option.id;
        });
        tempSelectedProductOptions = [...tempSelectedProductOptions, {option: option.id, type: type.id}]
        setSelectedProductOptions(tempSelectedProductOptions)
    }

    const productSelected = (id) => {
        ProductService.getProduct(id).then(res => {
            setProduct(res.data)
        })
    }

    // const refreshPrices = () => {
    //     let productOptions = [];
    //     item.orderItemOptionTypes.map(ot => productOptions.push({productOption: {"id": ot.productOption.id}, productOptionType: {id: ot.productOptionType.id}}))
    //     OrderService.checkUpdatedOrderItemPrice(item.id, quantity, productOptions).then(
    //         res => setTotal(res.data)
    //     ).catch(() => alert("Something went wrong!"));
    // }

    return (
        <div className="modal fade" id="orderItemAddModal" tabIndex="-1" role="dialog" aria-labelledby="orderItemAddModal"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="customModalLabel">{props.title}</h5>
                        <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SingleAutoCompleteCMSComponent title="Product Name" searchText={productInputValue} setSearchText={value => setProductInputValue(value)}
                            fieldName={"name"} valueSelectField={"id"}
                            selectedId={product.id}
                            valueSelectCallback={(id, value) => {productSelected(id); setProductInputValue(value);}}
                            valueClearCallback={() => {setProductInputValue(''); setProduct({id: -1});}}
                            entityServiceSearchCall={ProductService.searchForProducts}/>
                        {product.id > -1 && <div>
                            <ArtistImage photoUrl={`${product.productImageDTOS[0].url}`} size={5}/>
                            <div className="row">
                                <FormFieldDisabled name="Product Name" value={product.name}/>
                                <FormFieldDisabled name="Price"
                                                   value={product.discount ? product.discount : product.price}/>
                                <SimpleFormField name="Quantity" required={true} type="number" value={quantity}
                                                 onChange={(v) => setQuantity(v)}/>
                            </div>
                            <hr/>
                            {product.productOptionDTOS.map(po => {
                                return (
                                    <div className="col-sm-12">
                                        <dl className="dlist-inline">
                                            <dt style={{marginBottom: "-12px"}}>{po.title}:</dt>
                                            <dd>
                                                {po.types.map(type => (
                                                    <label className={"form-check form-check-inline "}>
                                                        <span className={"form-check-label "}>
                                                        <input className="form-check-input" type="radio"
                                                               onClick={() => onClickOptionType(po, type)}
                                                               name={po.title} id={type.id}
                                                               value="option2"/>
                                                           <div className={innerclasses.selector}>
                                                            {type.imageUrl && <img style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                objectFit: "cover",
                                                                borderRadius: "5px"
                                                            }}
                                                                                   src={`${CLOUDINARY_URL}w_50,h_50,c_fill/${type.imageUrl}`}
                                                                                   alt=""/>}
                                                               <span>{type.name}</span>
                                                               {type.priceChanges > 0 &&
                                                               <span style={{fontSize: "0.8em"}}>({type.priceModifier}
                                                                   <NepaliCurrency value={type.priceChanges}/>)</span>}
                                                           </div>
                                                        </span>
                                                    </label>
                                                ))}
                                            </dd>
                                        </dl>
                                    </div>
                                )
                            })}
                            <hr/>
                            {/*<div className="row">*/}
                            {/*    <FormFieldDisabled name="Total" value={total}/>*/}
                            {/*</div>*/}
                        </div>}

                    </div>
                    <div className="modal-footer">
                        {/*<button type="button" onClick={() => refreshPrices()} className="btn btn-primary" ><RefreshFontAwesome/> Update Prices</button>*/}
                        <button type="button" onClick={() => closeModal()} ref={cancelButtonRef} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={() => addItemToOrder()} className={`btn ${props.buttonTheme}`}>{props.confirmLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}