import React, {useState} from 'react';
import {MinusIcon} from "../FontAwesomeComponent";

function HotelPhoneNumber(props) {

    return (
        <tr>
            <td>{props.index + 1} {props.phoneNumber}</td>
            <td><input type="text" name="phoneNumber" value={props.attribute} onChange={event => props.onChange(event, props.index)} className="form-control" placeholder="Phone Number"/></td>
            <td><input type="text" name="description" value={props.text} onChange={event => props.onChange(event, props.index)} className="form-control" placeholder="Description"/></td>
            {props.length === props.index+1 && <td><button type="button" onClick={() => props.onRemove(props.index)} className="btn btn-danger"><MinusIcon/></button></td>}
        </tr>
    );
}

export default HotelPhoneNumber;