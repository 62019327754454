import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Actions Type
const SUCCESS_TOAST = 'SUCCESS_TOAST';
const ERROR_TOAST = 'ERROR_TOAST';

//Actions
export const sucessToast = (message) => {
    return {
        type: SUCCESS_TOAST,
        message: message
    }
}

export const errorToast = (message) => {
    return {
        type: ERROR_TOAST,
        message
    }
}

// REDUCER
const initialState = {}

const reducer = (state = initialState, action) => {
    switch (action.type){
        case SUCCESS_TOAST:
            toast.success(action.message);    
            return state;
        case ERROR_TOAST:
            toast.error(action.message);
            return state;
        default:
            return state;
    }
}

export default reducer;