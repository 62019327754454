import React, {Component} from 'react'
import ArtistService from "../../../../../services/ArtistService";
import Spinner from "../../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../../components/FontAwesomeComponent";
import SongService from "../../../../../services/SongService";
import {
    DateFormatCMSComponent, FormFieldDisabled, FormSelectField, NepaliCurrency,
    ViewComponentExternalLink,
    ViewComponentLink
} from "../../../../../components/CMSFormComponents";
import {
    artistListPage,
    categoriesListPage,
    chordsViewPage, hotelsListPage,
    usersListPage
} from "../../../../../constants/routesConstants";
import CategoryService from "../../../../../services/Shop/CategoryService";
import ProductService from "../../../../../services/Shop/HotelService";
import {CLOUDINARY_URL} from "../../../../../constants/GlobalConstants";
import OrderService from "../../../../../services/Shop/OrderService";

class OrdersDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        OrderService.getOrder(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        General Properties
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Username</td>
                                <th><ViewComponentExternalLink url={`${usersListPage}/${this.state.entity.user.id}`} text={this.state.entity.user.username}/></th>
                            </tr>
                            <tr>
                                <td scope="row">Name</td>
                                <th>{this.state.entity.user.firstName} {this.state.entity.user.lastName}</th>
                            </tr>
                            <tr>
                                <td scope="row">Order Notes</td>
                                <td>{this.state.entity.orderNotes}</td>
                            </tr>
                            <tr>
                                <td scope="row">Order Date</td>
                                <td><DateFormatCMSComponent value={this.state.entity.createdDate}/></td>
                            </tr>
                            <tr>
                                <td scope="row">Order Status</td>
                                <td><span className={this.state.entity.orderStatus === "COMPLETE" ? "badge badge-success" : this.state.entity.orderStatus === "PENDING" ? "badge badge-warning" : "badge badge-primary"}>{this.state.entity.orderStatus}</span></td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Order Properties
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <table className="table" style={{marginTop: "10px", textAlign: "right"}}>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col"># Product Name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Product Options</th>
                                    <th scope="col">Quantity</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.entity.orderItems.map((item, index) =>
                                    <tr>
                                        <td><ArtistImage photoUrl={`${item.product.productImageDTOS[0].url}`} size={5}/></td>
                                        <td><ViewComponentExternalLink url={`${hotelsListPage}/${item.product.id}`} text={`${item.product.id}: ${item.product.name}`}/></td>
                                        <td><NepaliCurrency value={item.product.discount ? item.product.discount : item.product.price}/></td>
                                        <td>
                                            {item.orderItemOptionTypes.map(ot => (
                                                <p>
                                                    {ot.productOption.title}: {ot.productOptionType.name} ({ot.productOptionType.priceModifier}<NepaliCurrency value={`${ot.productOptionType.priceChanges}`}/>)
                                                </p>
                                            ))}
                                        </td>
                                        <td>x {item.quantity}</td>
                                        <td><NepaliCurrency value={item.total}/></td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={5}>Sub-Total</td>
                                    <td><NepaliCurrency value={this.state.entity.subTotal}/></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Shipping Fee</td>
                                    <td><NepaliCurrency value={50}/></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Total</td>
                                    <td><NepaliCurrency value={this.state.entity.grandTotal}/></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-header">
                        Shipping Address Properties
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Street Address</td>
                                <th>{this.state.entity.shippingAddressDTO.streetAddress}</th>
                            </tr>
                            <tr>
                                <td scope="row">Apartment</td>
                                <th>{this.state.entity.shippingAddressDTO.streetAddress}</th>
                            </tr>
                            <tr>
                                <td scope="row">Town</td>
                                <th>{this.state.entity.shippingAddressDTO.town}</th>
                            </tr>
                            <tr>
                                <td scope="row">State</td>
                                <th>{this.state.entity.shippingAddressDTO.state}</th>
                            </tr>
                            <tr>
                                <td scope="row">Phone Number</td>
                                <th>{this.state.entity.shippingAddressDTO.phone}</th>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="card mt-3" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", padding: "10px 0"}}>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                        <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                    </div>
                </div>

            </div>
        )
    }

}

export default OrdersDetail;