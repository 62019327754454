import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants';

class ArtistService{
    
    getAllArtists(){
        return axios.get(BASE_URL + "/artists/all");
    }

    getPagedArtists(options){
        let url = BASE_URL + "/artists?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        return axios.get(url);
    }

    searchForArtist(name, page, size){
        let url = BASE_URL + "/artists?name=" + name + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    getArtist(id){
        return axios.get(BASE_URL + "/artists/" + id);
    }

    postArtist(formData){
        return axios.post(BASE_URL + "/artists", formData);
    }

    putArtist(artist){
        return axios.put(BASE_URL + "/artists", artist);
    }

    deleteArtist(artistId){
        return axios.delete(BASE_URL + `/artists/${artistId}`);
    }

}

export default new ArtistService();