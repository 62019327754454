import React, {Component, createRef} from 'react'
import Spinner from "./../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "./../../components/FontAwesomeComponent";
import * as Yup from "yup";
import {Formik} from "formik";
import 'react-dropzone-uploader/dist/styles.css'
import {jsonToBlob} from "./../../utils/Utils";
import {adminSettingPage, artistListPage, chordsViewPage, songListPage} from "./../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from './../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormField,
    SingleDropZoneUploader
} from "./../../components/CMSFormComponents";
import AuthenticationService from "../../services/AuthenticationService";
import ArtistImage from "../../components/ArtistItem/ArtistImage";
import logo from "../../assets/icon.png";

class CmsSettingsUpdate extends Component {

    state ={
        entity: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        imageUrl: null,
        imageFile: null,
    }

    backButtonRef = createRef();

    dropZone = createRef();

    validationSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        phoneNumber: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        file: null
    });

    componentDidMount() {
        AuthenticationService.getUserDetails().then(
            res => {
                this.setState({
                    entity: res.data,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    phoneNumber: res.data.phoneNumber,
                    imageUrl: res.data.imageUrl
                })
            }
        )
    }

    handleChangeStatus = ({ meta, file }, status) => {
        if(status === "done"){
            this.setState({imageFile: file})
        }
        if(status === "removed"){this.setState({imageFile: null})}
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);

        let formData = new FormData();
        const jsonPayload = {...this.state.entity, firstName: values.firstName, lastName: values.lastName, phoneNumber: values.phoneNumber};
        formData.append('payload', jsonToBlob(jsonPayload));
        if(this.state.imageFile) formData.append('file', this.state.imageFile);

        const restServiceApi = AuthenticationService.putUserDetails;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast("Your profile is Updated.");
                setSubmitting(false)
                this.props.history.push(adminSettingPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        <Formik initialValues={{ firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, phoneNumber: this.state.phoneNumber }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <FormField name="First Name" required={true} touched={touched.firstName} errors={errors.firstName} value={values.firstName} setFieldValue={setFieldValue} fieldName='firstName' onBlurHandler={setFieldTouched}/>
                                        <FormField name="Last Name" required={true} touched={touched.lastName} errors={errors.lastName} value={values.lastName} setFieldValue={setFieldValue} fieldName='lastName' onBlurHandler={setFieldTouched}/>
                                        <FormField name="Phone Number" required={true} touched={touched.phoneNumber} errors={errors.phoneNumber} value={values.phoneNumber} setFieldValue={setFieldValue} fieldName='phoneNumber' onBlurHandler={setFieldTouched}/>
                                    </div>
                                    <div className="SingleImageUploadPanel" style={errors.file ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>
                                        <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.file}</div>
                                        <div style={{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                            {this.state.imageUrl ? <ArtistImage photoUrl={this.state.imageUrl} size={5} name={this.state.username}/>
                                                : <img src={logo} style={{width: "5em", boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", backgroundColor: "#965ba5", padding: "4px", borderRadius: "5px"}}/>}
                                        </div>
                                        <SingleDropZoneUploader accept="image/*" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={"Click to Update Profile Picture"}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(CmsSettingsUpdate);