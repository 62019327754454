import React, {Component, useEffect, useState} from 'react';

const SearchBar = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <form className="form-inline my-2 my-lg-0" onSubmit={props.onSearch} style={{textAlign: "center", display: "block", paddingBottom: "10px"}}>
            <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control mr-sm-2" type="text" aria-label="Search"
                   value={props.searchText}
                   onFocus={() => setShow(true)}
                   onBlur={() => setTimeout(() => setShow(false), 200)}
                   onChange={(event) => props.setSearchText(event.target.value)}
                   placeholder={"Search " + props.title} />
            <div style={props.searchText == "" || options.length === 0 || !show  ? {display: "none"} : {display: "block"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map( option =>
                        <span key={option.id} onClick={() => props.setSearchText(option.name)} style={{cursor: "pointer"}} className="dropdown-item" >{option.name}</span>
                    )
                }
            </div>
            <button className="btn btn-primary my-2 my-sm-0" type="submit">Search</button>
            <button onClick={props.onResetSearch} disabled={props.searchText === ""} className="btn btn-warning my-2 my-sm-0" type="button" style={{marginLeft: "0.5rem"}}>Reset</button>
        </form>
        );
}

export default SearchBar;