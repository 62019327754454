import React, {Component} from 'react';
import SongItem from '../../components/SongItem/SongItem'
import Spinner from '../../components/Spinnner/Spinner'
import InfiniteScroll from 'react-infinite-scroller';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as actions from './song.reducer'
import {connect} from 'react-redux'

class SongContainer extends Component {

    componentDidMount(){
        if(this.props.songsState.songs.length === 0){
            this.props.fetchSongs({page: 0});
        }
    }

    onPressHandler = (id) => {
        this.props.history.push('/song/' + id);
    }

    infiniteApiCall = () => {
        this.props.fetchSongs({page: this.props.songsState.page, searchText: this.props.songsState.searchText});
    }

    onSearchHandler = (event) => {
        if(event) event.preventDefault();
        if(this.props.songsState.searchText !== ""){
            this.props.onSearchSongs({page: 0, searchText: this.props.songsState.searchText});
        } else {
            this.props.onSearchSongs({page: 0, searchText: ""});
        }
    }

    onResetSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.props.resetSearch();
        this.props.onSearchSongs({page: 0, searchText: ""});
    }

    render(){
        if(this.props.songsState.songs.length === 0 && !this.props.songsState.hasMore){
            return (
                <>
                <SearchBar title="Song" onResetSearch={this.onResetSearchHandler} onSearch={this.onSearchHandler} searchText={this.props.songsState.searchText} setSearchText={this.props.setSearchText}/>
                <div className="card" style={{width: "80%", textAlign: "center", margin: "0 auto", maxWidth: "600px"}}>
                    <div className="card-body">
                        <h5 className="card-title">No Song Found</h5>
                        <p className="card-text">Sorry we don't have any song named <b>{this.props.songsState.searchText}</b>. Please search for other song.</p>
                        <button onClick={this.onResetSearchHandler} className="btn btn-primary">Reset</button>
                    </div>
                </div>
                </>
            )
        }

        if(this.props.songsState.songs.length === 0){
            return (
                <Spinner size={5}/>
            )
        }

        return (
            <div style={{maxWidth: "600px", margin: "0 auto"}}>
                <SearchBar title="Song" onResetSearch={this.onResetSearchHandler} onSearch={this.onSearchHandler} searchText={this.props.songsState.searchText} setSearchText={this.props.setSearchText}/>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.infiniteApiCall}
                    hasMore={this.props.songsState.hasMore}
                    loader={<div className="loader" key={0}><Spinner size={3}/></div>}
                    >
                    <ul className="list-group" style={{marginBottom: "10px"}}>
                        {
                            this.props.songsState.songs.map(song => {
                                return (
                                    <SongItem key={song.id} song={song} onPress={this.onPressHandler}/>
                                )
                            })
                        }
                    </ul>
                </InfiniteScroll>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        songsState: state.songs
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSongs: (options) => dispatch(actions.fetchSongs(options)),
        setSearchText: (event) => dispatch(actions.setSearchText(event.target.value)),
        onSearchSongs: (options) => dispatch(actions.onSearchSongs(options)),
        resetSearch: () => dispatch(actions.resetSearch())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SongContainer);