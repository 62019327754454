import React, {Component} from 'react';
import classes from './Dashboard.module.css'
import {Link, NavLink, Route, Switch} from 'react-router-dom'
import Artists from "../../containers/cms/entities/Artists/Artists";
import FontAwesomeComponent from "../../components/FontAwesomeComponent";
import logo from '../../assets/icon.png'
import ArtistsDetail from "../../containers/cms/entities/Artists/ArtistsDetail";
import ArtistsUpdate from "../../containers/cms/entities/Artists/ArtistsUpdate";
import Songs from "../../containers/cms/entities/Songs/Songs";
import SongsDetail from "../../containers/cms/entities/Songs/SongsDetail";
import SongsUpdate from "../../containers/cms/entities/Songs/SongsUpdate";
import {connect} from "react-redux";
import CmsSettings from "../../containers/cms/CmsSettings";
import CmsSettingsUpdate from "../../containers/cms/CmsSettingsUpdate";
import PasswordUpdate from "../../containers/cms/PasswordUpdate";
import * as authActions from '../../containers/AuthContainer/auth.reducer'
import {logout} from '../../containers/AuthContainer/auth.reducer'
import AudioProduction from "../../containers/cms/entities/Productions/AudioProductions";
import AudioProductionDetail from "../../containers/cms/entities/Productions/AudioProductionDetail";
import AudioProductionUpdate from "../../containers/cms/entities/Productions/AudioProductionUpdate";
import AudioProductionOrders from "../../containers/cms/entities/Productions/AudioProductionOrders";
import AudioProductionOrderDetail from "../../containers/cms/entities/Productions/AudioProductionOrderDetail";
import AudioProductionOrderUpdate from "../../containers/cms/entities/Productions/AudioProductionOrderUpdate";
import VideoProductions from "../../containers/cms/entities/Productions/VideoProductions";
import VideoProductionDetail from "../../containers/cms/entities/Productions/VideoProductionDetail";
import VideoProductionUpdate from "../../containers/cms/entities/Productions/VideoProductionUpdate";
import VideoProductionOrders from "../../containers/cms/entities/Productions/VideoProductionOrders";
import VideoProductionOrderDetail from "../../containers/cms/entities/Productions/VideoProductionOrderDetail";
import VideoProductionOrderUpdate from "../../containers/cms/entities/Productions/VideoProductionOrderUpdate";
import Category from "../../containers/cms/entities/Shop/Category/Category";
import CategoryUpdate from "../../containers/cms/entities/Shop/Category/CategoryUpdate";
import CategoryDetail from "../../containers/cms/entities/Shop/Category/CategoryDetail";
import Hotels from "../../containers/cms/entities/Shop/Products/Hotels";
import Facility from "../../containers/cms/entities/Shop/Facility/Facility";
import FacilityUpdate from "../../containers/cms/entities/Shop/Facility/FacilityUpdate";
import HotelUpdate from "../../containers/cms/entities/Shop/Products/HotelUpdate";
import ProductDetail from "../../containers/cms/entities/Shop/Products/ProductDetail";
import Orders from "../../containers/cms/entities/Shop/Orders/Orders";
import OrdersUpdate from "../../containers/cms/entities/Shop/Orders/OrdersUpdate";
import Users from "../../containers/cms/entities/Shop/Users/Users";
import UsersUpdate from "../../containers/cms/entities/Shop/Users/UsersUpdate";
import UsersDetail from "../../containers/cms/entities/Shop/Users/UsersDetail";
import OrdersDetail from "../../containers/cms/entities/Shop/Orders/OrdersDetail";
import FacilityDetail from "../../containers/cms/entities/Shop/Facility/FacilityDetail";
import TopAttractionsUpdate from "../../containers/cms/entities/Shop/TopAttractions/TopAttractionsUpdate";
import TopAttractions from "../../containers/cms/entities/Shop/TopAttractions/TopAttractions";
import TopAttractionDetail from "../../containers/cms/entities/Shop/TopAttractions/TopAttractionDetail";
import DashboardMetrics from "../../containers/cms/DashboardMetrics";

class DashboardLayout extends Component {

    state = {
        sideNavVisible : true
    }

    componentDidMount() {
        if(this.props.userRole !== "ROLE_ADMIN"){
            this.props.history.push("/login");
        }
        this.hideNavIfMobile();
        window.addEventListener("resize", this.hideNavIfMobile.bind(this))
    }

    hideNavIfMobile = () => {
        if(window.innerWidth < 768){
            this.setState({sideNavVisible: false});
        }
    }

    changeSideNavState = () => {
        this.setState( {sideNavVisible: !this.state.sideNavVisible});
    }

    render(){
        return (
            <div className={classes.wrapper}>
                <nav className={classes.sidebar} style={this.state.sideNavVisible ? {marginLeft: "0"} : {marginLeft: "-250px"}}>
                    <div className={classes.sidebarHeader}>
                        <Link to={"/dashboard"}>
                            <img src={logo} alt="Chords Nepal" className={classes.logo}/>
                            <h3 style={{textAlign: "center"}}>Hotel Daraz</h3>
                        </Link>
                    </div>

                    <ul className={classes.components}>
                        <p style={{fontSize: "1.3em", padding: "10px", fontWeight: "600"}}>Dashboard</p>
                        <li>
                            <NavItemComponent path={`${this.props.match.url}`} title={"Home"} icon="fa-home"/>
                        </li>
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseArtists"><ItemListIcon name="fa-users"/> Artists<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseArtists">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/artists/new`} title={"Add an Artist"} icon="fa-plus-circle"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/artists`} title={"List Artists"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseSongs"><ItemListIcon name="fa-music"/> Songs<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseSongs">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/songs/new`} title={"Add a Song"} icon="fa-plus-circle"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/songs`} title={"List Songs"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseAudioProduction"><ItemListIcon name="fa-microphone"/> Audio Production<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseAudioProduction">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/audioProduction/new`} title={"Add a Audio Production"} icon="fa-plus-circle"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/audioProduction`} title={"List Audio Productions"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/audioProductionOrders`} title={"List Audio Productions Orders"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseVideoProduction"><ItemListIcon name="fa-video-camera"/> Video Production<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseVideoProduction">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/videoProduction/new`} title={"Add a Video Production"} icon="fa-plus-circle"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/videoProduction`} title={"List Video Productions"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/videoProductionOrders`} title={"List Video Productions Orders"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseCategories"><ItemListIcon name="fa-th"/> Categories<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseCategories">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/categories/new`} title={"Add a Category"} icon="fa-plus-circle"/>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/categories`} title={"List Categories"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li>
                            <a data-toggle="collapse" href="#collapseFacilities"><ItemListIcon name="fa-check-square"/> Facilities<DropdownButtonDrops/></a>
                            <ul className="collapse" id="collapseFacilities">
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/facilities/new`} title={"Add a Facility"} icon="fa-plus-circle"/>
                                </li>
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/facilities`} title={"List Facilities"} icon="fa-th-list"/>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a data-toggle="collapse" href="#collapseTopAttractions"><ItemListIcon name="fa-camera-retro"/> Top Attractions<DropdownButtonDrops/></a>
                            <ul className="collapse" id="collapseTopAttractions">
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/topAttractions/new`} title={"Add a Top Attraction"} icon="fa-plus-circle"/>
                                </li>
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/topAttractions`} title={"List Top Attractions"} icon="fa-th-list"/>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a data-toggle="collapse" href="#collapseHotels"><ItemListIcon name="fa-home"/> Hotels<DropdownButtonDrops/></a>
                            <ul className="collapse" id="collapseHotels">
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/hotels/new`} title={"Add a Hotel"} icon="fa-plus-circle"/>
                                </li>
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/hotels`} title={"List Hotels"} icon="fa-th-list"/>
                                </li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <a data-toggle="collapse" href="#collapseOrders"><ItemListIcon name="fa-shopping-cart"/> Orders<DropdownButtonDrops/></a>*/}
                        {/*    <ul className="collapse" id="collapseOrders">*/}
                        {/*        <li>*/}
                        {/*            <NavItemComponent path={`${this.props.match.url}/orders`} title={"List Orders"} icon="fa-th-list"/>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li>
                            <a data-toggle="collapse" href="#collapseUsers"><ItemListIcon name="fa-users"/> Users<DropdownButtonDrops/></a>
                            <ul className="collapse" id="collapseUsers">
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/users/new`} title={"Add a User"} icon="fa-plus-circle"/>
                                </li>
                                <li>
                                    <NavItemComponent path={`${this.props.match.url}/users`} title={"List Users"} icon="fa-th-list"/>
                                </li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="#">Portfolio</a>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a href="#">Contact</a>*/}
                        {/*</li>*/}
                    </ul>
                </nav>

                <div className={classes.content}>

                    <nav className={["navbar navbar-expand-lg navbar-light bg-light", classes.navbar].join(" ")}>
                        <div className="container-fluid">

                            <button type="button" className="btn btn-info" onClick={this.changeSideNavState}>
                                <i className="fa fa-align-left"></i>
                                <span className={classes.sidebarCollapseSpan}> Toggle Sidebar</span>
                            </button>
                            <button className="btn btn-secondary d-inline-block d-lg-none ml-auto" type="button"
                                    data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <i className="fa fa-align-justify"></i>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="nav navbar-nav ml-auto">
                                    <li>
                                        <Link className={"nav-link " + classes.navLink} to={"/dashboard/settings"}>Settings</Link>
                                    </li>
                                    <li>
                                        <Link to={"/login"} className={"nav-link " + classes.navLink}  onClick={() => logout()}>Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <Switch>
                        <Route exact path={`${this.props.match.url}`} component={DashboardMetrics}/>
                        <Route exact path={`${this.props.match.url}/settings`} component={CmsSettings}/>
                        <Route exact path={`${this.props.match.url}/settings/edit`} component={CmsSettingsUpdate}/>
                        <Route exact path={`${this.props.match.url}/settings/changePassword`} component={PasswordUpdate}/>

                        {/*ARTISTS*/}
                        <Route exact path={`${this.props.match.url}/artists/:id/edit`} component={ArtistsUpdate}/>
                        <Route exact path={`${this.props.match.url}/artists/new`} component={ArtistsUpdate}/>
                        <Route exact path={`${this.props.match.url}/artists/:id`} component={ArtistsDetail}/>
                        <Route exact path={`${this.props.match.url}/artists`} component={Artists}/>

                        {/*SONGS*/}
                        <Route exact path={`${this.props.match.url}/songs/:id/edit`} component={SongsUpdate}/>
                        <Route exact path={`${this.props.match.url}/songs/new`} component={SongsUpdate}/>
                        <Route exact path={`${this.props.match.url}/songs/:id`} component={SongsDetail}/>
                        <Route exact path={`${this.props.match.url}/songs`} component={Songs}/>

                        {/*AUDIO PRODUCTION*/}
                        <Route exact path={`${this.props.match.url}/audioProductionOrders/:id/edit`} component={AudioProductionOrderUpdate}/>
                        <Route exact path={`${this.props.match.url}/audioProduction/:id/edit`} component={AudioProductionUpdate}/>
                        <Route exact path={`${this.props.match.url}/audioProduction/new`} component={AudioProductionUpdate}/>
                        <Route exact path={`${this.props.match.url}/audioProductionOrders/:id`} component={AudioProductionOrderDetail}/>
                        <Route exact path={`${this.props.match.url}/audioProduction/:id`} component={AudioProductionDetail}/>
                        <Route exact path={`${this.props.match.url}/audioProductionOrders`} component={AudioProductionOrders}/>
                        <Route exact path={`${this.props.match.url}/audioProduction`} component={AudioProduction}/>

                        {/*Video PRODUCTION*/}
                        <Route exact path={`${this.props.match.url}/videoProductionOrders/:id/edit`} component={VideoProductionOrderUpdate}/>
                        <Route exact path={`${this.props.match.url}/videoProduction/:id/edit`} component={VideoProductionUpdate}/>
                        <Route exact path={`${this.props.match.url}/videoProduction/new`} component={VideoProductionUpdate}/>
                        <Route exact path={`${this.props.match.url}/videoProductionOrders/:id`} component={VideoProductionOrderDetail}/>
                        <Route exact path={`${this.props.match.url}/videoProduction/:id`} component={VideoProductionDetail}/>
                        <Route exact path={`${this.props.match.url}/videoProductionOrders`} component={VideoProductionOrders}/>
                        <Route exact path={`${this.props.match.url}/videoProduction`} component={VideoProductions}/>

                        {/*Categories*/}
                        <Route exact path={`${this.props.match.url}/categories/:id/edit`} component={CategoryUpdate}/>
                        <Route exact path={`${this.props.match.url}/categories/new`} component={CategoryUpdate}/>
                        <Route exact path={`${this.props.match.url}/categories/:id`} component={CategoryDetail}/>
                        <Route exact path={`${this.props.match.url}/categories`} component={Category}/>

                        {/*Facilities*/}
                        <Route exact path={`${this.props.match.url}/facilities/:id/edit`} component={FacilityUpdate}/>
                        <Route exact path={`${this.props.match.url}/facilities/new`} component={FacilityUpdate}/>
                        <Route exact path={`${this.props.match.url}/facilities/:id`} component={FacilityDetail}/>
                        <Route exact path={`${this.props.match.url}/facilities`} component={Facility}/>

                        {/*Top Attractions*/}
                        <Route exact path={`${this.props.match.url}/topAttractions/:id/edit`} component={TopAttractionsUpdate}/>
                        <Route exact path={`${this.props.match.url}/topAttractions/new`} component={TopAttractionsUpdate}/>
                        <Route exact path={`${this.props.match.url}/topAttractions/:id`} component={TopAttractionDetail}/>
                        <Route exact path={`${this.props.match.url}/topAttractions`} component={TopAttractions}/>

                        {/*Hotel*/}
                        <Route exact path={`${this.props.match.url}/hotels/:id/edit`} component={HotelUpdate}/>
                        <Route exact path={`${this.props.match.url}/hotels/new`} component={HotelUpdate}/>
                        <Route exact path={`${this.props.match.url}/hotels/:id`} component={ProductDetail}/>
                        <Route exact path={`${this.props.match.url}/hotels`} component={Hotels}/>

                        {/*Orders*/}
                        <Route exact path={`${this.props.match.url}/orders/:id/edit`} component={OrdersUpdate}/>
                        <Route exact path={`${this.props.match.url}/orders/:id`} component={OrdersDetail}/>
                        <Route exact path={`${this.props.match.url}/orders`} component={Orders}/>

                        {/*Users*/}
                        <Route exact path={`${this.props.match.url}/users/:id/edit`} component={UsersUpdate}/>
                        <Route exact path={`${this.props.match.url}/users/new`} component={UsersUpdate}/>
                        <Route exact path={`${this.props.match.url}/users/:id`} component={UsersDetail}/>
                        <Route exact path={`${this.props.match.url}/users`} component={Users}/>
                    </Switch>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        userRole: state.auth.userRole
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(authActions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);

export const DropdownButtonDrops = () => {
    return (
        <i className="fa fa-chevron-circle-down" style={{float: "right", marginTop: "7px"}} aria-hidden="true"></i>
    )
}

export const NavItemComponent = (props) => {
    return (<>
        <NavLink exact activeClassName={classes.active} to={props.path}><ItemListIcon name={props.icon}/> {props.title}</NavLink>
        </>
    );
}

export const ItemListIcon = (props) => {
    return (
        <FontAwesomeComponent style={{marginRight: "5px"}} name={props.name}/>
    );
}
