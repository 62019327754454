export const artistListPage = "/dashboard/artists"
export const songListPage = "/dashboard/songs"
export const categoriesListPage = "/dashboard/categories"
export const hotelsListPage = "/dashboard/hotels"
export const ordersListPage = "/dashboard/orders"
export const usersListPage = "/dashboard/users"
export const facilitiesListPage = "/dashboard/facilities"
export const topAttractionsListPage = "/dashboard/topAttractions"
export const audioProductionListPage = "/dashboard/audioProduction"
export const audioProductionOrderListPage = "/dashboard/audioProductionOrders"
export const videoProductionListPage = "/dashboard/videoProduction"
export const videoProductionOrderListPage = "/dashboard/videoProductionOrders"
export const chordsViewPage = "/song"
export const adminSettingPage = "/dashboard/settings"