import React from 'react';
import FontAwesomeComponent from "../FontAwesomeComponent";
import {DropdownButtonDrops, ItemListIcon, NavItemComponent} from "../../layouts/Dashboard/DashboardLayout";
import classes from "../../layouts/Dashboard/Dashboard.module.css";
import {NavLink} from "react-router-dom";

const CategoryNavItem = (props) => {
    return (
        <>
            <NavLink exact activeClassName={classes.categoryActive} className={classes.categoryNavLink} to={'#'} data-toggle="collapse" data-target={`#${props.id}`} aria-haspopup="true" aria-expanded="false">
                <ItemListIcon name={props.icon}/>
                {props.name}
            </NavLink>
        </>
    );
}

export default CategoryNavItem;