import React, {Component} from 'react';
import ProductListItem from "../../components/Store/ProductListItem";
import ProductService from "../../services/Shop/HotelService";
import Spinner from "../../components/Spinnner/Spinner";
import {CLOUDINARY_URL} from "../../constants/GlobalConstants";
import CategoryService from "../../services/Shop/CategoryService";

class ShopHomeScreen extends Component {
    state = {
        entities: [],
        page: 0,
        sortField: 'sortOrder,createdDate',
        sortOrder: 'asc',
        totalPages: 0
    }

    componentDidMount() {
        ProductService.getPublicPagedProducts({page: this.state.page, sortField: this.state.sortField, sortOrder: this.state.sortOrder}).then(
            res => {
                this.setState({entities: [...res.data.content], totalPages: res.data.totalPages});
            }
        );
    }

    gotoProductDetails = (id) => {
        this.props.history.push(`${this.props.match.url}${id}`);
    }

    render() {
        if(!this.state.entities){
            return (
                <Spinner/>
                )
        }

        return (
            <>
                <div id="carouselExampleIndicators" className="carousel slide my-4" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img className="d-block img-fluid" src="http://placehold.it/900x350"
                                 alt="First slide"/>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block img-fluid" src="http://placehold.it/900x350"
                                 alt="Second slide"/>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block img-fluid" src="http://placehold.it/900x350"
                                 alt="Third slide"/>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                       data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                       data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>

                <div className="row">
                    {this.state.entities.map(product => (
                        <ProductListItem onClick={this.gotoProductDetails} id={product.id} imageUrl={`${CLOUDINARY_URL}w_700,h_400,c_fill/${product.imageUrl}`} title={product.name} sortOrder={product.sortOrder} price={product.price} description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet numquam aspernatur!"}/>
                    ))}
                </div>
            </>
        );
    }
}

export default ShopHomeScreen;