import React, {Component} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../components/FontAwesomeComponent";
import SongService from "../../../../services/SongService";
import {
    DateFormatCMSComponent,
    NepaliCurrency,
    ViewComponentExternalLink,
    ViewComponentLink
} from "../../../../components/CMSFormComponents";
import {artistListPage, videoProductionListPage, chordsViewPage} from "../../../../constants/routesConstants";
import VideoProductionService from "../../../../services/VideoProductionService";
import VideoProductionOrderService from "../../../../services/VideoProductionOrderService";

class VideoProductionOrderDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        VideoProductionOrderService.getVideoProductionOrder(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">User</td>
                                {/*TODO Username Page Link*/}
                                <th>{this.state.entity.userId}: {this.state.entity.userName}</th>
                            </tr>
                            <tr>
                                <td scope="row">Video Production Type</td>
                                <th><ViewComponentLink url={`${videoProductionListPage}/${this.state.entity.videoProductionId}`} text={`${this.state.entity.videoProductionId}: ${this.state.entity.videoProductionType}`}/></th>
                            </tr>
                            <tr>
                                <td scope="row">Order Price</td>
                                <th><NepaliCurrency value={this.state.entity.orderPrice}/></th>
                            </tr>
                            <tr>
                                <td scope="row">Production Order Status</td>
                                <th>{this.state.entity.productionOrderStatus}</th>
                            </tr>
                            <tr>
                                <td scope="row">Order Date</td>
                                <th><DateFormatCMSComponent value={this.state.entity.orderDate}/></th>
                            </tr>
                            {this.state.entity.orderStatusDate && <tr>
                                <td scope="row">Order Change Status Date</td>
                                <th><DateFormatCMSComponent value={this.state.entity.orderStatusDate}/></th>
                            </tr>}
                            {this.state.entity.remarks && <tr>
                                <td scope="row">Remarks</td>
                                <th>{this.state.entity.remarks}</th>
                            </tr>}
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                            <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default VideoProductionOrderDetail;