import React from 'react';
import './App.css';
import RootLayout from './layouts/RootLayout/RootLayout'

function App() {
  return (
    <div className="App">
      <RootLayout/>
    </div>
  );
}

export default App;
