import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants'

class SongService {

    getAudioProduction(id){
        return axios.get(BASE_URL + "/audioProduction/" + id)
    }

    getPagedAudioProductions(options){
        let url = BASE_URL + "/audioProduction?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        return axios.get(url);
    }

    searchForAudioProductionsByType(audioProductionType, page, size){
        let url = BASE_URL + "/audioProduction?audioProductionType=" + audioProductionType + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postAudioProduction(formData){
        return axios.post(BASE_URL + "/audioProduction", formData);
    }

    putAudioProduction(song){
        return axios.put(BASE_URL + "/audioProduction", song);
    }

    deleteAudioProduction(songId){
        return axios.delete(BASE_URL + `/audioProduction/${songId}`);
    }

}

export default new SongService();