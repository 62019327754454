import React, {Component} from 'react';
import AuthenticationService from "../../services/AuthenticationService";
import * as actions from '../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {adminSettingPage} from "../../constants/routesConstants";

class PasswordUpdate extends Component {
    state = {
        error: null,
        oldPassword: null,
        newPassword: null,
        retypePassword: null
    }

    onSubmit = () => {

        if(!this.state.oldPassword || !this.state.newPassword || !this.state.retypePassword){
            this.setState({error: "All the fields are required!"})
            return;
        }

        if(this.state.newPassword.length < 5){
            this.setState({error: "Password too Short!"})
            return;
        }
        if(this.state.newPassword !== this.state.retypePassword){
            this.setState({error: "Confirm Password does not match!"})
            return;
        }

        AuthenticationService.updatePassword(this.state.oldPassword, this.state.newPassword)
            .then(() => {
                this.props.sucessToast("Password Updated Successful");
                this.props.history.push(adminSettingPage);
            })
            .catch((err) => {
                if(err.response && err.response.data.message === "Password Incorrect") this.setState({error: "Incorrect Old Password!"})
                else this.setState({error: "Something went wrong!"})
            })
    }

    render() {
        return (
            <>
                <div className="card" style={{width: "80%", margin: "0 auto", maxWidth: "600px"}}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textAlign: "center"}}>Password Update?</h5>
                        {this.state.error && <div className="alert alert-danger" role="alert">{this.state.error}</div>}
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="password" className={"form-control"} style={{marginBottom: "15px"}} id="oldPassword" placeholder="Old Password"
                               value={this.props.oldPassword}
                               onChange={(event) => this.setState({oldPassword: event.target.value})}/>
                        <label htmlFor="newPassword">New Password</label>
                        <input type="password" className={"form-control"} style={{marginBottom: "15px"}} id="newPassword" placeholder="New Password"
                               value={this.props.newPassword}
                               onChange={(event) => this.setState({newPassword: event.target.value})}/>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input type="password" className={"form-control"} id="confirmPassword" placeholder="Retype Password"
                               value={this.props.retypePassword}
                               onChange={(event) => this.setState({retypePassword: event.target.value})}/>
                        <button onClick={this.onSubmit} style={{marginTop: "15px", width: "100%"}} className="btn btn-primary">Update Password</button>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(PasswordUpdate);