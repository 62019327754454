import React, {Component} from 'react';
import AuthenticationService from "../../services/AuthenticationService";
import ArtistImage from "../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import {songListPage} from "../../constants/routesConstants";
import FontAwesomeComponent, {
    BackFontAwesome,
    EditFontAwesome, ImageIcon,
    PasswordIcon
} from "../../components/FontAwesomeComponent";
import Spinner from "../../components/Spinnner/Spinner";
import logo from '../../assets/icon.png'
import classes from './entities/Artists/styles/artist.module.css'
import UserAdminService from "../../services/UserAdminService";

class DashboardMetrics extends Component {
    state ={
        viewCount: null
    }

    componentDidMount() {
        UserAdminService.getTotalViewOfHotels().then(
            res => {
                this.setState({viewCount: res.data})
            }
        ).catch(() => this.setState({error: "Something Went Wrong"}))
    }

    render() {
        if(!this.state.viewCount){
            return <Spinner/>
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", width: "75%", minWidth: "600px", margin: "0 auto"}}>
                    <div className="card-body">
                        Total Hotel Views Collected till now: <b>{this.state.viewCount}</b>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardMetrics;
