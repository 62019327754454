import React from "react";

const Error = ({ touched, message }) => {
  if (!touched) {
    return null;
  }
  if (message) {
    return <div style={{color: "#721c24", fontSize: "0.7rem"}}>{message}</div>;
  }
  return null;
};

export default Error;