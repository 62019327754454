import React, {Component, createRef} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import ErrorFormItem from "../../../../components/ErrorFormItem";
import {Formik} from "formik";
import classes from '../Artists/styles/artist.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import IArtist from "../../../../models/IArtist";
import {jsonToBlob} from "../../../../utils/Utils";
import {
    artistListPage,
    videoProductionListPage, videoProductionOrderListPage,
    chordsViewPage,
    songListPage
} from "../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleDropZoneUploader,
    ViewComponentExternalLink,
    SingleAutoCompleteCMSComponent, FormTextAreaField, SelectCMSComponent, FormSelectField
} from "../../../../components/CMSFormComponents";
import {ModalView} from "../../../../components/BootstrapModal";
import SongService from "../../../../services/SongService";
import {CLOUDINARY_CHORDS_URL} from "../../../../constants/GlobalConstants";
import VideoProductionService from "../../../../services/VideoProductionService";
import VideoProductionOrderService from "../../../../services/VideoProductionOrderService";
import moment from "moment";
import ProductionOrderStatusService from "../../../../services/ProductionOrderStatusService";

class VideoProductionOrderUpdate extends Component {

    state = {
        id: null,
        entity: null,
        remarks: '',
        orderPrice: 0,
        productionStatus: '',
        productionOrderStatusList: []
    }

    backButtonRef = createRef();

    validationSchema = Yup.object().shape({
        remarks: Yup.string().max(255, "Too Long"),
        orderPrice: Yup.number().min(1, "Too Short").required("Required")
    });

    componentDidMount() {
        ProductionOrderStatusService.getAllProductionOrderStatus().then(res => this.setState({productionOrderStatusList: res.data}));
        VideoProductionOrderService.getVideoProductionOrder(this.props.match.params.id).then(
            item => this.setState({id: item.data.id, remarks: item.data.remarks ? item.data.remarks : '', orderPrice: item.data.orderPrice, productionStatus: item.data.productionOrderStatus, entity: item.data})
        )
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        if(!this.state.productionStatus){
            setSubmitting(false);
            toast.error("Something wrong with Order Status")
            return;
        }

        const jsonPayload = {remarks: values.remarks, orderPrice: values.orderPrice, productionOrderStatus: this.state.productionStatus,id: this.state.id};
        const restServiceApi = VideoProductionOrderService.putVideoProductionOrder;
        restServiceApi(jsonPayload)
            .then(res => {
                this.props.sucessToast("Order: " + res.data.id + " is Updated.")
                setSubmitting(false)
                this.props.history.push(videoProductionOrderListPage)
            })
            .catch(err => {
                toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        VideoProductionOrderService.deleteVideoProductionOrder(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted Order: " + res.data.id)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>
                        <Formik initialValues={{ remarks: this.state.remarks, orderPrice: this.state.orderPrice }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <FormFieldDisabled name="ID" value={this.state.id}/>
                                        <FormField name="Order Price" required={true} type="number" touched={touched.orderPrice} errors={errors.orderPrice} value={values.orderPrice} setFieldValue={setFieldValue} fieldName='orderPrice' onBlurHandler={setFieldTouched}/>
                                        <FormTextAreaField name="Remarks" touched={touched.remarks} errors={errors.remarks} value={values.remarks} setFieldValue={setFieldValue} fieldName='remarks' onBlurHandler={setFieldTouched}/>
                                        <FormSelectField label="Status" value={this.state.productionStatus} onChange={data => this.setState({productionStatus: data})} list={this.state.productionOrderStatusList}/>
                                        <FormFieldDisabled name="Username" value={this.state.entity.userId + ": " + this.state.entity.userName}/>
                                        <FormFieldDisabled name="Video Production Type" value={this.state.entity.videoProductionType}/>
                                        <FormFieldDisabled name="Order Date" value={moment(this.state.entity.orderDate).format("DD/MM/YYYY hh:mm A")}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                <ModalView title={`Delete ${this.state.entity.id}`} id={this.state.id}
                            body={`Are you sure you want to remove Production Order: ${this.state.entity.id}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(VideoProductionOrderUpdate);