import React from 'react';
import classes from './Songs.module.css';
import { CLOUDINARY_URL } from '../../constants/GlobalConstants';

const SongItem = (props) => {

    return (
        <li key={props.song.id} onClick={props.onPress.bind(this, props.song.id)} className={"list-group-item Item"} style={{cursor: "pointer"}}>
            <span style={{float: "left"}}><img style={{width: "2.5em", borderRadius: "100px", marginTop: "10px"}} src={CLOUDINARY_URL + props.song.artistPhotoUrl} alt={props.song.artistPhotoUrl}/></span>
            <div style={{float: "left", margin: "10px 15px"}}>
                <b style={{fontWeight: "500"}}>{props.song.title}</b>
            <p style={{fontSize: "0.8em", marginBottom: "0"}}>{props.song.artistName}</p>
            </div>
        </li>
    );
}

export default SongItem;