import React from 'react';
import classes from './ArtistItem.module.css'
import {CLOUDINARY_URL} from '../../constants/GlobalConstants'

const ArtistItem = (props) => {
    return (
        <li key={props.artist.id} className={"list-group-item Item"} onClick={props.onPress.bind(this, props.artist.id)} style={{cursor: "pointer"}}>
            <span style={{float: "left"}}><img style={{width: "4em", borderRadius: "100px"}} src={CLOUDINARY_URL + props.artist.photoUrl} alt={props.artist.name}/></span>
            <div style={{float: "left", margin: "10px 15px"}}><b style={{fontWeight: "500"}}>{props.artist.name.length  > 19 && window.innerWidth < 350 ? props.artist.name.substring(0, 16) + '...' : props.artist.name}</b>
            <p style={{fontSize: "0.8em", marginBottom: "0"}}>{props.artist.songs.length} songs</p></div>
        </li>
    );
}

export default ArtistItem;