import React, {useRef, useState, useEffect} from 'react';
import {
    FormField,
    FormFieldDateTimePicker,
    FormFieldDisabled,
    NepaliCurrency,
    SimpleFormField
} from "./CMSFormComponents";
import ArtistImage from "./ArtistItem/ArtistImage";
import {CLOUDINARY_URL} from "../constants/GlobalConstants";
import {RefreshFontAwesome, RemoveFontAwesome} from "./FontAwesomeComponent";
import innerclasses from "../containers/Store/css/productScreen.module.css";
import ProductService from "../services/Shop/HotelService";
import Spinner from "./Spinnner/Spinner";
import OrderService from "../services/Shop/OrderService";

export const OrderItemUpdateModal = (props) => {

    const cancelButtonRef = useRef(null);
    const [item, setItem] = useState(JSON.parse(JSON.stringify(props.updateEntity)));

    const [quantity, setQuantity] = useState(item.quantity);
    const [total, setTotal] = useState(item.total);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        ProductService.getProduct(item.product.id).then(res => {
            setProduct(res.data)
        })
    }, [item.id]);

    const closeModal = () => {
        setTimeout(() => {
            props.resetOrderItem();
            setItem({})
        }, 100);
    }

    const onConfirm = () => {
        let productOptions = [];
        item.orderItemOptionTypes.map(ot => productOptions.push({productOption: {"id": ot.productOption.id}, productOptionType: {id: ot.productOptionType.id}}))
        OrderService.orderItemUpdate(item.id, quantity, productOptions).then(
            res => {
                props.callback();
                cancelButtonRef.current.click();
            }
        )


    }

    const refreshPrices = () => {
        let productOptions = [];
        item.orderItemOptionTypes.map(ot => productOptions.push({productOption: {"id": ot.productOption.id}, productOptionType: {id: ot.productOptionType.id}}))
        OrderService.checkUpdatedOrderItemPrice(item.id, quantity, productOptions).then(
            res => setTotal(res.data)
        ).catch(() => alert("Something went wrong!"));
    }

    return (
        <div className="modal fade" id="orderItemModal" tabIndex="-1" role="dialog" aria-labelledby="orderItemModalModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="customModalLabel">{props.title}</h5>
                        <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ArtistImage photoUrl={`${item.product.productImageDTOS[0].url}`} size={5}/>
                        <div className="row">
                            <FormFieldDisabled name="Product Name" value={item.product.name}/>
                            <FormFieldDisabled name="Price" value={item.product.discount ? item.product.discount : item.product.price}/>
                            <SimpleFormField name="Quantity" required={true} type="number" value={quantity} onChange={(v) => setQuantity(v)}/>
                        </div>
                        <hr/>
                        {product ? product.productOptionDTOS.map(po => {
                            // po.id (6) / item.orderItemOptionTypes[].productOption.id (6)
                            const itemOption = item.orderItemOptionTypes.find(ot => ot.productOption.id === po.id);
                            return (
                                <div className="col-sm-12">
                                    <dl className="dlist-inline">
                                        <dt style={{marginBottom: "-12px"}}>{po.title}:</dt>
                                        <dd>
                                            {po.types.map(type => (
                                                <label className={"form-check form-check-inline "}>
                                                        <span className={"form-check-label "}>
                                                        <input className="form-check-input" type="radio"
                                                               onClick={() => {
                                                                   let orderItemOptionTypes = item.orderItemOptionTypes
                                                                   orderItemOptionTypes.map(ot => {
                                                                       if(ot.productOption.id === po.id){
                                                                           ot.productOptionType.id = type.id;
                                                                       }
                                                                       return ot;
                                                                   })
                                                                   setItem({...item, orderItemOptionTypes: orderItemOptionTypes});
                                                               }}
                                                               checked={itemOption.productOptionType.id === type.id}
                                                               name={po.title} id={type.id}
                                                               value="option2"/>
                                                           <div className={innerclasses.selector}>
                                                            {type.imageUrl && <img style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                objectFit: "cover",
                                                                borderRadius: "5px"
                                                            }}
                                                                                   src={`${CLOUDINARY_URL}w_50,h_50,c_fill/${type.imageUrl}`}
                                                                                   alt=""/>}
                                                               <span>{type.name}</span>
                                                               {type.priceChanges > 0 && <span style={{fontSize: "0.8em"}}>({type.priceModifier} <NepaliCurrency value={type.priceChanges}/>)</span>}
                                                           </div>
                                                        </span>
                                                </label>
                                            ))}
                                        </dd>
                                    </dl>
                                </div>
                            )
                        }) : <Spinner size={5}/>}
                        <hr/>
                        <div className="row">
                            <FormFieldDisabled name="Total" value={total}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => refreshPrices()} className="btn btn-primary" ><RefreshFontAwesome/> Update Prices</button>
                        <button type="button" onClick={() => closeModal()} ref={cancelButtonRef} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={() => onConfirm()} className={`btn ${props.buttonTheme}`}>{props.confirmLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}