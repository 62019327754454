import {BASE_URL} from "../../constants/GlobalConstants";
import axios from 'axios';
import jwtDecode from 'jwt-decode'
import AuthenticationService from "../../services/AuthenticationService";

// Action Types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


// Actions

export const auth = (username, password, router) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password
        };
        let url = `${BASE_URL}/token`;
        axios.post(url, authData)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                const decoded = jwtDecode(response.data.token);
                const d = new Date(0);
                d.setUTCSeconds(decoded.exp);
                dispatch(authSuccess(response.data.token, decoded.scopes, d));
                successRedirection(decoded.scopes, router);
                dispatch(checkAuthTimeout((d.getTime() - new Date().getTime()) / 1000));
            })
            .catch(err => {
                dispatch(authFail(err.response && err.response.data.status === 401 ? "Username or Password Incorrect" : "Something went wrong!"));
            });
    };
};

const successRedirection = (role, router) => {
    if(role === "ROLE_ADMIN"){
        router("/dashboard");
    }
}

export const authStart = () => {
    return {
        type: AUTH_START
    };
};

export const authCheckState = (isJWTChecked, router) => {
    return dispatch => {
        const token = localStorage.getItem("token");
        isJWTChecked(true);
        if(!token){
            dispatch(logout());
        } else {
            const decoded = jwtDecode(token);
            const d = new Date(0);
            d.setUTCSeconds(decoded.exp);
            if(d <= new Date()){
                dispatch(logout(router));
            } else {
                dispatch(authSuccess(token, decoded.scopes, d));
                dispatch(checkAuthTimeout((d.getTime() - new Date().getTime()) / 1000));
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    return {
        type: AUTH_LOGOUT
    };
};

export const authSuccess = (token, userRole, expiresAt) => {
    AuthenticationService.setupAxiosInterceptors(`Bearer ${token}`);
    return {
        type: AUTH_SUCCESS,
        idToken: token,
        userRole: userRole,
        expiresAt: expiresAt
    };
};

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    };
};

// Reducer
const initialState = {
    token: null,
    userRole: null,
    expiresAt: null,
    error: null,
    authRedirectPath: '/'
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case AUTH_START: return {...state, error: null}; //WHY?
        case AUTH_SUCCESS: {return {...state, token: action.idToken, userRole: action.userRole, expiresAt: action.expiresAt, error: null};}
        case AUTH_FAIL: return {...state, error: action.error};
        case AUTH_LOGOUT: return {...state, token: null, userRole: null, expiresAt: null};
        // case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default:
            return state;
    }
};



export default reducer;