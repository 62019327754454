import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, combineReducers, compose} from 'redux'
import artistReducer from './containers/ArtistContainer/artist.reducer'
import songReducer from './containers/SongContainer/song.reducer'
import toastReducer from './layouts/RootLayout/rootLayout.reducer'
import authReducer from './containers/AuthContainer/auth.reducer'
import thunk from 'redux-thunk';
import 'font-awesome/css/font-awesome.min.css';
import AuthenticationService from "./services/AuthenticationService";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  artists: artistReducer,
  songs: songReducer,
  toast: toastReducer,
  auth: authReducer
});

// AuthenticationService.getLocalStorageJwt(() => {});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
