import {CLOUDINARY_URL} from "../../constants/GlobalConstants";
import {MinusIcon} from "../FontAwesomeComponent";
import React from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const DnDImageList = (props) => {

    return (
        <DragDropContext onDragEnd={props.imageOnDragEnd}>
            <Droppable droppableId="imageUrls">
                {(provided) => (
                    <table style={{width: "500px", margin: "0 auto"}} className="imageUrls" {...provided.droppableProps} ref={provided.innerRef}>
                        {
                            props.imageUrls.map((image, index) => {
                                return (
                                    <Draggable key={image.id} draggableId={image.url + image.id} index={index}>
                                        {(provided) => (
                                            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <td>
                                                    <img style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        objectFit: "cover",
                                                        border: "1px solid #ccc",
                                                        margin: "10px",
                                                        borderRadius: "5px"
                                                    }} src={`${CLOUDINARY_URL}/${image.url}`}/>
                                                </td>
                                                <td>{(props.size - 1) !== props.index &&
                                                <button type="button" onClick={() => props.onRemoveImagePreview(index)} className="btn btn-danger">
                                                    <MinusIcon/></button>}</td>
                                            </tr>

                                        )}
                                    </Draggable>
                            )
                            })
                        }
                        {provided.placeholder}
                    </table>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DnDImageList;