import React, {Component} from 'react';
import ArtistService from '../../services/ArtistService'
import ArtistItem from '../../components/ArtistItem/ArtistItem'
import './ArtistContainer.css'
import Spinner from '../../components/Spinnner/Spinner'
import {Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as actions from './artist.reducer'
import {connect} from 'react-redux'

class ArtistContainer extends Component {

    componentDidMount(){
        if(this.props.artistsState.artists.length === 0){
            this.props.fetchArtists({page: 0});
        }
    }

    onPressHandler = (id) => {
        this.props.history.push('/artist/' + id);
    }

    infiniteApiCall = () => {
        this.props.fetchArtists({page: this.props.artistsState.page, searchText: this.props.artistsState.searchText});
    }

    onSearchHandler = (event) => {
        if(event) event.preventDefault();
        if(this.props.artistsState.searchText !== ""){
            this.props.onSearchArtists({page: 0, searchText: this.props.artistsState.searchText});
        } else {
            this.props.onSearchArtists({page: 0, searchText: ""});
        }
    }

    onResetSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.props.resetSearch();
        this.props.onSearchArtists({page: 0, searchText: ""});
    }

    render(){
        if(this.props.artistsState.artists.length === 0 && !this.props.artistsState.hasMore){
            return (
                <>
                <SearchBar title="Artist" onResetSearch={this.onResetSearchHandler} onSearch={this.onSearchHandler} searchText={this.props.artistsState.searchText} setSearchText={this.props.setSearchText}/>
                <div className="card" style={{width: "80%", textAlign: "center", margin: "0 auto", maxWidth: "600px"}}>
                    <div className="card-body">
                        <h5 className="card-title">No Artist Found</h5>
                        <p className="card-text">Sorry we don't have any artist named <b>{this.props.artistsState.searchText}</b>. Please search for other artists.</p>
                        <button onClick={this.onResetSearchHandler} className="btn btn-primary">Reset</button>
                    </div>
                </div>
                </>
            )
        }

        if(this.props.artistsState.artists.length === 0){
            return (
                <Spinner size={5}/>
            )
        }

        return (
            <div style={{maxWidth: "600px", margin: "0 auto"}}>
                <SearchBar title="Artist" onResetSearch={this.onResetSearchHandler} onSearch={this.onSearchHandler} searchText={this.props.artistsState.searchText} setSearchText={this.props.setSearchText} entityServiceSearchCall={ArtistService.searchForArtist}/>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.infiniteApiCall}
                    hasMore={this.props.artistsState.hasMore}
                    loader={<div className="loader" key={0}><Spinner size={3}/></div>}
                    >
                    <ul className="list-group" style={{marginBottom: "10px"}}>
                        {
                            this.props.artistsState.artists.map(artist => {
                                return (
                                    <ArtistItem key={artist.id} artist={artist} onPress={this.onPressHandler}/>
                                )
                            })
                        }
                    </ul>
                </InfiniteScroll>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        artistsState: state.artists
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetchArtists: (options) => dispatch(actions.fetchArtists(options)),
        setSearchText: (value) => dispatch(actions.setSearchText(value)),
        onSearchArtists: (options) => dispatch(actions.onSearchArtists(options)),
        resetSearch: () => dispatch(actions.resetSearch())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistContainer);