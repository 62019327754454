import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './css/RichTextEditor.css'

export default class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        const html = props.value;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    reset = () => {
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({ editorState });
    }

    render() {
        const { editorState } = this.state;
        return (
            <>
                <div className="col-md-12" style={{marginTop: "10px", borderRadius: "0.25rem", padding: "10px"}}>
                    <label>{this.props.title}</label>
                    <Editor
                        placeholder={this.props.placeholder}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                    {/*<textarea*/}
                    {/*    disabled*/}
                    {/*    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}*/}
                    {/*/>*/}
                </div>
            </>
        );
    }
}