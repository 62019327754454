import axios from 'axios';
import {BASE_URL} from '../../constants/GlobalConstants'

class HotelService {

    getHotel(id){
        return axios.get(BASE_URL + "/hotels/" + id)
    }

    getPagedHotels(options){
        let url = BASE_URL + "/hotels?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        url = options.location && options.location !== '' ? url + "&location=" + options.location : url;
        return axios.get(url);
    }

    getPublicPagedHotels(options){
        let url = BASE_URL + "/hotels/public?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        url = options.category && options.category !== '' ? url + "&category=" + options.category : url;
        return axios.get(url);
    }

    searchForHotels(name, page, size){
        let url = BASE_URL + "/hotels?name=" + name + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    searchForHotelLocations(name, page, size){
        let url = BASE_URL + "/hotels/locations/" + name + "?page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postHotel(formData){
        return axios.post(BASE_URL + "/hotels", formData);
    }

    putHotel(hotels){
        return axios.put(BASE_URL + "/hotels", hotels);
    }

    deleteHotel(hotelsId){
        return axios.delete(BASE_URL + `/hotels/${hotelsId}`);
    }

}

export default new HotelService();