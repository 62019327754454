import axios from 'axios';
import {BASE_URL} from '../../constants/GlobalConstants'

class FacilityService {

    getFacility(id){
        return axios.get(BASE_URL + "/facilities/" + id)
    }

    getPagedFacilities(options){
        let url = BASE_URL + "/facilities?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        return axios.get(url);
    }

    searchForFacilities(name, page, size){
        let url = BASE_URL + "/facilities?name=" + name + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postFacility(formData){
        return axios.post(BASE_URL + "/facilities", formData);
    }

    putFacility(facilities){
        return axios.put(BASE_URL + "/facilities", facilities);
    }

    deleteFacility(facilitiesId){
        return axios.delete(BASE_URL + `/facilities/${facilitiesId}`);
    }

}

export default new FacilityService();