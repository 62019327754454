import Axios from "axios";
import { BASE_URL } from "../../constants/GlobalConstants";

// Action Types
export const SET_SONG_PAGED = 'SET_SONG_PAGED';
export const SEARCH_SONG_PAGED = 'SEARCH_SONG_PAGED';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const RESET_SEARCH = 'RESET_SEARCH';

// Actions
export const fetchSongs = (options) => {
    let url = `${BASE_URL}/songs?page=${options.page}&sort=title,asc`;
    url = options.searchText && options.searchText !== '' ? url + "&title=" + options.searchText : url;
    return dispatch => {
        Axios.get(url).then(res => {
            dispatch(setSongs(res.data));
        })
    }
}

export const onSearchSongs = (options) => {
    let url = `${BASE_URL}/songs?page=${options.page}&sort=title,asc`;
    url = options.searchText && options.searchText !== '' ? url + "&title=" + options.searchText : url;
    return dispatch => {
        Axios.get(url).then(res => {
            dispatch(searchSongs(res.data));
        })
    }
}

const setSongs = (payload) => {
    return {
        type: SET_SONG_PAGED,
        payload: payload
    }
}

export const setSearchText = (searchText) => {
    return {
        type: SET_SEARCH_TEXT,
        searchText: searchText
    }
}

export const searchSongs = (payload) => {
    return {
        type: SEARCH_SONG_PAGED,
        payload: payload
    }
}

export const resetSearch = () => {
    return {
        type: RESET_SEARCH
    }
}

// Reducer
const initialState = {
    songs : [],
    page: 0,
    size: null,
    sort: null,
    hasMore: true,
    searchText: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SONG_PAGED:
            return {...state, songs: [...state.songs, ...action.payload.content], page: state.page + 1, hasMore: !action.payload.last}
        case SET_SEARCH_TEXT:
            return {...state, searchText: action.searchText}
        case SEARCH_SONG_PAGED:
            return {...state, songs: [...action.payload.content], page: 1, hasMore: !action.payload.last}
        case RESET_SEARCH:
            return {...state, searchText: ''}
        default:
            return state;
        
    }
}

export default reducer;