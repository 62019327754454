import axios from 'axios';
import {BASE_URL} from '../../constants/GlobalConstants'

class TopAttractionsService {

    searchForTopAttractions(title, page, size){
        let url = BASE_URL + "/topAttractions?name=" + title + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    getTopAttraction(id){
        return axios.get(BASE_URL + "/topAttractions/" + id)
    }

    getPagedTopAttraction(options){
        let url = BASE_URL + "/topAttractions?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        return axios.get(url);
    }

    searchForTopAttraction(name, page, size){
        let url = BASE_URL + "/topAttractions?name=" + name + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postTopAttraction(formData){
        return axios.post(BASE_URL + "/topAttractions", formData);
    }

    putTopAttraction(topAttraction){
        return axios.put(BASE_URL + "/topAttractions", topAttraction);
    }

    deleteTopAttraction(topAttractionId){
        return axios.delete(BASE_URL + `/topAttractions/${topAttractionId}`);
    }
}

export default new TopAttractionsService();