import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants'

class AudioProductionOrderService {

    getAudioProductionOrder(id){
        return axios.get(BASE_URL + "/audioProductionOrder/" + id)
    }

    getPagedAudioProductionOrders(options){
        let url = BASE_URL + "/audioProductionOrder?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url += options.username && `&username=${options.username}`;
        url += options.audioProductionType && `&audioProductionType=${options.audioProductionType}`;
        url += options.startDate ? `&startDate=${options.startDate}` : '';
        url += options.endDate ? `&endDate=${options.endDate}` : '';
        url += options.productionStatus ? `&productionStatus=${options.productionStatus}` : '';
        return axios.get(url);
    }

    postAudioProductionOrder(formData){
        return axios.post(BASE_URL + "/audioProductionOrder", formData);
    }

    putAudioProductionOrder(entity){
        return axios.put(BASE_URL + "/audioProductionOrder", entity);
    }

    deleteAudioProductionOrder(entityId){
        return axios.delete(BASE_URL + `/audioProductionOrder/${entityId}`);
    }

}

export default new AudioProductionOrderService();