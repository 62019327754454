import React from 'react';
import SongContainer from "../../containers/SongContainer/SongContainer";
import ArtistSongContainer from "../../containers/ArtistSongContainer/ArtistSongContainer";
import ChordContainer from "../../containers/ChordContainer/ChordContainer";
import LoginContainer from "../../containers/AuthContainer/LoginContainer";
import RegisterContainer from "../../containers/AuthContainer/RegisterComponent";
import ArtistContainer from "../../containers/ArtistContainer/ArtistContainer";
import {Switch, Route} from 'react-router-dom'
import NavBar from "../NavBar/NavBar";
import ForgotPassword from "../../containers/AuthContainer/ForgotPassword";
import ShopScreen from "../../containers/Store/ShopScreen";
import ProductScreen from "../../containers/Store/ProductScreen";

const PublicRoutes = (props) => {
    return (
        <>
            <NavBar/>
            <div className="container" style={{marginTop: "72px"}}>
                <Switch>
                    <Route path="/login" exact component={LoginContainer}/>
                    <Route path="/register" exact component={RegisterContainer}/>
                    <Route path="/" exact component={LoginContainer}/>
                    <Route path="*" component={LoginContainer}/>
                </Switch>
            </div>
            <footer className="py-3 bg-dark">
                <div className="container">
                    <p className="m-0 text-center text-white">Copyright &copy; Hotel Daraz {new Date().getFullYear().toString()}</p>
                </div>
            </footer>
        </>
    );
}

export default PublicRoutes;
