import React, {Component, createRef} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../components/ArtistItem/ArtistImage";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import ErrorFormItem from "../../../../components/ErrorFormItem";
import {Formik} from "formik";
import classes from './styles/artist.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import IArtist from "../../../../models/IArtist";
import {jsonToBlob} from "../../../../utils/Utils";
import {artistListPage} from "../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {FormFieldDisabled, FormField, SingleDropZoneUploader} from "../../../../components/CMSFormComponents";
import {ModalView} from "../../../../components/BootstrapModal";

class ArtistsUpdate extends Component {

    state = {
        id: null,
        name: '',
        photoUrl: null,
        imageFile: null,
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id,
    }

    backButtonRef = createRef();

    dropZone = createRef();

    validationSchema = Yup.object().shape({
        name: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        image: null
    });

    componentDidMount() {
        if(this.state.isEditMode){
            ArtistService.getArtist(this.props.match.params.id).then(
                item => this.setState({id: item.data.id, name: item.data.name, photoUrl: item.data.photoUrl})
            )
        }
    }

    handleChangeStatus = ({ meta, file }, status) => {
        if(status === "done"){
            this.setState({imageFile: file})
        }
        if(status === "removed"){this.setState({imageFile: null})}
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        if(!this.state.imageFile && !this.state.isEditMode){
            setFieldError('image', 'Upload a valid Thumbnail');
            setSubmitting(false);
            return;
        }

        let formData = new FormData();
        const artist = {name: values.name, id: this.state.isEditMode ? this.state.id : null};
        formData.append('payload', jsonToBlob(artist));
        if(this.state.imageFile) formData.append('image', this.state.imageFile);

        const restServiceApi = this.state.isEditMode ? ArtistService.putArtist : ArtistService.postArtist;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.name + " is Updated." : "A New Artist: " + res.data.name + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({imageFile: null, saveAndNew: false})
                    this.dropZone.current.files = [];
                    this.dropZone.current.forceUpdate()
                }
                else this.props.history.push(artistListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('image', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteArtist = (id) => {
        ArtistService.deleteArtist(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.name)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>}
                        {this.state.isEditMode && <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                            <ArtistImage photoUrl={this.state.photoUrl} size={5} name={this.state.name}/>
                        </div>}
                        <Formik initialValues={{ name: this.state.name }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>
                                        // <div className="col-md-6">
                                        //     <label htmlFor="id">ID</label>
                                        //     <input type="text" disabled value={this.state.id}
                                        //            className="form-control" id="id" placeholder="ID"/>
                                        // </div>
                                        }
                                        <FormField name="Name" required={true} touched={touched.name} errors={errors.name} value={values.name} setFieldValue={setFieldValue} fieldName='name' onBlurHandler={setFieldTouched}/>
                                        {/*<div className="col-md-6">*/}
                                        {/*    <label htmlFor="name" style={{float: "left"}}>Name*</label>*/}
                                        {/*    <ErrorFormItem touched={touched.name} message={errors.name} style={{float: "right"}}/>*/}
                                        {/*    <input type="text" value={values.name} id="name" onChange={handleChange} onBlur={handleBlur} className={["form-control", touched.name && errors.name ? classes.hasError : ""].join(" ")} placeholder="Name"/>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="SingleImageUploadPanel" style={errors.image ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>
                                        <label style={{float: "left", padding: "5px"}}>Thumbnail*</label>
                                        <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.image}</div>
                                        <SingleDropZoneUploader accept="image/*" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={this.state.isEditMode ? "Update Thumbnail" : "Drag Image or Click to Browse"}/>
                                        {/*<Dropzone*/}
                                        {/*    ref={this.dropZone}*/}
                                        {/*    onChangeStatus={this.handleChangeStatus}*/}
                                        {/*    inputContent={this.state.isEditMode ? "Update Thumbnail" : "Drag Image or Click to Browse"}*/}
                                        {/*    multiple={false}*/}
                                        {/*    maxFiles={1}*/}
                                        {/*    styles={{*/}
                                        {/*        dropzone: { overflow: 'auto', border: '2px dashed #eeeeee', borderRadius: "2px", background: '#fafafa', color: "#bdbdbd", minHeight: "75px" },*/}
                                        {/*        inputLabelWithFiles: { margin: '20px 3%' },*/}
                                        {/*        inputLabel: {color:"#bdbdbd"},*/}
                                        {/*        preview: {padding: "20px 3%"}*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.name}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.name}?`}
                            callback={this.deleteArtist} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(ArtistsUpdate);