import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants';
import jwtDecode from 'jwt-decode'

export const TOKEN_NAME = 'ganesh_token'
class AuthenticationService{

    executeJwtAuthenticationService(user){
        return axios.post(`${BASE_URL}/token`, user);
    }

    registerUser(user){
        return axios.post(`${BASE_URL}/register`, user);
    }

    checkUsedRegistrationFields(value, field){
        return axios.get(`http://localhost:8080/api/users/register/${field}/${value}`)
    }

    setLocalStorageJwt(token){
        localStorage.setItem(TOKEN_NAME, 'Bearer ' + token);
        this.setupAxiosInterceptors('Bearer ' + token);
    }

    getLocalStorageJwt(){
        const token = localStorage.getItem(TOKEN_NAME)
        if (token === null) return

        const decoded = jwtDecode(token);
        const d = new Date(0);
        d.setUTCSeconds(decoded.exp);

        this.setupAxiosInterceptors(token);
    }

    isUserLoggedIn() {
        let user = localStorage.getItem(TOKEN_NAME)
        if (user === null) return false
        return true
    }

    setupAxiosInterceptors(token){
        axios.interceptors.request.use(
            (config) => {
                // if(this.isUserLoggedIn()){
                    config.headers.authorization = token
                // }
                return config;
            }
        );
    }

    requestResetPassword(email){
        return axios.post(`${BASE_URL}/users/reset-password/init`, {email: email});
    }

    resetPassword(userId, verificationNumber, newPassword) {
        return axios.post(`${BASE_URL}/users/reset-password/finish`, {id: userId, resetKey: verificationNumber, newPassword: newPassword});
    }

    getUserDetails(){
        return axios.get(`${BASE_URL}/users/account`);
    }

    putUserDetails(user){
        return axios.put(`${BASE_URL}/users/account`, user);
    }

    updatePassword(oldPassword, newPassword) {
        return axios.post(`${BASE_URL}/users/changePassword`, {oldPassword, newPassword});
    }
}

export default new AuthenticationService();