import React from 'react';
import CategoryNavItem from "./CategoryNavItem";
import classes from "../../layouts/Dashboard/Dashboard.module.css";

const CategorySubNavItem = (props) => {
    return (
        <>
            <CategoryNavItem link={"#"} name={props.child.name} id={`${props.child.name}-${props.child.id}`}/>
            {props.child.children.length > 0 &&
                <div className="list-group" className="collapse" id={`${props.child.name}-${props.child.id}`}>
                    <ul className={classes.categoryComponents}>
                        {props.child.children.map(children => (
                            <li>
                                <CategorySubNavItem child={children}/>
                            </li>
                        ))}
                    </ul>
                </div>}
        </>
    );
};

export default CategorySubNavItem;
