import React, {Component, createRef} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import ErrorFormItem from "../../../../components/ErrorFormItem";
import {Formik} from "formik";
import classes from '../Artists/styles/artist.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import IArtist from "../../../../models/IArtist";
import {jsonToBlob} from "../../../../utils/Utils";
import {artistListPage, chordsViewPage, songListPage} from "../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleDropZoneUploader,
    ViewComponentExternalLink,
    SingleAutoCompleteCMSComponent
} from "../../../../components/CMSFormComponents";
import {ModalView} from "../../../../components/BootstrapModal";
import SongService from "../../../../services/SongService";
import {CLOUDINARY_CHORDS_URL} from "../../../../constants/GlobalConstants";

class SongsUpdate extends Component {

    state = {
        id: null,
        title: '',
        fileUrl: null,
        textFile: null,
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id,
        //AUTOCOMPLETE
        artistNameInputValue: '',
        artistId: -1
    }

    backButtonRef = createRef();

    dropZone = createRef();

    validationSchema = Yup.object().shape({
        title: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        artistNameId: null,
        file: null
    });

    componentDidMount() {
        if(this.state.isEditMode){
            SongService.getSong(this.props.match.params.id).then(
                item => this.setState({id: item.data.id, title: item.data.title, fileUrl: item.data.url, artistNameInputValue: item.data.artistName, artistId: item.data.artistId})
            )
        }
    }

    handleChangeStatus = ({ meta, file }, status) => {
        if(status === "done"){
            this.setState({textFile: file})
        }
        if(status === "removed"){this.setState({textFile: null})}
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        if(!this.state.textFile && !this.state.isEditMode){
            setFieldError('file', 'Upload a valid File');
            setSubmitting(false);
            return;
        }
        if(this.state.artistId === -1){
            setFieldError('artistId', 'Select a Valid Artist');
            setSubmitting(false);
            return;
        }

        let formData = new FormData();
        const jsonPayload = {title: values.title, artistId: this.state.artistId, id: this.state.isEditMode ? this.state.id : null};
        formData.append('payload', jsonToBlob(jsonPayload));
        if(this.state.textFile) formData.append('file', this.state.textFile);

        const restServiceApi = this.state.isEditMode ? SongService.putSong : SongService.postSong;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.title + " is Updated." : "A New Song: " + res.data.title + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({textFile: null, saveAndNew: false, artistNameInputValue: '', artistId: -1});
                    this.dropZone.current.files = [];
                    this.dropZone.current.forceUpdate()
                }
                else this.props.history.push(songListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        SongService.deleteSong(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.title)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>}
                        {this.state.isEditMode && <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                            <ViewComponentExternalLink url={`${CLOUDINARY_CHORDS_URL}${this.state.fileUrl}`} text={this.state.title}/>
                        </div>}
                        <Formik initialValues={{ title: this.state.title }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>}
                                        <FormField name="Title" required={true} touched={touched.title} errors={errors.title} value={values.title} setFieldValue={setFieldValue} fieldName='title' onBlurHandler={setFieldTouched}/>
                                        <SingleAutoCompleteCMSComponent required title="Artist" searchText={this.state.artistNameInputValue} setSearchText={value => this.setState({artistNameInputValue: value})} errors={errors.artistId}
                                                fieldName={"name"} valueSelectField={"id"}
                                                selectedId={this.state.artistId}
                                                valueSelectCallback={(id, value) => {this.setState({artistId: id}); this.setState({artistNameInputValue: value});}}
                                                valueClearCallback={() => {this.setState({artistId: -1}); this.setState({artistNameInputValue: ''});}}
                                                entityServiceSearchCall={ArtistService.searchForArtist}/>
                                    </div>
                                    <div className="SingleImageUploadPanel" style={errors.file ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>
                                        <label style={{float: "left", padding: "5px"}}>Thumbnail*</label>
                                        <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.file}</div>
                                        <SingleDropZoneUploader accept=".txt" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={this.state.isEditMode ? "Update Chords File" : "Drag File or Click to Browse"}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.title}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.title}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(SongsUpdate);