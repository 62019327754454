import React from 'react';

const FontAwesomeComponent = (props) => {
    return (
            <i className={"fa " + props.name} aria-hidden="true" style={props.style}></i>
        )
}
export default FontAwesomeComponent;

export const BackFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-chevron-left"/>
    );
}

export const EditFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-pencil"/>
    );
}

export const ViewFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-eye"/>
    );
}

export const RemoveFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-trash"/>
    );
}

export const RefreshFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-refresh"/>
    );
}

export const SaveFontAwesome = (props) => {
    return (
        <FontAwesomeComponent name="fa-floppy-o"/>
    );
}

export const SortIcon = () => {
    return <FontAwesomeComponent name="fa-sort"/>
}

export const PasswordIcon = () => {
    return <FontAwesomeComponent name="fa-unlock"/>
}

export const ImageIcon = () => {
    return <FontAwesomeComponent name="fa-picture-o"/>
}

export const AddIcon = () => {
    return <FontAwesomeComponent name="fa-plus-circle"/>
}

export const MinusIcon = () => {
    return <FontAwesomeComponent name="fa-minus-circle"/>
}