import React, {Component} from 'react';
import ProductImageSmallThumbnail from "../../components/Store/Product/ProductImageSmallThumbnail";
import classes from '../../components/Store/Product/productImageSmallThumbnail.module.css'
import ProductService from "../../services/Shop/HotelService";
import Spinner from "../../components/Spinnner/Spinner";
import {NepaliCurrency} from "../../components/CMSFormComponents";
import {CLOUDINARY_URL} from "../../constants/GlobalConstants";
import FontAwesomeComponent from "../../components/FontAwesomeComponent";
import innerclasses from './css/productScreen.module.css'
import ReactImageMagnify from 'react-image-magnify';

class ProductScreen extends Component {

    state = {
        entity: null,
        overrideImage: null,
        selectedImageIndex: 0
    }

    componentDidMount() {
        ProductService.getProduct(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    imageClick = (index) => {
        this.setState({overrideImage: null, selectedImageIndex: index})
    }

    onClickOptionType = (type) => {
        if(type.imageUrl){
            this.setState({overrideImage: type.imageUrl})
        }
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <>
                <div className="row">
                <div className="col-lg-6">

                    <div className="card mt-4">
                        {/*<img className="card-img-top img-fluid" src={`${CLOUDINARY_URL}${this.state.entity.productImageDTOS[0].url}`} alt={this.state.entity.name}/>*/}
                        <ReactImageMagnify {...{
                            smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: !this.state.overrideImage ? `${CLOUDINARY_URL}w_1000,h_1000,c_fill/${this.state.entity.productImageDTOS[this.state.selectedImageIndex].url}` : `${CLOUDINARY_URL}w_1000,h_1000,c_fill/${this.state.overrideImage}`
                            },
                            largeImage: {
                                src: !this.state.overrideImage ? `${CLOUDINARY_URL}w_1000,h_1000,c_fill/${this.state.entity.productImageDTOS[this.state.selectedImageIndex].url}` : `${CLOUDINARY_URL}w_1000,h_1000,c_fill/${this.state.overrideImage}`,
                                width: 1000,
                                height: 1000
                            },
                            enlargedImageStyle:{
                                objectFit: "cover",
                                background: "white"
                            },
                            enlargedImagePosition: 'over'
                        }} />
                    </div>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        {this.state.entity.productImageDTOS.map((pi, index) => (
                            <ProductImageSmallThumbnail index={index} onClick={this.imageClick} src={`${CLOUDINARY_URL}w_100,h_100,c_fill/${pi.url}`}/>
                        ))}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card mt-4">
                        <div className="card-body">
                            <h3 className="card-title">{this.state.entity.name}</h3>
                            <p>{this.state.entity.manufacturerName && <span>Brand: <a href={"#"}>{this.state.entity.manufacturerName}</a><br/></span>}
                            {/*Product Code: Product 16<br/>*/}
                            {/*Reward Points: 600<br/>*/}
                            Availability: {this.state.entity.stockStatus === "IN_STOCK" ? <span className="badge badge-primary">Available</span> : this.state.entity.stockStatus === "PRE_ORDER" ? <span className="badge badge-warning">Pre Order</span> : this.state.entity.stockStatus === "OUT_OF_STOCK" ? <span className="badge badge-warning">Out of Stock</span> : <span className="badge badge-primary">Available in few Days</span>}</p>
                            <div className="mb-3">
                                <var className="price h3" style={{fontStyle: "normal", color: "rgb(150, 91, 165)"}}>
                                    <span className="currency"><NepaliCurrency value={this.state.entity.price}/></span>
                                </var>
                            </div>
                            {/*<p>Ex Tax: $22.00</p>*/}
                            <hr/>
                            <div className="row">
                                {this.state.entity.productOptionDTOS.map(po => (
                                    <div className="col-sm-12">
                                        <dl className="dlist-inline">
                                            <dt style={{marginBottom: "-12px"}}>{po.title}:</dt>
                                            <dd>
                                                {po.types.map(type => (
                                                    <label onClick={() => this.onClickOptionType(type)} className={"form-check form-check-inline "}>
                                                        <span className={"form-check-label "}>
                                                        <input className="form-check-input" type="radio"
                                                               name={po.title} id={type.id}
                                                               value="option2"/>
                                                           <div className={innerclasses.selector}>
                                                            {type.imageUrl && <img style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                objectFit: "cover",
                                                                borderRadius: "5px"
                                                            }} src={`${CLOUDINARY_URL}w_50,h_50,c_fill/${type.imageUrl}`} alt=""/>}
                                                            <span>{type.name}</span>

                                                           </div>
                                                        </span>
                                                    </label>
                                                ))}
                                            </dd>
                                        </dl>
                                    </div>
                                ))}
                                <div className="col-sm-12">
                                    <dl className="dlist-inline">
                                        <dt>Quantity:</dt>
                                        <dd>
                                            <select className="form-control form-control-sm"
                                                    style={{width: "70px"}}>
                                                <option> 1</option>
                                                <option> 2</option>
                                                <option> 3</option>
                                            </select>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <hr/>
                            <button className="btn  btn-primary"> Buy now </button>
                            <button className="btn  btn-outline-primary ml-2"> <FontAwesomeComponent name="fa-shopping-cart"/> Add to cart </button>
                        </div>
                    </div>

                </div>
            </div>
                <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={"nav-link active " + classes.Tabs} id="description-tab" data-toggle="tab" href="#description" role="tab"
                           aria-controls="description" aria-selected="true">Description</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + classes.Tabs} id="attributes-tab" data-toggle="tab" href="#attributes" role="tab"
                           aria-controls="attributes" aria-selected="false">Attributes</a>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className={"nav-link " + classes.Tabs} id="reviews-tab" data-toggle="tab" href="#reviews" role="tab"*/}
                    {/*       aria-controls="reviews" aria-selected="false">Reviews (3)</a>*/}
                    {/*</li>*/}
                </ul>
                <div className="tab-content" id="myTabContent">
                    {/*DESCRIPTION*/}
                    <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                        <div className="card card-outline-secondary my-4">
                            <div className="card-header">
                                Product Description
                            </div>
                            <div className="card-body">
                                <span dangerouslySetInnerHTML={{__html: this.state.entity.productDescription}}></span>
                                <hr/>
                            </div>
                        </div>
                    </div>
                    {/*Attributes*/}
                    <div className="tab-pane fade" id="attributes" role="tabpanel" aria-labelledby="attributes-tab">
                        <div className="card card-outline-secondary my-4">
                            <div className="card-header">
                                Product Attributes
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                    </thead>
                                    <tbody>
                                    {this.state.entity.productAttributes.map(pa => (
                                        <tr>
                                            <th>{pa.attribute}:</th>
                                            <td>{pa.text}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/*REVIEWS*/}
                    <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                        <div className="card card-outline-secondary my-4">
                            <div className="card-header">
                                Product Reviews
                            </div>
                            <div className="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis et enim aperiam inventore,
                                    similique necessitatibus neque non! Doloribus, modi sapiente laboriosam aperiam fugiat
                                    laborum. Sequi mollitia, necessitatibus quae sint natus.</p>
                                <small className="text-muted">Posted by Anonymous on 3/1/17</small>
                                <hr/>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis et enim aperiam
                                    inventore, similique necessitatibus neque non! Doloribus, modi sapiente laboriosam
                                    aperiam fugiat laborum. Sequi mollitia, necessitatibus quae sint natus.</p>
                                <small className="text-muted">Posted by Anonymous on 3/1/17</small>
                                <hr/>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis et enim aperiam
                                    inventore, similique necessitatibus neque non! Doloribus, modi sapiente laboriosam
                                    aperiam fugiat laborum. Sequi mollitia, necessitatibus quae sint natus.</p>
                                <small className="text-muted">Posted by Anonymous on 3/1/17</small>
                                <hr/>
                                <a href="#" className="btn btn-success">Leave a Review</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductScreen;