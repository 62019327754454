import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardLayout from "../Dashboard/DashboardLayout";
import PublicRoutes from "./PublicRoutes";
import * as authActions from "../../containers/AuthContainer/auth.reducer";
import {connect} from "react-redux";
import Spinner from "../../components/Spinnner/Spinner";

class RootLayout extends Component {

    state = {
        isJWTChecked: false
    }

    successToaster = (message) => {
        toast.success(message);
    }

    componentDidMount() {
        this.props.authCheckState((bool) => this.setState({isJWTChecked: bool}));
    }

    render() {
        if(!this.state.isJWTChecked){
            return <Spinner/>
        }
        return (
            <>
                <ToastContainer/>
                <Switch>
                    <Route path="/dashboard" component={DashboardLayout}/>
                    <Route path="/" component={PublicRoutes}/>
                    <Route path="*" component={PublicRoutes}/>
                </Switch>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        userRole: state.auth.userRole
    };
}

const mapDispatchToProps = dispatch => {
    return {
        authCheckState: (isJWTChecked, router) => dispatch(authActions.authCheckState(isJWTChecked, router))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
