import React, {Component} from 'react';
import AuthenticationService from "../../services/AuthenticationService";
import ArtistImage from "../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import {songListPage} from "../../constants/routesConstants";
import FontAwesomeComponent, {
    BackFontAwesome,
    EditFontAwesome, ImageIcon,
    PasswordIcon
} from "../../components/FontAwesomeComponent";
import Spinner from "../../components/Spinnner/Spinner";
import logo from '../../assets/icon.png'
import classes from './entities/Artists/styles/artist.module.css'

class CmsSettings extends Component {
    state ={
        id: null,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        imageUrl: null,
        error: null,
        roles: null
    }

    componentDidMount() {
        AuthenticationService.getUserDetails().then(
            res => {
                this.setState({
                    id: res.data.id,
                    username: res.data.username,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    phoneNumber: res.data.phoneNumber,
                    imageUrl: res.data.imageUrl,
                    roles: res.data.roles
                })
            }
        ).catch(() => this.setState({error: "Something Went Wrong"}))
    }

    render() {
        if(!this.state.username){
            return <Spinner/>
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", width: "75%", minWidth: "600px", margin: "0 auto"}}>
                    <div className="card-body">
                        {this.state.error && <div className="alert alert-danger" role="alert">{this.state.error}</div>}
                        <div style={{marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            {this.state.imageUrl ? <ArtistImage photoUrl={this.state.imageUrl} size={5} name={this.state.username}/>
                            : <img src={logo} style={{width: "5em", boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)", backgroundColor: "#965ba5", padding: "4px", borderRadius: "5px"}}/>}
                        </div>
                        <div style={{display:"flex"}}>
                            <Link to={`${this.props.match.url}/edit`} style={{display: "flex", margin: "0 auto", textDecoration: "none"}}><button className="btn btn-outline-success">Update Profile</button></Link>
                            <Link to={`${this.props.match.url}/changePassword`} style={{display: "flex", margin: "0 auto", textDecoration: "none"}}><button className="btn btn-outline-warning"><PasswordIcon/> Change Password</button></Link>
                        </div>
                        <table className="table" style={{marginTop: "10px"}}>
                            <tbody style={{textAlign: "center"}}>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Username</td>
                                <th>{this.state.username}</th>
                            </tr>
                            <tr>
                                <td scope="row">First Name</td>
                                <th>{this.state.firstName}</th>
                            </tr>
                            <tr>
                                <td scope="row">Last Name</td>
                                <th>{this.state.lastName}</th>
                            </tr>
                            <tr>
                                <td scope="row">Email</td>
                                <th>{this.state.email}</th>
                            </tr>
                            <tr>
                                <td scope="row">Phone Number</td>
                                <th>{this.state.phoneNumber}</th>
                            </tr>
                            <tr>
                                <td scope="row">Roles</td>
                                <th>{this.state.roles}</th>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                            {/*<Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CmsSettings;