import React, {Component} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../components/ArtistItem/ArtistImage";
import Pagination from "../../../../components/DashboardComponents/Pagination";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import FontAwesomeComponent, {RemoveFontAwesome, SortIcon} from "../../../../components/FontAwesomeComponent";
import classes from '../Artists/styles/artist.module.css'
import queryString from 'query-string';
import {Link, NavLink} from "react-router-dom";
import {ModalView} from "../../../../components/BootstrapModal";
import * as actions from '../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from 'react-redux'
import SongService from "../../../../services/SongService";
import {ResetCMSButton, SearchCMSComponent, SubmitCMSButton} from "../../../../components/CMSFormComponents";

class Songs extends Component{

    state = {
        entities: [],
        page: 0,
        sortField: 'id',
        sortOrder: 'asc',
        totalPages: 0,
        noEntity: false,
        deleteEntity: {id: -1, deleteEntityTitle: ''},
        // TITLE
        titleInputValue: '',
        title: '',
        // ARTISTNAME
        artistNameInputValue: '',
        artistName: ''
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        if(JSON.stringify(params) !== '{}'){
            let tempState = {};
            if(params.title){
                tempState.title = params.title;
            }
            if(params.artistName){
                tempState.artistName = params.artistName;
            }
            if(params.sort){
                const values = params.sort.split(",");
                tempState.sortField = values[0];
                tempState.sortOrder = values[1];
            }
            if(params.page){
                tempState.page = +params.page;
            }
            this.setState(tempState);
            if(tempState.page === 0 ) this.getEntities()
        } else {
            this.getEntities();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.page !== this.state.page || prevState.sortField !== this.state.sortField || prevState.sortOrder !== this.state.sortOrder || prevState.title !== this.state.title || prevState.artistName !== this.state.artistName){
            this.getEntities();
            let url = `${this.props.location.pathname}?page=${this.state.page}&sort=${this.state.sortField},${this.state.sortOrder}`;
            url += this.state.title && this.state.title !== '' ? `&title=${this.state.title}`: '';
            url += this.state.artistName && this.state.artistName !== '' ? `&artistName=${this.state.artistName}`: '';
            this.props.history.push(url);
        }
    }

    getEntities = () => {
        this.setState({noEntity: false})
        SongService.getPagedSongs({page: this.state.page, sortField: this.state.sortField, sortOrder: this.state.sortOrder, title: this.state.title, artistName: this.state.artistName}).then(
            res => {
                this.setState({entities: [...res.data.content], totalPages: res.data.totalPages, noEntity: res.data.content.length === 0 ? true : false});
            }
        );
    }

    handlePageClick = ({selected}) => {
        this.setState({page: selected});
    }

    onSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.setState({page: 0, title: this.state.titleInputValue, artistName: this.state.artistNameInputValue});
    }

    onResetSearchHandler = (event) => {
        if(event) event.preventDefault();
        this.setState({page: 0, sortField: 'id', sortOrder: 'asc', titleInputValue: '', title: '', artistNameInputValue: '', artistName: ''});
    }

    sortByHandler = (field) => {
        if(this.state.sortField === field){
            this.setState({sortOrder: this.state.sortOrder === 'asc' ? 'desc' : 'asc', page : 0});
        } else {
            this.setState({sortField: field, sortOrder: 'asc', page: 0});
        }
    }

    gotoAddNewEntityPage = () => {
        this.props.history.push("/dashboard/songs/new");
    }

    deleteEntity = (id) => {
        SongService.deleteSong(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.title)
                this.getEntities();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    TopToolbar = () => {
        return (<>
                <button onClick={this.gotoAddNewEntityPage} className="btn btn-primary float-right"><FontAwesomeComponent name="fa-plus-circle"/> Add New Song</button>
                <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", clear: "both"}}>
                    <SearchCMSComponent title="Song" searchText={this.state.titleInputValue} fieldName={"title"} setSearchText={(value) => this.setState({titleInputValue: value})} entityServiceSearchCall={SongService.searchForSongs}/>
                    <SearchCMSComponent title="Artist" searchText={this.state.artistNameInputValue} fieldName={"name"} setSearchText={(value) => this.setState({artistNameInputValue: value})} entityServiceSearchCall={ArtistService.searchForArtist}/>
                </div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                    <SubmitCMSButton onSearch={this.onSearchHandler}/>
                    <ResetCMSButton onResetSearch={this.onResetSearchHandler}/>
                </div>
            </>
        );
    }

    render() {

        if(this.state.entities.length === 0 && this.state.noEntity){
            return (
                <>
                    {this.TopToolbar()}
                    <div className="card" style={{width: "80%", textAlign: "center", margin: "0 auto", maxWidth: "600px"}}>
                        <div className="card-body">
                            <h5 className="card-title">No Such Item Found</h5>
                            <button onClick={this.onResetSearchHandler} className="btn btn-primary">Reset</button>
                        </div>
                    </div>
                </>
            )
        }

        if(this.state.entities.length == 0){
            return (
                <Spinner/>
            );
        }

        return (
            <>
                {this.TopToolbar()}
                <Pagination handlePageClick={this.handlePageClick} totalPages={this.state.totalPages} currentPage={this.state.page}/>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th onClick={this.sortByHandler.bind(this, 'id')} className={classes.clickableTableHeader} style={this.state.sortField === 'id' ? {backgroundColor: "#ccc"}: null} scope="col"># <SortIcon/></th>
                        <th onClick={this.sortByHandler.bind(this, 'title')} className={classes.clickableTableHeader} style={this.state.sortField === 'title' ? {backgroundColor: "#ccc"}: null} scope="col">Title <SortIcon/></th>
                        <th scope="col"></th>
                        <th onClick={this.sortByHandler.bind(this, 'artist.name')} className={classes.clickableTableHeader} style={this.state.sortField === 'artist.name' ? {backgroundColor: "#ccc"}: null} scope="col">Artist <SortIcon/></th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.entities.map(entity => {
                            return (
                                <tr key={entity.id}>
                                    <th>{entity.id}</th>
                                    <td>{entity.title}</td>
                                    <td><ArtistImage photoUrl={entity.artistPhotoUrl} size={2.5} name={entity.artistName}/></td>
                                    <td scope="col">{entity.artistName}</td>
                                    <td style={{display: "flex", justifyContent: "space-around"}}>
                                        <Link to={`${this.props.match.url}/${entity.id}`}><button className="btn btn-primary">Views</button></Link>
                                        <Link to={`${this.props.match.url}/${entity.id}/edit`}><button className="btn btn-warning">Edit</button></Link>
                                        <button onClick={() => this.setState({deleteEntity: {id: entity.id, title: entity.title}})} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
                <ModalView title={`Delete ${this.state.deleteEntity.title}`} id={this.state.deleteEntity.id} body={`Are you sure you want to remove [${this.state.deleteEntity.id}] ${this.state.deleteEntity.title}?`} callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>} buttonTheme="btn-danger"/>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(Songs);

