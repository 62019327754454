import React, {Component, createRef} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import {
    AddIcon,
    BackFontAwesome, EditFontAwesome,
    RemoveFontAwesome,
    SaveFontAwesome
} from "../../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import classesTab from '../../../../../components/Store/Product/productImageSmallThumbnail.module.css'
import {Formik} from "formik";
import 'react-dropzone-uploader/dist/styles.css'
import {jsonToBlob} from "../../../../../utils/Utils";
import {
    artistListPage,
    categoriesListPage, ordersListPage, hotelsListPage, usersListPage,
} from "../../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleAutoCompleteCMSComponent,
    FormSelectField,
    SortOrderField,
    FormFieldDateTimePicker,
    CategoryMultiAutoCompleteCMSComponent,
    ImageUploaderCustom,
    ImagePreviewerCustom,
    TopAttractionsCMSComponent,
    RelatedMultiAutoCompleteCMSComponent, ViewComponentExternalLink, NepaliCurrency, SimpleFormField
} from "../../../../../components/CMSFormComponents";
import {ModalView} from "../../../../../components/BootstrapModal";
import CategoryService from "../../../../../services/Shop/CategoryService";
import RichTextEditor from "../../../../../components/RichTextEditor";
import ManufacturerService from "../../../../../services/Shop/FacilityService";
import HotelPhoneNumber from "../../../../../components/Store/HotelPhoneNumber";
import ProductService from "../../../../../services/Shop/HotelService";
import ProductOption from "../../../../../components/Store/ProductOption";
import {CLOUDINARY_URL} from "../../../../../constants/GlobalConstants";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DnDImageList from "../../../../../components/Store/DnDImageList";
import classes from "../../Artists/styles/artist.module.css";
import ErrorFormItem from "../../../../../components/ErrorFormItem";
import TagService from "../../../../../services/Shop/TopAttractionsService";
import OrderService from "../../../../../services/Shop/OrderService";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import {OrderItemUpdateModal} from "../../../../../components/OrderItemUpdateModal";
import {OrderItemDeleteModal} from "../../../../../components/OrderItemDeleteModal";
import {OrderItemAddModal} from "../../../../../components/OrderItemAddModal";
import UserAdminService from "../../../../../services/UserAdminService";

class UsersUpdate extends Component {

    state = {
        id: null,
        firstName: '',
        lastName: '',
        username: '',
        phoneNumber: '',
        email: '',
        entity: null,
        status: null,
        isHotelManager: true,
        isEditMode: !!this.props.match.params.id,
    }

    backButtonRef = createRef();

    validationSchema = Yup.object().shape({
        firstName: Yup.string().min(4, "Too Short").max(255, "Too Long").required("Required"),
        lastName: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required"),
        username: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required"),
        password: Yup.string().min(4, "Too Short").max(50, "Too Long"),
        email: Yup.string().min(4, "Too Short").max(100, "Too Long").required("Required").email("Invalid email"),
        phoneNumber: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required")
    });

    componentDidMount() {
        if(this.state.isEditMode){
            this.getEntities()
        }
    }

    getEntities(){
        UserAdminService.getUser(this.props.match.params.id).then(
            item => {
                this.setState({
                    id: item.data.id,
                    firstName: item.data.firstName,
                    lastName: item.data.lastName,
                    username: item.data.username,
                    email: item.data.email,
                    phoneNumber: item.data.phoneNumber,
                    entity: item.data,
                    status: item.data.enabled ? "Enabled" : "Disabled"
                })
                if(item.data.roles.includes("ROLE_HOTEL_MANAGER")) this.setState({isHotelManager: true})
                else this.setState({isHotelManager: false})
            }
        )
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);

        const jsonPayload = {id: this.state.isEditMode ? this.state.entity.id : null, username: values.username, firstName: values.firstName, lastName: values.lastName, email: values.email, password: values.password, phoneNumber: values.phoneNumber, enabled: this.state.status === "Disabled" ? false : true};
        if(this.state.isHotelManager){
            jsonPayload.roles = ["ROLE_HOTEL_MANAGER"]
        }

        const restServiceApi = this.state.isEditMode ? UserAdminService.putUser : UserAdminService.postUser;
        restServiceApi(jsonPayload)
            .then(res => {
                this.props.sucessToast("User is Updated!")
                setSubmitting(false)
                this.props.history.push(usersListPage)
            })
            .catch(err => {
                console.log(err.response)
                if(err.response.data.httpStatus === "CONFLICT"){
                    toast.error("Username Already Used!")
                } else {
                    toast.error("Something went wrong")
                }
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        UserAdminService.deleteUser(id).then(
            (res) => {
                this.props.sucessToast("Successfully User: " + res.data.id)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove User</button>}
                        <Formik initialValues={{  firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, phoneNumber: this.state.phoneNumber, username: this.state.username, password: ""  }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="orderDetails" role="tabpanel" aria-labelledby="orderDetails-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    User Details
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.isEditMode && <FormFieldDisabled name="User ID" value={this.state.id}/>}
                                                        {
                                                            this.state.isEditMode ?
                                                                <FormFieldDisabled name="Username" value={this.state.username}/>:
                                                                <FormField name="Username" required={true} touched={touched.username} errors={errors.username} value={values.username} setFieldValue={setFieldValue} fieldName='username' onBlurHandler={setFieldTouched}/>
                                                        }
                                                        <FormField name="Password" touched={touched.password} errors={errors.password} value={values.password} setFieldValue={setFieldValue} fieldName='password' onBlurHandler={setFieldTouched}/>
                                                        <FormField name="First Name" required={true} touched={touched.firstName} errors={errors.firstName} value={values.firstName} setFieldValue={setFieldValue} fieldName='firstName' onBlurHandler={setFieldTouched}/>
                                                        <FormField name="Last Name" required={true} touched={touched.lastName} errors={errors.lastName} value={values.lastName} setFieldValue={setFieldValue} fieldName='lastName' onBlurHandler={setFieldTouched}/>
                                                        <FormField name="Email" required={true} touched={touched.email} errors={errors.email} value={values.email} setFieldValue={setFieldValue} fieldName='email' onBlurHandler={setFieldTouched}/>
                                                        <FormField name="Phone Number" required={true} touched={touched.phoneNumber} errors={errors.phoneNumber} value={values.phoneNumber} setFieldValue={setFieldValue} fieldName='phoneNumber' onBlurHandler={setFieldTouched}/>
                                                        <FormSelectField label="Status"
                                                               value={this.state.status}
                                                               onChange={data => this.setState({status: data})}
                                                               list={['Enabled', 'Disabled']}/>
                                                    </div>
                                                    <div className="col-md-6" style={{marginTop: "10px"}}>
                                                        <label style={{float: "left"}}>Hotel Manager?</label>
                                                        <input type="checkbox" style={{height: "30px", width: "30px", marginLeft: "20px"}} checked={this.state.isHotelManager} onChange={(e) => this.setState({isHotelManager : !this.state.isHotelManager})} className="form-check-input"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.name}`} id={this.state.id}
                            body={`Are you sure you want to remove User ID: [${this.state.id}], of ${this.state.entity.username}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(UsersUpdate);