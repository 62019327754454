import React, {Component, createRef} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import {BackFontAwesome, RemoveFontAwesome, SaveFontAwesome} from "../../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import classesTab from '../../../../../components/Store/Product/productImageSmallThumbnail.module.css'
import {Formik} from "formik";
import classes from '../../Artists/styles/artist.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {jsonToBlob} from "../../../../../utils/Utils";
import {
    artistListPage,
    categoriesListPage,
    chordsViewPage,
    songListPage
} from "../../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleDropZoneUploader,
    ViewComponentExternalLink,
    SingleAutoCompleteCMSComponent,
    CategoryChainAutoCompleteCMSComponent,
    FormSelectField,
    FormRangeField,
    SortOrderField
} from "../../../../../components/CMSFormComponents";
import {ModalView} from "../../../../../components/BootstrapModal";
import SongService from "../../../../../services/SongService";
import {CLOUDINARY_CHORDS_URL} from "../../../../../constants/GlobalConstants";
import CategoryService from "../../../../../services/Shop/CategoryService";
import RichTextEditor from "../../../../../components/RichTextEditor";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";

class CategoryUpdate extends Component {

    state = {
        id: null,
        title: '',
        imageUrl: null,
        imageFile: null,
        saveAndNew: false,
        isEditMode: !!this.props.match.params.id,
        disabled: false,
        sortOrder: 100,
        //AUTOCOMPLETE
        parentInputValue: '',
        parentId: -1,
        // DESCRIPTION
        description: ''
    }

    backButtonRef = createRef();

    dropZone = createRef();

    richText = createRef();

    validationSchema = Yup.object().shape({
        title: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        file: null
    });

    componentDidMount() {
        if(this.state.isEditMode){
            CategoryService.getCategory(this.props.match.params.id).then(
                item => this.setState({id: item.data.id, title: item.data.title, imageUrl: item.data.imageUrl, parentInputValue: item.data.parentTitle, parentId: item.data.parentId, disabled: item.data.disabled, sortOrder: item.data.sortOrder, description: item.data.categoryDescription})
            )
        }
    }

    handleChangeStatus = ({ meta, file }, status) => {
        if(status === "done"){
            this.setState({imageFile: file})
        }
        if(status === "removed"){this.setState({imageFile: null})}
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        if(!this.state.imageFile && !this.state.isEditMode){
            setFieldError('file', 'Upload a valid File');
            setSubmitting(false);
            return;
        }
        if(this.state.parentId === -1){
            setFieldError('parentId', 'Select a Valid Parent Category');
            toast.error("Select a Valid Parent Category");
            setSubmitting(false);
            return;
        }

        let formData = new FormData();
        const jsonPayload = {title: values.title, parentId: this.state.parentId, sortOrder: this.state.sortOrder, disabled: this.state.disabled, categoryDescription: this.state.description, id: this.state.isEditMode ? this.state.id : null};
        formData.append('payload', jsonToBlob(jsonPayload));
        if(this.state.imageFile) formData.append('file', this.state.imageFile);

        const restServiceApi = this.state.isEditMode ? CategoryService.putCategory : CategoryService.postCategory;
        restServiceApi(formData)
            .then(res => {
                this.props.sucessToast(this.state.isEditMode ? res.data.title + " is Updated." : "A New Category: " + res.data.title + " is Created.")
                setSubmitting(false)
                if(this.state.saveAndNew) {
                    resetForm();
                    this.setState({ saveAndNew: false, parentInputValue: '', parentId: -1, disabled: false, sortOrder: 100, description: ''});
                    this.richText.current.reset();
                    this.setState({imageFile: null, saveAndNew: false, parentInputValue: '', parentId: -1});
                    this.dropZone.current.files = [];
                    this.dropZone.current.forceUpdate()
                }
                else this.props.history.push(categoriesListPage)
            })
            .catch(err => {
                if(err.response && err.response.data.message.startsWith("Maximum upload size exceeded")) {
                    setFieldError('file', 'File Size Too Big');
                    toast.error("File Size Too Big");
                }
                else toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        CategoryService.deleteCategory(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted " + res.data.title)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove</button>}
                        <Formik initialValues={{ title: this.state.title }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className={"nav-link active " + classesTab.Tabs} id="general-tab" data-toggle="tab" href="#general" role="tab"
                                               aria-controls="general" aria-selected="true">General</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + classesTab.Tabs} id="data-tab" data-toggle="tab" href="#data" role="tab"
                                               aria-controls="data" aria-selected="false">Data</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {/*GENERAL*/}
                                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    General Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.isEditMode && <FormFieldDisabled name="ID" value={this.state.id}/>}
                                                        <FormField name="Title" required={true} touched={touched.title} errors={errors.title} value={values.title} setFieldValue={setFieldValue} fieldName='title' onBlurHandler={setFieldTouched}/>
                                                        <RichTextEditor value={this.state.description} ref={this.richText} onChange={value => this.setState({description: value})} title="Description" placeholder="Description..."/>
                                                    </div>
                                                    {this.state.isEditMode && <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                                                        <ArtistImage photoUrl={this.state.imageUrl} size={5} name={this.state.title}/>
                                                    </div>}
                                                    <div className="SingleImageUploadPanel" style={errors.file ? {backgroundColor: "#f8d7da"} : {backgroundColor: "initial"}}>
                                                        <label style={{float: "left", padding: "5px"}}>Thumbnail*</label>
                                                        <div style={{color: "#721c24", fontSize: "0.7rem", float: "left"}}>{errors.file}</div>
                                                        <SingleDropZoneUploader accept="image/*" refObject={this.dropZone} onChangeStatus={this.handleChangeStatus} inputContent={this.state.isEditMode ? "Update Thumbnail" : "Drag Image or Click to Browse"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Data*/}
                                        <div className="tab-pane fade" id="data" role="tabpanel" aria-labelledby="data-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Data Properties
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <CategoryChainAutoCompleteCMSComponent required title="Parent Category" searchText={this.state.parentInputValue} setSearchText={value => this.setState({parentInputValue: value})} errors={errors.parentId}
                                                           fieldName={"title"} valueSelectField={"id"}
                                                           selectedId={this.state.parentId}
                                                           valueSelectCallback={(id, value) => {this.setState({parentId: id}); this.setState({parentInputValue: value});}}
                                                           valueClearCallback={() => {this.setState({parentId: -1}); this.setState({parentInputValue: ''});}}
                                                           entityServiceSearchCall={CategoryService.searchForCategories}/>
                                                        <FormSelectField label="Status" value={this.state.disabled ? "Disabled" : "Enabled"} onChange={data => this.setState(data === "Disabled" ? { disabled: true} : { disabled: false})} list={["Enabled", "Disabled"]}/>
                                                        <SortOrderField value={this.state.sortOrder} onChange={value => this.setState({sortOrder: value})}/>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.title}`} id={this.state.id}
                            body={`Are you sure you want to remove [${this.state.id}] ${this.state.title}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(CategoryUpdate);