import React, {useRef} from 'react';

export const ModalView = (props) => {

    const cancelButtonRef = useRef(null);

    const onConfirm = () => {
        props.callback(props.id);
        cancelButtonRef.current.click();
    }

    return (
        <div className="modal fade" id="customModal" tabIndex="-1" role="dialog" aria-labelledby="customModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="customModalLabel">{props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.body}
                    </div>
                    <div className="modal-footer">
                        <button type="button" ref={cancelButtonRef} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={() => onConfirm()} className={`btn ${props.buttonTheme}`}>{props.confirmLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}