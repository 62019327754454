import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants'

class SongService {

    getAllSongs(){
        return axios.get(BASE_URL + "/songs/all");
    }

    getSong(id){
        return axios.get(BASE_URL + "/songs/" + id)
    }

    getPagedSongs(options){
        let url = BASE_URL + "/songs?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.title && options.title !== '' ? url + "&title=" + options.title : url;
        url = options.artistName && options.artistName !== '' ? url + "&artistName=" + options.artistName : url;
        return axios.get(url);
    }

    searchForSongs(title, page, size){
        let url = BASE_URL + "/songs?title=" + title + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postSong(formData){
        return axios.post(BASE_URL + "/songs", formData);
    }

    putSong(song){
        return axios.put(BASE_URL + "/songs", song);
    }

    deleteSong(songId){
        return axios.delete(BASE_URL + `/songs/${songId}`);
    }

}

export default new SongService();