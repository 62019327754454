import axios from 'axios';
import {BASE_URL} from '../../constants/GlobalConstants'

class OrderService {

    getOrder(id){
        return axios.get(BASE_URL + "/orders/" + id)
    }

    getOrderStatuses(){
        return axios.get(BASE_URL + "/ordersStatusList");
    }

    getPagedOrders(options){
        let url = BASE_URL + "/orders?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.username && options.username !== '' ? url + "&username=" + options.username : url;
        url = options.town && options.town !== '' ? url + "&town=" + options.town : url;
        url += options.startDate ? `&startDate=${options.startDate}` : '';
        url += options.endDate ? `&endDate=${options.endDate}` : '';
        url += options.orderStatus ? `&orderStatus=${options.orderStatus}` : '';
        return axios.get(url);
    }

    checkUpdatedOrderItemPrice(itemId, quantity, productOptions){
        return axios.post(BASE_URL + "/orders/checkUpdatedOrderItemPrice", {id: itemId, quantity: quantity, orderItemOptionTypes: productOptions});
    }


    orderItemUpdate(itemId, quantity, productOptions) {
        return axios.put(BASE_URL + "/orders/orderItems", {id: itemId, quantity: quantity, orderItemOptionTypes: productOptions});
    }

    addOrderItemToOrder(orderId, product, quantity, orderItemOptionTypeDTOS) {
        return axios.post(BASE_URL + "/orders/"+ orderId + "/addItem", {product: {id: product.id}, quantity: quantity, orderItemOptionTypes: orderItemOptionTypeDTOS});
    }

    getPublicPagedOrders(options){
        let url = BASE_URL + "/orders/public?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.name && options.name !== '' ? url + "&name=" + options.name : url;
        url = options.category && options.category !== '' ? url + "&category=" + options.category : url;
        return axios.get(url);
    }

    searchForOrders(name, page, size){
        let url = BASE_URL + "/orders?name=" + name + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postOrder(formData){
        return axios.post(BASE_URL + "/orders", formData);
    }

    putOrder(orders){
        return axios.put(BASE_URL + "/orders", orders);
    }

    deleteOrder(ordersId){
        return axios.delete(BASE_URL + `/orders/${ordersId}`);
    }

    deleteOrderItem(ordersItemId){
        return axios.delete(BASE_URL + `/orders/orderItems/${ordersItemId}`);
    }


}

export default new OrderService();