import React, {useEffect, useState} from 'react';
import classes from "./Pagination.module.css";
import ReactPaginate from "react-paginate";

export default (props) => {

    return (
        <>
            {/*<button className="btn btn-primary" onClick={changePage}>CLICK</button>*/}
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={props.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                disabledClassName={classes.disabled}
                onPageChange={props.handlePageClick.bind(this)}
                containerClassName={classes.pagination}
                subContainerClassName={'pages pagination'}
                activeClassName={ classes.active}
                forcePage={props.currentPage}
            />
        </>
    )
}