import axios from 'axios';
import {BASE_URL} from '../../constants/GlobalConstants'

class CategoryService {

    getCategory(id){
        return axios.get(BASE_URL + "/categories/" + id)
    }

    getPagedCategories(options){
        let url = BASE_URL + "/categories?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.title && options.title !== '' ? url + "&title=" + options.title : url;
        url = options.parentTitle && options.parentTitle !== '' ? url + "&parentTitle=" + options.parentTitle : url;
        return axios.get(url);
    }

    searchForCategories(title, page, size){
        let url = BASE_URL + "/categories?title=" + title + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postCategory(formData){
        return axios.post(BASE_URL + "/categories", formData);
    }

    putCategory(categories){
        return axios.put(BASE_URL + "/categories", categories);
    }

    deleteCategory(categoriesId){
        return axios.delete(BASE_URL + `/categories/${categoriesId}`);
    }

    getCategoryTree(){
        return axios.get(BASE_URL + "/categories/tree")
    }

}

export default new CategoryService();