import React, { useRef, useEffect } from 'react'

const ARTIST_TAG = "<artist>"
const SongRender = (props) => {
  const webRef = useRef(null)
  let { scrollSpeed = 0 } = props
  useEffect(() => {
    let run
    if (scrollSpeed <= 0) {
      run = `
      if(window.intervalId) {
        clearInterval(window.intervalId);
      }
      true;
      `
    } else {
      run = `
      function pageScroll(){
        window.scrollBy(0,1);
      }
      if(window.intervalId) {
        clearInterval(window.intervalId);
      }
      window.intervalId = setInterval(pageScroll, ${(1 - scrollSpeed) * 200 + 10});
      true;
      `
    }
    // if (webRef.current) {
    //   webRef.current.injectJavaScript(run)
    // }
    var runnerFunction = new Function (run);
    runnerFunction();
  }, [props.scrollSpeed])


  function onReceiveMessage(event) {
    let { data } = event.nativeEvent
    if (props.onPressArtist && data.includes(ARTIST_TAG)) {
      props.onPressArtist()
    } else if (props.onPressChord) {
      props.onPressChord(event.nativeEvent.data)
    }
  }

  return (
    <html>
    <head><meta meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0, minimum-scale=0.5"/></head>
    <body className="body-container"><p dangerouslySetInnerHTML={{__html: props.chordProContent}}></p></body>
    <style>{styles}</style>
    </html>
  )
}
const renderHtml = () => {
  // return `<html>
  //   <head><meta meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0, minimum-scale=0.5"></head>
  //   <body>${body}</body>
  //   <style>${styles}</style>
  // </html>`
}
const onClickChordPostMessage = `
(
  function() {
    function onClickChord (chord) {
      return function () {
        window.ReactNativeWebView.postMessage(chord)
      }
    }
    var anchors = document.getElementsByClassName('chord');
    for(var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        var chord = anchor.innerText || anchor.textContent;
        anchor.onclick = onClickChord(chord)
    }
    var artistNodes = document.getElementsByClassName('artist');
    for(var i = 0; i < artistNodes.length; i++) {
        var anchor = artistNodes[i];
        var artist = anchor.innerText || anchor.textContent;
        anchor.onclick = onClickChord("${ARTIST_TAG}" + artist)
    }
})();

true;
`
const styles = `
.body-container {
  font-family: monospace;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          padding-bottom: 100px;
          padding-left: 5px;
          margin: 0 auto;
          width: 90%;
          max-width: 600px;
          font-size: 1.5rem;
          padding-top: 55px;
}
.title {
  font-size: 20px
}
.artist {
  font-weight: bold;
  color: red;
  cursor: pointer;
}
.chord:hover {
  color: #2980b9;
}
// .line {
//   margin: 0;
//   position: relative;
//   margin-bottom: 0px;
//   font-size: 14px;
//   font-family: monospace;
//   white-space: pre-wrap;
//   margin-right: 10px;
// }
.line-size-14 { font-size: 14px; }
.line-size-15 { font-size: 15px; }
.line-size-16 { font-size: 16px; }
.line-size-17 { font-size: 17px; }
.line-size-18 { font-size: 18px; }
.line-size-19 { font-size: 19px; }
.line-size-20 { font-size: 20px; }
.line-size-21 { font-size: 21px; }
.line-size-22 { font-size: 22px; }
.line-size-23 { font-size: 23px; }
.line-size-24 { font-size: 24px; }
.chord {
  color: red;
  position: relative;
  display: inline-block;
  padding-top: 20px;
  width: 0px;
  top: -22px;
  cursor: pointer;
  font-weight: 700;
}
.chord-inline {
  position: inherit;
  display: inline-block;
  padding-top: 0px;
  width: auto;
  top: auto;
}
.chord-size-14 { top: -14px; }
.chord-size-15 { top: -15px; }
.chord-size-16 { top: -16px; }
.chord-size-17 { top: -17px; }
.chord-size-18 { top: -18px; }
.chord-size-19 { top: -19px; }
.chord-size-20 { top: -20px; }
.chord-size-21 { top: -21px; }
.chord-size-22 { top: -22px; }
.chord-size-23 { top: -23px; }
.chord-size-24 { top: -24px; }
.chord:active {
  color: blue;
}
.word {
  display: inline-block;
}
.tab {
}
.tab-line {
  max-width: 4px;
  display: inline-block;
  word-wrap: break-word;
  padding-bottom: 20px;
}
`
export default SongRender