import React, {Component, createRef} from 'react'
import Spinner from "../../../../../components/Spinnner/Spinner";
import {
    AddIcon,
    BackFontAwesome, EditFontAwesome,
    RemoveFontAwesome,
    SaveFontAwesome
} from "../../../../../components/FontAwesomeComponent";
import * as Yup from "yup";
import classesTab from '../../../../../components/Store/Product/productImageSmallThumbnail.module.css'
import {Formik} from "formik";
import 'react-dropzone-uploader/dist/styles.css'
import {jsonToBlob} from "../../../../../utils/Utils";
import {
    artistListPage,
    categoriesListPage, ordersListPage, hotelsListPage,
} from "../../../../../constants/routesConstants";
import {toast} from "react-toastify";
import * as actions from '../../../../../layouts/RootLayout/rootLayout.reducer'
import {connect} from "react-redux";
import {
    FormFieldDisabled,
    FormField,
    SingleAutoCompleteCMSComponent,
    FormSelectField,
    SortOrderField,
    FormFieldDateTimePicker,
    CategoryMultiAutoCompleteCMSComponent,
    ImageUploaderCustom,
    ImagePreviewerCustom,
    TopAttractionsCMSComponent,
    RelatedMultiAutoCompleteCMSComponent, ViewComponentExternalLink, NepaliCurrency, SimpleFormField
} from "../../../../../components/CMSFormComponents";
import {ModalView} from "../../../../../components/BootstrapModal";
import CategoryService from "../../../../../services/Shop/CategoryService";
import RichTextEditor from "../../../../../components/RichTextEditor";
import ManufacturerService from "../../../../../services/Shop/FacilityService";
import HotelPhoneNumber from "../../../../../components/Store/HotelPhoneNumber";
import ProductService from "../../../../../services/Shop/HotelService";
import ProductOption from "../../../../../components/Store/ProductOption";
import {CLOUDINARY_URL} from "../../../../../constants/GlobalConstants";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DnDImageList from "../../../../../components/Store/DnDImageList";
import classes from "../../Artists/styles/artist.module.css";
import ErrorFormItem from "../../../../../components/ErrorFormItem";
import TagService from "../../../../../services/Shop/TopAttractionsService";
import OrderService from "../../../../../services/Shop/OrderService";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import {OrderItemUpdateModal} from "../../../../../components/OrderItemUpdateModal";
import {OrderItemDeleteModal} from "../../../../../components/OrderItemDeleteModal";
import {OrderItemAddModal} from "../../../../../components/OrderItemAddModal";

class OrdersUpdate extends Component {

    state = {
        id: null,
        entity: null,
        orderStatus: null,
        orderStatusList: [],
        orderItems: [],
        isEditMode: !!this.props.match.params.id,
        deleteOrderItem: {id: -1, name:''},
        // SHIPPING ADDRESS
        streetAddress: '',
        apartment: '',
        town: '',
        phone: ''
    }

    backButtonRef = createRef();
    richText = createRef();

    generalTab = createRef();
    linksTab = createRef();
    attributesTab = createRef();
    optionsTab = createRef();
    imagesTab = createRef();

    validationSchema = Yup.object().shape({
        streetAddress: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        apartment: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        town: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        state: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        phone: Yup.number().min(1, "Too Short").required("Required"),
    });

    componentDidMount() {
        this.getEntities()
    }

    getEntities(){
        OrderService.getOrder(this.props.match.params.id).then(
            item => this.setState({id: item.data.id, entity: item.data, orderStatus: item.data.orderStatus, shippingAddress: item.data.shippingAddress, orderItems: item.data.orderItems, streetAddress: item.data.shippingAddressDTO.streetAddress, apartment: item.data.shippingAddressDTO.apartment, town: item.data.shippingAddressDTO.town, state: item.data.shippingAddressDTO.state, phone: item.data.shippingAddressDTO.phone})
        )
        OrderService.getOrderStatuses().then(res => this.setState({orderStatusList: ["Select Status", ...res.data]}));
    }

    resetOrderItem = () => {
        this.setState({updateEntity: null})
    }

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);

        const jsonPayload = {id: this.state.entity.id, orderStatus: this.state.orderStatus, shippingAddressDTO:{ streetAddress: values.streetAddress, apartment: values.apartment, town: values.town, state: values.state, phone: values.phone}};
        const restServiceApi = OrderService.putOrder;
        restServiceApi(jsonPayload)
            .then(res => {
                this.props.sucessToast("Order is Updated!")
                setSubmitting(false)
                this.props.history.push(ordersListPage)
            })
            .catch(err => {
                toast.error("Something went wrong")
                setSubmitting(false);
            });
    }

    deleteEntity = (id) => {
        OrderService.deleteOrder(id).then(
            (res) => {
                this.props.sucessToast("Successfully Deleted Order Number: " + res.data.id)
                this.backButtonRef.current.click();
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    deleteOrderItem = (id) => {
        OrderService.deleteOrderItem(id).then(
            (res) => {
                this.getEntities()
                this.props.sucessToast("Successfully Deleted Order Item")
            }
        ).catch(() => this.props.errorToast("Something went wrong"))
    }

    render() {
        if(!this.state.id && this.state.isEditMode){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {this.state.isEditMode && <button style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove Product</button>}
                        <Formik initialValues={{  streetAddress: this.state.streetAddress, apartment: this.state.apartment, town: this.state.town, phone: this.state.phone, state: this.state.state  }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setFieldTouched}) => (
                                <form onSubmit={handleSubmit}>
                                    <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a ref={this.orderDetailsTab} className={"nav-link active " + classesTab.Tabs} id="general-tab" data-toggle="tab" href="#orderDetails" role="tab"
                                               aria-controls="orderDetails" aria-selected="true">Order Details</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + classesTab.Tabs} id="data-tab" data-toggle="tab" href="#shippingAddress" role="tab"
                                               aria-controls="data" aria-selected="false">Shipping Address</a>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <a ref={this.linksTab} className={"nav-link " + classesTab.Tabs} id="links-tab" data-toggle="tab" href="#links" role="tab"*/}
                                        {/*       aria-controls="links" aria-selected="false">Links</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                        {/*    <a ref={this.attributesTab} className={"nav-link " + classesTab.Tabs} id="data-attributes" data-toggle="tab" href="#attributes" role="tab"*/}
                                        {/*       aria-controls="attributes" aria-selected="false">Attributes</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                        {/*    <a ref={this.optionsTab} className={"nav-link " + classesTab.Tabs} id="data-options" data-toggle="tab" href="#options" role="tab"*/}
                                        {/*       aria-controls="options" aria-selected="false">Options</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                        {/*    <a ref={this.imagesTab} className={"nav-link " + classesTab.Tabs} id="data-images" data-toggle="tab" href="#images" role="tab"*/}
                                        {/*       aria-controls="images" aria-selected="false">Images</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {/*Order Details*/}
                                        <div className="tab-pane fade show active" id="orderDetails" role="tabpanel" aria-labelledby="orderDetails-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Order Details
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.isEditMode && <FormFieldDisabled name="Order ID" value={this.state.id}/>}
                                                        {this.state.orderStatusList.length > 0 && <FormSelectField label="Order Status"
                                                               value={this.state.orderStatus}
                                                               onChange={data => this.setState({orderStatus: data})}
                                                               list={this.state.orderStatusList}/>}
                                                       <button data-toggle="modal" data-target="#orderItemAddModal" style={{margin: "10px"}} className="btn btn-primary">Add New Order Item</button>
                                                        <table className="table" style={{marginTop: "10px", textAlign: "right"}}>
                                                            <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th scope="col"># Product Name</th>
                                                                <th scope="col">Price</th>
                                                                <th scope="col">Product Options</th>
                                                                <th scope="col">Quantity</th>
                                                                <th>Total</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.orderItems.map((item, index) =>
                                                                <tr>
                                                                    <td><ArtistImage photoUrl={`${item.product.productImageDTOS[0].url}`} size={5}/></td>
                                                                    <td><ViewComponentExternalLink url={`${hotelsListPage}/${item.product.id}`} text={`${item.product.id}: ${item.product.name}`}/></td>
                                                                    <td><NepaliCurrency value={item.product.discount ? item.product.discount : item.product.price}/></td>
                                                                    <td>
                                                                        {item.orderItemOptionTypes.map(ot => (
                                                                            <p>
                                                                                {ot.productOption.title}: {ot.productOptionType.name} ({ot.productOptionType.priceModifier}<NepaliCurrency value={`${ot.productOptionType.priceChanges}`}/>)
                                                                            </p>
                                                                        ))}
                                                                    </td>
                                                                    <td>x {item.quantity}</td>
                                                                    <td><NepaliCurrency value={item.total}/></td>
                                                                    <td>
                                                                        <button onClick={() => this.setState({updateEntity: item})} data-toggle="modal" data-target="#orderItemModal" style={{margin: "0 5px 5px 5px"}} className="btn btn-warning">Edit</button>
                                                                        <button style={{margin: "0 5px 5px 5px"}} onClick={() => this.setState({deleteOrderItem: {id: item.id, name: item.product.name}})} className="btn btn-danger" data-toggle="modal" data-target="#orderItemDeleteModal">Remove</button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td colSpan={5}>Sub-Total</td>
                                                                <td><NepaliCurrency value={this.state.entity.subTotal}/></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5}>Shipping Fee</td>
                                                                <td><NepaliCurrency value={50}/></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5}>Total</td>
                                                                <td><NepaliCurrency value={this.state.entity.grandTotal}/></td>
                                                                <td></td>
                                                            </tr>
                                                            </tbody>
                                                        </table>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="shippingAddress" role="tabpanel" aria-labelledby="shippingAddress-tab">
                                            <div className="card card-outline-secondary my-4">
                                                <div className="card-header">
                                                    Shipping Address Properties
                                                </div>
                                                <div className="card-body">
                                                                <div className="row">
                                                                <form onSubmit={handleSubmit}>
                                                                    <FormField name="Street Address" required={true} touched={touched.streetAddress} errors={errors.streetAddress} value={values.streetAddress} setFieldValue={setFieldValue} fieldName='streetAddress' onBlurHandler={setFieldTouched}/>
                                                                    <FormField name="Apartment" required={true} touched={touched.apartment} errors={errors.apartment} value={values.apartment} setFieldValue={setFieldValue} fieldName='apartment' onBlurHandler={setFieldTouched}/>
                                                                    <FormField name="Town" required={true} touched={touched.town} errors={errors.town} value={values.town} setFieldValue={setFieldValue} fieldName='town' onBlurHandler={setFieldTouched}/>
                                                                    <FormField name="State" required={true} touched={touched.state} errors={errors.state} value={values.state} setFieldValue={setFieldValue} fieldName='state' onBlurHandler={setFieldTouched}/>
                                                                    <FormField name="Phone" required={true} touched={touched.phone} errors={errors.phone} value={values.phone} setFieldValue={setFieldValue} fieldName='phone' onBlurHandler={setFieldTouched}/>
                                                                </form>
                                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/!*Links*!/*/}
                                        {/*<div className="tab-pane fade" id="links" role="tabpanel" aria-labelledby="links-tab">*/}
                                        {/*    <div className="card card-outline-secondary my-4">*/}
                                        {/*        <div className="card-header">*/}
                                        {/*            Links Properties*/}
                                        {/*        </div>*/}
                                        {/*        <div className="card-body">*/}
                                        {/*            <div className="row">*/}
                                        {/*                <SingleAutoCompleteCMSComponent title="Manufacturer" searchText={this.state.manufacturerInputValue} setSearchText={value => this.setState({manufacturerInputValue: value})} errors={errors.manufacturerId}*/}
                                        {/*                        fieldName={"name"} valueSelectField={"id"}*/}
                                        {/*                        selectedId={this.state.manufacturerId}*/}
                                        {/*                        valueSelectCallback={(id, value) => {this.setState({manufacturerId: id}); this.setState({manufacturerInputValue: value});}}*/}
                                        {/*                        valueClearCallback={() => {this.setState({manufacturerId: -1}); this.setState({manufacturerInputValue: ''});}}*/}
                                        {/*                        entityServiceSearchCall={ManufacturerService.searchForManufacturers}/>*/}
                                        {/*                <CategoryMultiAutoCompleteCMSComponent required title="Categories" searchText={this.state.categoryInputValue} setSearchText={value => this.setState({categoryInputValue: value})} errors={errors.categoryIds}*/}
                                        {/*                       fieldName={"title"} valueSelectField={"id"}*/}
                                        {/*                       selectedIds={this.state.categoryIds}*/}
                                        {/*                       valueSelectCallback={(id, value, categoryChain) => {this.setState({categoryIds: [...this.state.categoryIds, {id, value, categoryChain}]}); this.setState({categoryInputValue: ""});}}*/}
                                        {/*                       onRemove={(index) => this.categoryIdRemove(index)}*/}
                                        {/*                       valueClearCallback={() => {this.setState({categoryIds: []}); this.setState({categoryInputValue: ''});}}*/}
                                        {/*                       entityServiceSearchCall={CategoryService.searchForCategories}/>*/}
                                        {/*                <TagsCMSComponent title="Tags" searchText={this.state.tagsInputValue} setSearchText={value => this.setState({tagsInputValue: value})} errors={errors.tags}*/}
                                        {/*                       fieldName={"name"} valueSelectField={"id"}*/}
                                        {/*                       addNewTag={() => {this.setState({tags: [...this.state.tags, {id: -1, name: this.state.tagsInputValue}]}); this.setState({tagsInputValue: ""});}}*/}
                                        {/*                       selectedIds={this.state.tags}*/}
                                        {/*                       valueSelectCallback={(id, value) => {this.setState({tags: [...this.state.tags, {id, name: value}]}); this.setState({tagsInputValue: ""});}}*/}
                                        {/*                       onRemove={(index) => this.tagsRemove(index)}*/}
                                        {/*                       valueClearCallback={() => {this.setState({tags: []}); this.setState({tagsInputValue: ''});}}*/}
                                        {/*                       entityServiceSearchCall={TagService.searchForTags}/>*/}
                                        {/*                <RelatedMultiAutoCompleteCMSComponent required title="Related Products" searchText={this.state.relatedProductsInputValue} setSearchText={value => this.setState({relatedProductsInputValue: value})} errors={errors.relatedProducts}*/}
                                        {/*                       fieldName={"name"} valueSelectField={"id"}*/}
                                        {/*                       selectedIds={this.state.relatedProducts}*/}
                                        {/*                       valueSelectCallback={(id, value) => {this.setState({relatedProducts: [...this.state.relatedProducts, {id, name: value}]}); this.setState({relatedProductsInputValue: ""});}}*/}
                                        {/*                       onRemove={(index) => this.relatedProductsRemove(index)}*/}
                                        {/*                       valueClearCallback={() => {this.setState({relatedProducts: []}); this.setState({relatedProductsInputValue: ''});}}*/}
                                        {/*                       entityServiceSearchCall={ProductService.searchForArtist}/>*/}

                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*/!*Attributes*!/*/}
                                        {/*<div className="tab-pane fade" id="attributes" role="tabpanel" aria-labelledby="attributes-tab">*/}
                                        {/*    <div className="card card-outline-secondary my-4">*/}
                                        {/*        <div className="card-header">*/}
                                        {/*            Attributes*/}
                                        {/*        </div>*/}
                                        {/*        <div className="card-body">*/}
                                        {/*            <div className="row">*/}
                                        {/*                <table className="table">*/}
                                        {/*                    <thead>*/}
                                        {/*                    <tr>*/}
                                        {/*                        <th scope="col">#</th>*/}
                                        {/*                        <th scope="col">Attribute</th>*/}
                                        {/*                        <th scope="col">Text</th>*/}
                                        {/*                        <th></th>*/}
                                        {/*                    </tr>*/}
                                        {/*                    </thead>*/}
                                        {/*                    <tbody>*/}
                                        {/*                        {this.state.productAttributes.map((attribute, index) =>*/}
                                        {/*                            <HotelPhoneNumber onChange={e => this.handleAttributeChange(e, index)} index={index} attribute={attribute.attribute} text={attribute.text} onRemove={index => this.handleAttributeRemove(index)}/>*/}
                                        {/*                        )}*/}
                                        {/*                    </tbody>*/}
                                        {/*                </table>*/}
                                        {/*                <button type="button" style={{margin: "0 auto"}} onClick={() => this.setState({productAttributes: [...this.state.productAttributes, {attribute: "", text: ""}]})} className="btn btn-primary"><AddIcon/> Add an Attribute</button>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*/!*Options*!/*/}
                                        {/*<div className="tab-pane fade" id="options" role="tabpanel" aria-labelledby="options-tab">*/}
                                        {/*    {this.state.options.map((option, index) =>*/}
                                        {/*        <ProductOption option={option} index={index} optionTitleChange={(value, index) => this.handleOptionTitleChange(value, index)} optionDisabledChange={(value, index) => this.handleOptionDisabledChange(value, index)} optionTypeNameChange={this.handleOptionTypeNameChange} optionTypePriceModifierChange={this.handleOptionTypePriceModifierChange} optionTypePriceChangesChange={this.handleOptionTypePriceChangesChange} optionTypeDisabledChange={this.handleOptionTypeDisabledChange} optionTypeImageChange={this.handleOptionTypeImageChange} optionTypeImageRemove={this.handleOptionTypeImageRemove} optionTypeAdd={this.handleOptionTypeAdd} optionTypeRemove={this.handleOptionTypeRemove} optionRemove={this.handleOptionRemove}/>*/}
                                        {/*    )}*/}
                                        {/*    <button type="button" style={{margin: "0 auto"}} onClick={() => this.setState({options: [...this.state.options, {title: '', disabled: false, types: [{name: '', priceModifier: '+', priceChanges: 0, disabled: false, image: null, localImageUrl: null}]}]})} className="btn btn-primary mt-2"><AddIcon/> Add Another Buying Option</button>*/}
                                        {/*</div>*/}
                                        {/*/!*IMAGES*!/*/}
                                        {/*<div className="tab-pane fade" id="images" role="tabpanel" aria-labelledby="images-tab">*/}
                                        {/*    <div className="card card-outline-secondary my-4">*/}
                                        {/*        <div className="card-header">*/}
                                        {/*            Images Properties*/}
                                        {/*        </div>*/}
                                        {/*        <div className="card-body">*/}
                                        {/*            <div className="row">*/}
                                        {/*                <table className="table">*/}
                                        {/*                    <tbody>*/}
                                        {/*                        /!*{this.state.imageUrls.map((image, index) =>*!/*/}
                                        {/*                        /!*    <DnDImageList image={image} index={index}/>*!/*/}
                                        {/*                        /!*)}*!/*/}
                                        {/*                        <DnDImageList imageUrls={this.state.imageUrls} onRemoveImagePreview={this.onRemoveImagePreview} imageOnDragEnd={this.handleImageOnDragEnd}/>*/}
                                        {/*                        <h2>Image Uploader</h2>*/}
                                        {/*                        {this.state.imageFiles.map((image, index) =>*/}
                                        {/*                            <ImageUploaderCustom onChange={e => this.handleImageChange(e, index)} localUrl={image.localUrl} index={index} size={this.state.imageFiles.length} file={image.file} sortOrder={image.sortOrder} onRemove={index => this.handleImageRemove(index)}/>*/}
                                        {/*                            // <ImageUploaderCustom onChange={e => this.handleAttributeChange(e, index)} index={index} attribute={attribute.attribute} text={attribute.text} onRemove={index => this.handleAttributeRemove(index)}/>*/}
                                        {/*                        )}*/}
                                        {/*                    </tbody>*/}
                                        {/*                </table>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px"}}>
                                        <button type="button" ref={this.backButtonRef} onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                                        {!this.state.isEditMode && <button type="submit" disabled={isSubmitting}
                                                 onClick={() => this.setState({saveAndNew: true})}
                                                 className="btn btn-success"><SaveFontAwesome/> Save & New</button>}
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary"><SaveFontAwesome/> Save</button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
                {this.state.updateEntity && <OrderItemUpdateModal title={`Editing ID: [${this.state.updateEntity.id}] ${this.state.updateEntity.product.name}`}
                           id={this.state.updateEntity.id}
                           updateEntity={this.state.updateEntity}
                           callback={() => this.getEntities()}
                           resetOrderItem={this.resetOrderItem}
                           confirmLabel={<><EditFontAwesome/> Save</>}
                           buttonTheme="btn-warning"/>}
                <OrderItemAddModal title={`Adding Order Item`}
                          orderId={this.state.entity.id}
                          callback={() => this.getEntities()}
                          confirmLabel={<><EditFontAwesome/> Save</>}
                          buttonTheme="btn-warning"/>
                {this.state.isEditMode && <ModalView title={`Delete ${this.state.name}`} id={this.state.id}
                            body={`Are you sure you want to remove Order ID: [${this.state.id}], of ${this.state.entity.user.username}?`}
                            callback={this.deleteEntity} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                            buttonTheme="btn-danger"/>}
                <OrderItemDeleteModal title={`Delete ${this.state.deleteOrderItem.name}`} id={this.state.deleteOrderItem.id}
                             body={`Are you sure you want to remove Order Item ID: [${this.state.deleteOrderItem.id}], ${this.state.deleteOrderItem.name}?`}
                             callback={this.deleteOrderItem} confirmLabel={<><RemoveFontAwesome/> Delete</>}
                             buttonTheme="btn-danger"/>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(OrdersUpdate);