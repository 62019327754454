import Axios from "axios";
import { BASE_URL } from "../../constants/GlobalConstants";

// Action Types
export const SET_ARTIST_PAGED = 'SET_ARTIST_PAGED';
export const SEARCH_ARTIST_PAGED = 'SEARCH_ARTIST_PAGED';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const RESET_SEARCH = 'RESET_SEARCH';

// Actions
export const fetchArtists = (options) => {
    let url = BASE_URL + "/artists?page=" + options.page;
    url = options.sort && options.sort !== '' ? `${url}&sort=${options.sort.field},${options.sort.order}` : `${url}&sort=name,asc`;
    url = options.searchText && options.searchText !== '' ? url + "&name=" + options.searchText : url;
    return dispatch => {
        Axios.get(url).then(res => {
            dispatch(setArtists(res.data));
        })
    }
}

export const onSearchArtists = (options) => {
    let url = BASE_URL + "/artists?page=" + options.page;
    url = options.sort && options.sort !== '' ? `${url}&sort=${options.sort.field},${options.sort.order}` : `${url}&sort=name,asc`;
    url = options.searchText && options.searchText !== '' ? url + "&name=" + options.searchText : url;
    return dispatch => {
        Axios.get(url).then(res => {
            dispatch(searchArtists(res.data));
        })
    }
}

const setArtists = (payload) => {
    return {
        type: SET_ARTIST_PAGED,
        payload: payload
    }
}

export const setSearchText = (searchText) => {
    return {
        type: SET_SEARCH_TEXT,
        searchText: searchText
    }
}

export const searchArtists = (payload) => {
    return {
        type: SEARCH_ARTIST_PAGED,
        payload: payload
    }
}

export const resetSearch = () => {
    return {
        type: RESET_SEARCH
    }
}

// Reducer
const initialState = {
    artists : [],
    page: 0,
    size: null,
    sort: null,
    hasMore: true,
    searchText: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ARTIST_PAGED:
            return {...state, artists: [...state.artists, ...action.payload.content], page: state.page + 1, hasMore: !action.payload.last}
        case SET_SEARCH_TEXT:
            return {...state, searchText: action.searchText}
        case SEARCH_ARTIST_PAGED:
            return {...state, artists: [...action.payload.content], page: 1, hasMore: !action.payload.last}
        case RESET_SEARCH:
            return {...state, searchText: ''}
        default:
            return state;
        
    }
}

export default reducer;