import React, { Component } from 'react';
import classes from './LoginContainer.module.css'
import bg from '../../assets/svgs/login.svg'
import logo from '../../assets/icon.png'
import { Link } from 'react-router-dom'
import { Formik } from 'formik';
import * as Yup from 'yup';
import ErrorFormItem from '../../components/ErrorFormItem' 
import AuthenticationService from '../../services/AuthenticationService';
import * as actions from '../../layouts/RootLayout/rootLayout.reducer'
import {connect} from 'react-redux'

class RegisterContainer extends Component {

    validationSchema = Yup.object().shape({
        firstName: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required"),
        lastName: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required"),
        username: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required")
            // .test('unique-username', 'Username already in use', function(value){
            //     if(value){
            //         return AuthenticationService.checkUsedRegistrationFields(value, 'validUsername')
            //             .then(res => {return res.data == false;})
            //     } 
            // })
        ,
        password: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required"),
        confirmPassword: Yup.string().required("Required").test('passwords-match', 'Passwords Not Matched', function(value) {
            return this.parent.password === value;
          }),
        email: Yup.string().min(4, "Too Short").max(100, "Too Long").required("Required").email("Invalid email")
            // .test('unique-email', 'Email already in use', function(value){
            //     if(value){
            //         return AuthenticationService.checkUsedRegistrationFields(value, 'validEmail')
            //             .then(res => {return res.data == false;})
            //     } 
            // })
        ,
        phoneNumber: Yup.string().min(4, "Too Short").max(50, "Too Long").required("Required")
    });

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        const user = {
            username: values.username,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email
        };
        AuthenticationService.registerUser(user)
            .then(res => { this.props.sucessToast("Registration Successful. Please Login."); this.props.history.push("/login")})
            .catch(err => {
                setSubmitting(false);
                if( err.response.data.httpStatus == "CONFLICT" ){
                    this.props.errorToast(err.response.data.message);
                    setFieldError(err.response.data.errorField, err.response.data.message);
                } else {
                    this.props.errorToast("Something went Wrong");
                }
            });
    }

    render() {
        return (
            <div className={classes.splashContainer} style={{ backgroundImage: `url(${bg})` }}>
                <div className={[classes.card, classes.RegitserWidth].join(" ")}>
                    <div className="card-body">
                        <Formik initialValues={{ firstName: "", lastName: "", username: "", password: "", confirmPassword: "", email: "", phoneNumber: "" }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldError}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="firstName" style={{float: "left"}}>First Name*</label>
                                            <ErrorFormItem touched={touched.firstName} message={errors.firstName} style={{float: "right"}}/>
                                            <input className={["form-control", touched.firstName && errors.firstName ? classes.hasError : ""].join(" ")} id="firstName" type="text" placeholder="First Name" autoComplete="on"
                                                   value={values.firstName}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="lastName" style={{float: "left"}}>Last Name*</label>
                                            <ErrorFormItem touched={touched.lastName} message={errors.lastName} style={{float: "right"}}/>
                                            <input className={["form-control", touched.lastName && errors.lastName ? classes.hasError : ""].join(" ")} id="lastName" type="text" placeholder="Last Name" autoComplete="on"
                                                   value={values.lastName}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="userName" style={{float: "left"}}>Username*</label>
                                            <ErrorFormItem touched={touched.username} message={errors.username}/>
                                            <input className={["form-control", touched.username && errors.username ? classes.hasError : ""].join(" ")} id="username" type="text" placeholder="Username" autoComplete="off"
                                                   value={values.username}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="phoneNumber" style={{float: "left"}}>Phone Number*</label>
                                            <ErrorFormItem touched={touched.phoneNumber} message={errors.phoneNumber}/>
                                            <input className={["form-control", touched.phoneNumber && errors.phoneNumber ? classes.hasError : ""].join(" ")} id="phoneNumber" type="number" placeholder="Phone Number" autoComplete="off"
                                                   value={values.phoneNumber}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="password" style={{float: "left"}}>Password*</label>
                                            <ErrorFormItem touched={touched.password} message={errors.password}/>
                                            <input className={["form-control", touched.password && errors.password ? classes.hasError : ""].join(" ")} id="password" type="password" placeholder="Password"
                                                   value={values.password}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label htmlFor="confirmPassword" style={{float: "left"}}>Confirm Password*</label>
                                            <ErrorFormItem touched={touched.confirmPassword} message={errors.confirmPassword}/>
                                            <input className={["form-control", touched.confirmPassword && errors.confirmPassword ? classes.hasError : ""].join(" ")} id="confirmPassword" type="password" placeholder="Confirm Password"
                                                   value={values.confirmPassword}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email" style={{float: "left"}}>Email*</label>
                                        <ErrorFormItem touched={touched.email} message={errors.email}/>
                                        <input className={["form-control", touched.email && errors.email ? classes.hasError : ""].join(" ")} id="email" type="email" placeholder="Email" autoComplete="off"
                                               value={values.email}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                        />
                                    </div>

                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block">Register an Account</button>
                                </form>
                            )
                            }
                        </Formik>
                    </div>
                    <div className={"card-footer bg-white p-0  " + classes.cardFooter}>
                        <div className={"card-footer-item card-footer-item-bordered " + classes.cardFooterItemLeft}>
                            <Link to="/login" className={"footer-link " + classes.Link}>Login</Link></div>
                        <div className={"card-footer-item card-footer-item-bordered " + classes.cardFooterItemRight}>
                            <a href="#" className={"footer-link " + classes.Link}>Forgot Password</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message))
    }
}

export default connect(null, mapDispatchToProps)(RegisterContainer);