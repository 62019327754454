import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants'

class VideoProductionOrderService {

    getVideoProductionOrder(id){
        return axios.get(BASE_URL + "/videoProductionOrder/" + id)
    }

    getPagedVideoProductionOrders(options){
        let url = BASE_URL + "/videoProductionOrder?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url += options.username && `&username=${options.username}`;
        url += options.videoProductionType && `&videoProductionType=${options.videoProductionType}`;
        url += options.startDate ? `&startDate=${options.startDate}` : '';
        url += options.endDate ? `&endDate=${options.endDate}` : '';
        url += options.productionStatus ? `&productionStatus=${options.productionStatus}` : '';
        return axios.get(url);
    }

    postVideoProductionOrder(formData){
        return axios.post(BASE_URL + "/videoProductionOrder", formData);
    }

    putVideoProductionOrder(entity){
        return axios.put(BASE_URL + "/videoProductionOrder", entity);
    }

    deleteVideoProductionOrder(entityId){
        return axios.delete(BASE_URL + `/videoProductionOrder/${entityId}`);
    }

}

export default new VideoProductionOrderService();