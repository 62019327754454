import React, {Component} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../components/FontAwesomeComponent";
import SongService from "../../../../services/SongService";
import {ViewComponentExternalLink, ViewComponentLink} from "../../../../components/CMSFormComponents";
import {artistListPage, chordsViewPage} from "../../../../constants/routesConstants";
import VideoProductionService from "../../../../services/VideoProductionService";

class VideoProductionDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        VideoProductionService.getVideoProduction(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Type</td>
                                <th>{this.state.entity.videoProductionType}</th>
                            </tr>
                            <tr>
                                <td scope="row">Price</td>
                                <th>Rs. {this.state.entity.price}</th>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                            <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default VideoProductionDetail;