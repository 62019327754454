import axios from 'axios';
import {BASE_URL} from '../constants/GlobalConstants'

class UserAdminService {

    getAllUsers(){
        return axios.get(BASE_URL + "/admin/users/all");
    }

    getUser(id){
        return axios.get(BASE_URL + "/admin/users/" + id)
    }

    getPagedUsers(options){
        let url = BASE_URL + "/admin/users?page=" + options.page;
        url = (options.sortField && options.sortOrder) && (options.sortField !== '' && options.sortOrder !== '') ? `${url}&sort=${options.sortField},${options.sortOrder}` : `${url}&sort=id,asc`;
        url = options.username && options.username !== '' ? url + "&username=" + options.username : url;
        return axios.get(url);
    }

    searchForUsersByUsername(username, page, size){
        let url = BASE_URL + "/admin/users?username=" + username + "&page=" + page;
        if(size) url += `&size=${size}`
        return axios.get(url);
    }

    postUser(formData){
        return axios.post(BASE_URL + "/admin/users", formData);
    }

    putUser(user){
        return axios.put(`${BASE_URL}/admin/users`, user);
    }

    deleteUser(songId){
        return axios.delete(BASE_URL + `/admin/users/${songId}`);
    }

    getTotalViewOfHotels(){
        return axios.get(BASE_URL + `/dashboard/totalViewCount`);
    }

}

export default new UserAdminService();
