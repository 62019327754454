import React, {Component} from 'react'
import ArtistService from "../../../../services/ArtistService";
import Spinner from "../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../components/FontAwesomeComponent";
import {songListPage} from "../../../../constants/routesConstants";

class ArtistsDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        ArtistService.getArtist(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                            <ArtistImage photoUrl={this.state.entity.photoUrl} size={5} name={this.state.entity.name}/>
                        </div>
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Name</td>
                                <th>{this.state.entity.name}</th>
                            </tr>
                            <tr>
                                <td scope="row">Number of Songs</td>
                                {/*TODO Songs LIST*/}
                                <th><Link to={`${songListPage}?artistName=${this.state.entity.name}`}>
                                    {this.state.entity.songs.length} Songs <FontAwesomeComponent name={"fa-link"}/></Link></th>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                            <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ArtistsDetail;