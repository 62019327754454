import React from 'react';

const ProductImageSmallThumbnail = (props) => {
    return (
        <div className="col-xs-4" onClick={() => props.onClick(props.index)}>
            <div className="card mt-2">
                <img className="card-img-top img-fluid" style={{width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    // border: "1px solid #ccc",
                    // margin: "10px",
                    borderRadius: "5px"}} src={props.src} alt=""/>
            </div>
        </div>
    );
};

export default ProductImageSmallThumbnail;
