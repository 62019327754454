import React, {Component} from 'react';
import classes from './LoginContainer.module.css'
import bg from '../../assets/svgs/login.svg'
import {Link} from 'react-router-dom'
import {Formik} from 'formik';
import * as Yup from 'yup';
import ErrorFormItem from '../../components/ErrorFormItem'
import * as actions from '../../layouts/RootLayout/rootLayout.reducer'
import * as authActions from './auth.reducer'
import {connect} from 'react-redux'

class LoginContainer extends Component {

    validationSchema = Yup.object().shape({
        username: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required"),
        password: Yup.string().min(1, "Too Short").max(255, "Too Long").required("Required")
    });

    onSubmit = (values, setSubmitting, resetForm, setFieldError) => {
        setSubmitting(true);
        
        // AuthenticationService.executeJwtAuthenticationService({username: values.username, password: values.password})
        //     .then(
        //         res => {
        //             this.props.sucessToast("Welcome!");
        //             AuthenticationService.setLocalStorageJwt(res.data.token);
        //             this.props.history.push("/");
        //         }
        // ).catch(err => {
        //     if(err.response && err.response.status === 401){ this.props.errorToast("Invalid Username Or Password!"); setFieldError('username', 'Invalid Username Or Password!')}
        //     else {this.props.errorToast("Something went wrong!")}
        // });
        this.props.onAuth(values.username, values.password, this.props.history.push);

        // resetForm();
        setSubmitting(false);
    }

    //BE extra columns - socialToken, socialProvider, socialId
    signupSocial = (res, type) => {
        let payload;
        if(type === 'facebook'){
            payload = {name: res.name, provider: type, email: res.email, provider_id: res.id, token: res.accessToken, provider_pic: res.picture.data.url}
        }
        if(type === "google"){
            payload = {name: res.profileObj.name, provider: type, email: res.profileObj.email, provider_id: res.googleId, token: res.accessToken, provider_pic: res.profileObj.imageUrl}
        }
    }

    responseFacebook = (res) => {
        this.signupSocial(res, 'facebook');
    }

    responseGoogle = (res) => {
        this.signupSocial(res, 'google');
    }



    render() {
        return (
            <div className={classes.splashContainer} style={{ backgroundImage: `url(${bg})` }}>
                <div className={classes.card}>
                    <div className="card-body">
                        <h5 className="card-title" style={{textAlign: "center"}}>Login</h5>
                        <Formik initialValues={{ username: "", password: "" }} onSubmit={(values, {setSubmitting, resetForm, setFieldError}) => this.onSubmit(values, setSubmitting, resetForm, setFieldError)} validationSchema={this.validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <ErrorFormItem touched={touched.username} message={this.props.authError}/>
                                        <input className={["form-control", touched.username && errors.username ? classes.hasError : ""].join(" ")} id="username" type="text" placeholder="Username or Email" autoComplete="off"
                                            value={values.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <ErrorFormItem touched={touched.password} message={errors.password}/>
                                        <input className={["form-control", touched.password && errors.password ? classes.hasError : ""].join(" ")} id="password" type="password" placeholder="Password" 
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <label className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" /><span className="custom-control-label" style={{ fontSize: "0.8rem", paddingTop: "5px" }}>Remember Me</span>
                                        </label>
                                    </div>
                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block">Login</button>
                                </form>

                            )
                            }
                        </Formik>
                    </div>
                    <div className={"card-footer bg-white p-0  " + classes.cardFooter}>
                        <div className={"card-footer-item card-footer-item-bordered " + classes.cardFooterItemLeft}>
                            <Link to="/register" className={"footer-link " + classes.Link}>Create An Account</Link></div>
                        <div className={"card-footer-item card-footer-item-bordered " + classes.cardFooterItemRight}>
                            <Link to="/forgot-password" className={"footer-link " + classes.Link}>Forgot Password</Link>
                        </div>
                    </div>
                </div>
                {/*<FacebookLogin*/}
                {/*    appId="353466285823310"*/}
                {/*    autoLoad={true}*/}
                {/*    fields="name,email,picture"*/}
                {/*    onClick={() => console.log("Component Clicked")}*/}
                {/*    callback={this.responseFacebook} />*/}
                {/*<GoogleLogin*/}
                {/*    clientId="964689403829-h5g3ggcqppm39hfd3a735tvdr4u6smq6.apps.googleusercontent.com"*/}
                {/*    buttonText="Login"*/}
                {/*    onSuccess={this.responseGoogle}*/}
                {/*    onFailure={(err) => {*/}
                {/*        errorToast("Something Went Wrong!")*/}
                {/*        console.log("GOOGLE ERR", err)*/}
                {/*    }}*/}
                {/*    cookiePolicy={'single_host_origin'}*/}
                {/*/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authError: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sucessToast: (message) => dispatch(actions.sucessToast(message)),
        errorToast: (message) => dispatch(actions.errorToast(message)),
        onAuth: (username, password, router) => dispatch(authActions.auth(username, password, router))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
