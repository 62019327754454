import React from 'react';
import {NepaliCurrency} from "../CMSFormComponents";

const ProductListItem = (props) => {
    return (
        <div onClick={() => props.onClick(props.id)} className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="card h-100 Item">
                <img className="card-img-top" src={props.imageUrl} alt={props.title}/>
                <div className="card-body">
                    <h4 className="card-title">
                        <a href="#">{props.title}</a>
                    </h4>
                    <h5><NepaliCurrency value={props.price}/> - {props.sortOrder}</h5>
                    <p className="card-text">{props.description}</p>
                </div>
                <div className="card-footer">
                    <small className="text-muted">&#9733; &#9733; &#9733; &#9733; &#9734;</small>
                </div>
            </div>
        </div>
    );
}

export default ProductListItem;