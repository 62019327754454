import React, {createRef, useState} from 'react';
import {MinusIcon} from "../FontAwesomeComponent";
import RichTextEditor from "../RichTextEditor";

function Room(props) {
    const richText = createRef();

    return (
        <>
            <tr>
                <td>{props.index + 1}</td>
                <td><input type="text" name="name" value={props.name} onChange={event => props.onChange(event, props.index)} className="form-control" placeholder="Room Name"/></td>
                <td><input type="number" name="price" value={props.price} onChange={event => props.onChange(event, props.index)} className="form-control" placeholder="Price"/></td>
                {props.length === props.index+1 && <td><button type="button" onClick={() => props.onRemove(props.index)} className="btn btn-danger"><MinusIcon/></button></td>}
            </tr>
            <tr>
                <td></td>
                <td colSpan={3}><RichTextEditor value={props.description} ref={richText} onChange={value => props.onDescriptionChange(value, props.index)} title="Description" placeholder="Description..."/></td>
            </tr>
        </>

    );
}

export default Room;