import React, {Component} from 'react'
import ArtistService from "../../../../../services/ArtistService";
import Spinner from "../../../../../components/Spinnner/Spinner";
import ArtistImage from "../../../../../components/ArtistItem/ArtistImage";
import {Link} from "react-router-dom";
import FontAwesomeComponent, {BackFontAwesome, EditFontAwesome} from "../../../../../components/FontAwesomeComponent";
import SongService from "../../../../../services/SongService";
import {ViewComponentExternalLink, ViewComponentLink} from "../../../../../components/CMSFormComponents";
import {artistListPage, categoriesListPage, chordsViewPage} from "../../../../../constants/routesConstants";
import CategoryService from "../../../../../services/Shop/CategoryService";

class CategoryDetail extends Component {

    state = {
        entity: null
    }

    componentDidMount() {
        CategoryService.getCategory(this.props.match.params.id).then(
            item => this.setState({entity: item.data})
        )
    }

    render() {
        if(!this.state.entity){
            return(
                <Spinner/>
            )
        }

        return (
            <div>
                <div className="card" style={{boxShadow: "5px 1px 12px rgba(0, 0, 0, 0.1)"}}>
                    <div className="card-body">
                        {/*<div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>*/}
                        {/*    <ArtistImage photoUrl={this.state.entity.artistPhotoUrl} size={5} name={this.state.entity.artistName}/>*/}
                        {/*</div>*/}
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <td scope="row">Id</td>
                                <th>{this.state.entity.id}</th>
                            </tr>
                            <tr>
                                <td scope="row">Title</td>
                                <th><ViewComponentExternalLink url={`${categoriesListPage}/${this.state.entity.id}`} text={this.state.entity.title}/></th>
                            </tr>
                            <tr>
                                <td scope="row">Description</td>
                                <th dangerouslySetInnerHTML={{__html: this.state.entity.categoryDescription}}></th>
                            </tr>
                            <tr>
                                <td scope="row">Disabled</td>
                                <th>{this.state.entity.disabled ? <span className="badge badge-danger">Yes</span> : <span className="badge badge-primary">No</span>}</th>
                            </tr>
                            <tr>
                                <td scope="row">Sort Order</td>
                                <th>{this.state.entity.sortOrder} <span className="badge badge-primary">{this.state.entity.sortOrder > 75 ? "VERY LOW" : this.state.entity.sortOrder > 50 ? "LOW" : this.state.entity.sortOrder > 25 ? "HIGH" : "VERY HIGH"}</span></th>
                            </tr>
                            <tr>
                                <td scope="row">Parent Chain</td>
                                <th>{this.state.entity.categoryChain && this.state.entity.categoryChain.slice(1, this.state.entity.categoryChain.length).map(cat => <span key={cat.id}>{`${cat.name} > `}</span>)}{this.state.entity.title}</th>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <button onClick={() => this.props.history.goBack()} className="btn btn-secondary"><BackFontAwesome/> Back</button>
                            <Link to={`${this.props.match.url}/edit`}><button className="btn btn-warning"><EditFontAwesome/> Edit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CategoryDetail;