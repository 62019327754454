import React, {Component} from 'react';
import CategoryNavItem from "../../components/Store/CategoryNavItem";
import ProductListItem from "../../components/Store/ProductListItem";
import ShopHomeScreen from "./ShopHomeScreen";
import {Route, Switch} from "react-router-dom";
import CmsSettings from "../cms/CmsSettings";
import CmsSettingsUpdate from "../cms/CmsSettingsUpdate";
import PasswordUpdate from "../cms/PasswordUpdate";
import ProductScreen from "./ProductScreen";
import CategoryService from "../../services/Shop/CategoryService";
import Spinner from "../../components/Spinnner/Spinner";
import {DropdownButtonDrops, ItemListIcon, NavItemComponent} from "../../layouts/Dashboard/DashboardLayout";
import CategorySubNavItem from "../../components/Store/CategorySubNavItem";
import classes from "../../layouts/Dashboard/Dashboard.module.css";

class ShopScreen extends Component {

    state = {
        categoryTree: null
    }

    componentDidMount() {
        CategoryService.getCategoryTree().then(
            res => {
                this.setState({categoryTree: res.data});
            }
        );
    }

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-lg-3">

                        <h1 className="my-4">Categories</h1>
                        <ul className={classes.categoryComponents} style={{backgroundColor: "rgb(150, 91, 165)", borderRadius: "5px"}}>
                        {this.state.categoryTree ? this.state.categoryTree.children.map(child => (
                                <li>
                                    <CategoryNavItem link={"#"} id={`${child.name}-${child.id}`} name={child.name}/>
                                    {child.children.length>0 && <div className="list-group" className="collapse" id={`${child.name}-${child.id}`}>
                                        <ul className={classes.categoryComponents}>
                                            {child.children.map(children => (
                                                <li>
                                                    <CategorySubNavItem child={children}/>
                                                </li>
                                            ))}
                                    </ul>
                                    </div>}
                                </li>
                            )) : <Spinner/>}

                        </ul>

                    </div>

                    <div className="col-lg-9">
                        <Switch>
                            {/*<Route exact path={`${this.props.match.url}/:id`} component={ProductScreen}/>*/}
                            <Route exact path={`${this.props.match.url}/`} component={ShopHomeScreen}/>
                            {/*<Route exact path={`${this.props.match.url}/`} component={PasswordUpdate}/>*/}
                        </Switch>
                    </div>

                </div>
            </>
        );
    }
}

export default ShopScreen;