import React from 'react';
import {FormSelectField} from "../CMSFormComponents";
import HotelPhoneNumber from "./HotelPhoneNumber";
import {AddIcon, MinusIcon} from "../FontAwesomeComponent";
import {CLOUDINARY_URL} from "../../constants/GlobalConstants";

function ProductOption(props) {
    return (
        <>
            <div className="card card-outline-secondary my-4">
                <div className="card-header">
                    {props.option.title ? props.option.title : "Option"}
                </div>
                <div className="card-body">
                    <button onClick={() => props.optionRemove(props.index)} type="button" style={{float: "right"}} className="btn btn-danger" data-toggle="modal" data-target="#customModal">Remove Option</button>
                    <div className="row">
                        <div className="col-md-6" style={{marginTop: "10px"}}>
                            <label style={{float: "left"}}>Title</label>
                            <input type="text" name="attribute" value={props.option.title} onChange={event => props.optionTitleChange(event.target.value, props.index)} className="form-control" placeholder="Title"/>
                        </div>
                        <FormSelectField label="Disabled?" value={props.option.disabled ? "Yes" : "No"} onChange={data => props.optionDisabledChange(data, props.index)} list={["Yes", "No"]}/>
                    </div>
                    <table className="table table-bordered table-hover mt-2">
                        <thead>
                        <tr>
                            <th style={{minWidth: "120px"}} scope="col">Option Name</th>
                            <th style={{minWidth: "120px"}} scope="col">Price</th>
                            <th scope="col">Image</th>
                            <th scope="col">Disabled?</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.option.types.map((type, typeIndex) =>
                            <tr>
                                <td><input type="text" name="name" value={type.name} onChange={event => props.optionTypeNameChange(event.target.value, props.index, typeIndex)} className="form-control" placeholder="Option Name"/></td>
                                <td>
                                    <select value={type.priceModifier} className="form-control mr-sm-2" onChange={event => props.optionTypePriceModifierChange(event.target.value, props.index, typeIndex)}>
                                        <option key="+" value="+">+</option>
                                        <option key="-" value="-">-</option>
                                    </select>
                                    <input type="number" name="priceChanges" value={type.priceChanges} onChange={event => props.optionTypePriceChangesChange(event.target.value, props.index, typeIndex)} className="form-control" placeholder="Price to +/-"/>
                                </td>
                                <td>
                                    {type.localImageUrl && <img style={{width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px"}} src={type.localImageUrl}/>}
                                    {type.imageUrl && <img style={{width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px"}} src={`${CLOUDINARY_URL}${type.imageUrl}`}/>}
                                    {(type.localImageUrl || type.imageUrl) && <button type="button" style={{position: "relative", left: "-32px", top: "-37px", borderRadius: "50px"}} onClick={() => props.optionTypeImageRemove(props.index, typeIndex)} className="btn btn-danger"><MinusIcon/></button>}
                                    {!(type.localImageUrl || type.imageUrl) && <input  style={{width: "100px"}} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(event) => props.optionTypeImageChange(event, props.index, typeIndex)}/>}
                                </td>
                                <td>
                                    <select value={type.disabled ? "Yes" : "No"} className="form-control mr-sm-2" onChange={event => props.optionTypeDisabledChange(event.target.value, props.index, typeIndex)}>
                                        <option key="Yes" value="Yes">Yes</option>
                                        <option key="No" value="No">No</option>
                                    </select>
                                </td>
                                <td><button type="button" onClick={() => props.optionTypeRemove(props.index, typeIndex)} className="btn btn-danger"><MinusIcon/></button></td>
                            </tr>
                        )}
                        <tr><td></td><td></td><td></td><td></td><td><button type="button" onClick={() => props.optionTypeAdd(props.index)} className="btn btn-success"><AddIcon/></button></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
}

export default ProductOption;