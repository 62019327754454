import ErrorFormItem from "./ErrorFormItem";
import classes from "../containers/cms/entities/Artists/styles/artist.module.css";
import React, {useEffect, useState} from "react";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import FontAwesomeComponent, {MinusIcon} from "./FontAwesomeComponent";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {logout} from "../containers/AuthContainer/auth.reducer";
import {CLOUDINARY_URL} from "../constants/GlobalConstants";
import ProductService from "../services/Shop/HotelService";

export const FormField = (props) => {

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor={props.name} style={{float: "left"}}>{props.name}{props.required && <>*</>}</label>
            <ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>
            <input autoComplete={props.autocomplete == "off" ? "off" : "on"} type={props.type ? props.type : "text"} value={props.value} id={props.name} onChange={(data) => props.setFieldValue(props.fieldName, data.target.value)} onBlur={() => props.onBlurHandler(props.fieldName, true)} className={["form-control", props.touched && props.errors ? classes.hasError : ""].join(" ")} placeholder={props.name}/>
        </div>
    );
}

export const SimpleFormField = (props) => {

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor={props.name} style={{float: "left"}}>{props.name}{props.required && <>*</>}</label>
            <input type={props.type ? props.type : "text"} value={props.value} id={props.name} onChange={(data) => props.onChange(data.target.value)}className={["form-control"].join(" ")} placeholder={props.name}/>
        </div>
    );
}

export const FormFieldDateTimePicker = (props) => {
    const CustomInput = ({ value, onClick }) => (
        <input type="text" value={value} placeholder={`Pick ${props.label}`} onClick={onClick} className={"form-control"}/>
    );
    return (
        <>
            <div  className="col-md-6" style={{marginTop: "10px"}}>
                <label htmlFor={props.name} style={{float: "left"}}>{props.name}{props.required && <>*</>}</label>
                <DatePicker style={{width: "100%"}} className={"form-control"} customInput={<CustomInput />} showTimeSelect selected={props.selected} onChange={props.onChange} dateFormat="dd/MM/yyyy h:mm aa"/>
            </div>
        </>
    )
}

export const FormTextAreaField = (props) => {

    return (
        <div className="col-md-12" style={{marginTop: "10px"}}>
            <label htmlFor={props.name} style={{float: "left"}}>{props.name}{props.required && <>*</>}</label>
            <ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>
            <textarea type={props.type ? props.type : "text"} value={props.value} id={props.name} onChange={(data) => props.setFieldValue(props.fieldName, data.target.value)} onBlur={() => props.onBlurHandler(props.fieldName, true)} className={["form-control", props.touched && props.errors ? classes.hasError : ""].join(" ")} placeholder={props.name}/>
        </div>
    );
}

export const FormSelectField = (props) => {
    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor={props.label} style={{float: "left"}}>{props.label}{props.required && <>*</>}</label>
            <select value={props.value} className="form-control mr-sm-2" onChange={event => props.onChange(event.target.value)}>
                {props.list.map(
                    item => {
                        return (
                            <option key={item} value={item}>{item}</option>
                        );
                    }
                )}
            </select>
            {/*<input type={props.type ? props.type : "text"} value={props.value} id={props.name} onChange={(data) => props.setFieldValue(props.fieldName, data.target.value)} onBlur={() => props.onBlurHandler(props.fieldName, true)} className={["form-control", props.touched && props.errors ? classes.hasError : ""].join(" ")} placeholder={props.name}/>*/}
        </div>
    );
}

export const SortOrderField = (props) => {
    let sortLabel = "";
    if(props.value > 75){
        sortLabel = "VERY LOW";
    } else if(props.value > 50){
        sortLabel = "LOW";
    } else if(props.value > 25){
        sortLabel = "HIGH";
    } else {
        sortLabel = "VERY HIGH";
    }
    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label style={{float: "left"}}>Sort Order [{props.value} - {sortLabel}]</label>
                <input type="range" value={props.value} min={1} max={100} onChange={event => props.onChange(event.target.value)} className="form-control-range" id="formControlRange"/>
        </div>
    );
}

export const FormFieldAutocomplete = (props) => {

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor={props.name} style={{float: "left"}}>{props.name}{props.required && <>*</>}</label>
            <ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>
            <input type="text" value={props.value} id={props.name} onChange={(data) => props.setFieldValue(props.fieldName, data.target.value)} onBlur={() => props.onBlurHandler(props.fieldName, true)} className={["form-control", props.touched && props.errors ? classes.hasError : ""].join(" ")} placeholder={props.name}/>
        </div>
    );
}

export const FormFieldDisabled = (props) => {
    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor={props.name}>{props.name}</label>
            <input type="text" disabled value={props.value}
                   className="form-control" id={props.name} placeholder={props.name}/>
        </div>
    );
}

export const SingleDropZoneUploader = (props) => {
    return (
        <Dropzone
            ref={props.refObject}
            onChangeStatus={props.onChangeStatus}
            inputContent={props.inputContent}
            multiple={false}
            maxFiles={1}
            accept={props.accept}
            styles={{
                dropzone: { overflow: 'auto', border: '2px dashed #eeeeee', borderRadius: "2px", background: '#fafafa', color: "#bdbdbd", minHeight: "75px" },
                inputLabelWithFiles: { margin: '20px 3%' },
                inputLabel: {color:"#bdbdbd"},
                preview: {padding: "20px 3%"}
            }}
        />
    );
}

export const MultiDropZoneUploader = (props) => {
    return (
        <Dropzone
            ref={props.refObject}
            onChangeStatus={props.onChangeStatus}
            inputContent={props.inputContent}
            maxFiles={6}
            accept={props.accept}
            styles={{
                dropzone: { overflow: 'auto', border: '2px dashed #eeeeee', borderRadius: "2px", background: '#fafafa', color: "#bdbdbd", minHeight: "75px" },
                inputLabelWithFiles: { margin: '20px 3%' },
                inputLabel: {color:"#bdbdbd"},
                preview: {padding: "20px 3%"}
            }}
        />
    );
}

export const ImagePreviewerCustom = (props) => {
    return (
        <tr>
            <td>
                <img style={{width: "200px", height: "200px", objectFit: "cover", border: "1px solid #ccc", margin: "10px", borderRadius: "5px"}} src={`${CLOUDINARY_URL}/${props.image.url}`}/>
            </td>
            <td>{(props.size - 1) !== props.index && <button type="button" onClick={() => props.onRemove(props.index)} className="btn btn-danger">
                <MinusIcon/></button>}</td>
        </tr>
    );
}

export const ImageUploaderCustom = (props) => {
    return (
        <tr>
            <td>
                {!props.file ?
                    <input type="file" multiple={true} accept="image/x-png,image/gif,image/jpeg" onChange={(event) => props.onChange(event, props.index)}/> :
                    <img style={{width: "200px", height: "100px", objectFit: "cover"}} src={props.localUrl}/>
                }
            </td>
            <td>{(props.size - 1) !== props.index && <button type="button" onClick={() => props.onRemove(props.index)} className="btn btn-danger">
                <MinusIcon/></button>}</td>
        </tr>
    );
}

export const SearchCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== "" && props.entityServiceSearchCall){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="form-inline my-2 my-lg-0" style={{textAlign: "center", display: "block", paddingBottom: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}</label>
            <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control mr-sm-2" type="text" aria-label="Search"
                   onFocus={() => setShow(true)}
                   onBlur={() => setTimeout(() => setShow(false), 200)}
                   placeholder={"Search " + props.title}
                   value={props.searchText}
                   onChange={(event) => props.setSearchText(event.target.value)} />
            <div style={props.searchText == "" || options.length === 0 || !show ? {display: "none"} : {display: "block"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map( option => {
                            const field = `option.${props.fieldName}`;
                            return <span key={option.id} onClick={() => props.setSearchText(eval(field))}
                                  style={{cursor: "pointer"}} className="dropdown-item">{eval(field)}</span>
                        }
                    )
                }
            </div>

        </div>
    );
}

export const SearchSpecificIdCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="form-inline my-2 my-lg-0" style={{textAlign: "center", display: "block", paddingBottom: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}</label>
            <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control mr-sm-2" type="text" aria-label="Search"
                   onFocus={() => setShow(true)}
                   onBlur={() => setTimeout(() => setShow(false), 200)}
                   placeholder={"Search " + props.title}
                   value={props.searchText}
                   onChange={(event) => props.setSearchText(event.target.value)} />
            <div style={props.searchText == "" || options.length === 0 || !show ? {display: "none"} : {display: "block"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map( option => {
                            const field = `option.${props.fieldName}`;
                            return <span key={option.id} onClick={() => props.setSearchText(eval(field), option.id)}
                                         style={{cursor: "pointer"}} className="dropdown-item">{eval(field)}</span>
                        }
                    )
                }
            </div>

        </div>
    );
}

export const SingleAutoCompleteCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            {/*<ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>*/}
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group mb-3">
                <input id="dropdownMenuButton" data-toggle="dropdown" autoComplete={props.autocomplete == "off" ? "off" : "on"} className="form-control" type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       disabled={props.selectedId !== -1}
                       placeholder={"Search " + props.title}/>
                <div className="input-group-append">
                    <button onClick={props.valueClearCallback} disabled={props.selectedId === -1} className="btn btn-outline-danger" type="button" style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}>Change</button>
                </div>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                                const field = `option.${props.fieldName}`;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(eval(`option.${props.valueSelectField}`), eval(field))}
                                             style={{cursor: "pointer"}} className="dropdown-item">{eval(field)}</span>
                            }
                        )
                    }
                </div>
            </div>


        </div>
    );
}

export const CategoryMultiAutoCompleteCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            {/*<ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>*/}
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group">
                <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control" autoComplete={props.autocomplete == "off" ? "off" : "on"} type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       disabled={props.selectedIds.length === 5}
                       placeholder={"Search " + props.title}/>
                <div className="input-group-append">
                    <button onClick={props.valueClearCallback} disabled={props.selectedIds.length === 0} className="btn btn-outline-danger" type="button" style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}>Clear</button>
                </div>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                                const field = `option.${props.fieldName}`;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(eval(`option.${props.valueSelectField}`), eval(field), option.categoryChain)}
                                             style={{cursor: "pointer"}} className="dropdown-item">{option.categoryChain && option.categoryChain.slice(1, option.categoryChain.length).map(cat => <span key={cat.id}>{`${cat.name} > `}</span>)}<b>{eval(field)}</b></span>
                            }
                        )
                    }
                </div>
            </div>
            {props.selectedIds.map((cat, index) => <button title={cat.categoryChain && cat.categoryChain.slice(1, cat.categoryChain.length).map(c => `${c.name} > `)} onClick={() => props.onRemove(index)} type="button" style={{marginRight: "2px", marginTop: "2px"}} className="btn btn-primary btn-sm">
                {`${cat.id}: ${cat.value}`}<MinusIcon/>
            </button>)}
        </div>
    );
}

export const RelatedMultiAutoCompleteCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                ProductService.searchForProducts(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            {/*<ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>*/}
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group">
                <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control" autoComplete={props.autocomplete == "off" ? "off" : "on"} type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       autocomplete="off"
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       disabled={props.selectedIds.length === 5}
                       placeholder={"Search " + props.title}/>
                <div className="input-group-append">
                    <button onClick={props.valueClearCallback} disabled={props.selectedIds.length === 0} className="btn btn-outline-danger" type="button" style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}>Clear</button>
                </div>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                                const field = `option.${props.fieldName}`;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(eval(`option.${props.valueSelectField}`), eval(field), option.categoryChain)}
                                             style={{cursor: "pointer"}} className="dropdown-item">{option.categoryChain && option.categoryChain.slice(1, option.categoryChain.length).map(cat => <span key={cat.id}>{`${cat.name} > `}</span>)}<b>{eval(field)}</b></span>
                            }
                        )
                    }
                </div>
            </div>
            {props.selectedIds.map((cat, index) => <button title={cat.categoryChain && cat.categoryChain.slice(1, cat.categoryChain.length).map(c => `${c.name} > `)} onClick={() => props.onRemove(index)} type="button" style={{marginRight: "2px", marginTop: "2px"}} className="btn btn-primary btn-sm">
                {`${cat.id}: ${cat.name}`}<MinusIcon/>
            </button>)}
        </div>
    );
}

export const FacilitiesCMSComponent = (props) => {
    return (
        <table className="table" style={{marginTop: "20px"}}>
            <thead>
            <tr>
                <th scope="col">Facility</th>
                <th scope="col">Active</th>
                <th scope="col">Description</th>
            </tr>
            </thead>
            <tbody>
            {props.facilities.map(facility => <tr key={facility.id}>
                    <td>{facility.name}</td>
                    <td><input type="checkbox" style={{height: "30px", width: "30px"}} checked={facility.checked} onChange={(e) => props.onCheck(facility.facilityId, e)} className="form-check-input"/></td>
                    <td><input type="text" className="form-control" onChange={(e) => props.onDescriptionChange(facility.facilityId, e)} value={facility.description}/></td>
                </tr>
            )}
            </tbody>
        </table>
    );
}

export const TopAttractionsCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            {/*<ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>*/}
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group">
                <input id="dropdownMenuButton" data-toggle="dropdown" autoComplete={props.autocomplete == "off" ? "off" : "on"} className="form-control" type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       disabled={props.selectedIds.length === 10}
                       placeholder={"Search " + props.title}/>
                <div className="input-group-append">
                    <button onClick={props.addNewTag} disabled={props.searchText.length === 0} className="btn btn-outline-success" type="button" style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}>Add</button>
                </div>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                                const field = `option.${props.fieldName}`;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(eval(`option.${props.valueSelectField}`), eval(field), option.categoryChain)}
                                             style={{cursor: "pointer"}} className="dropdown-item">{option.categoryChain && option.categoryChain.slice(1, option.categoryChain.length).map(cat => <span key={cat.id}>{`${cat.name} > `}</span>)}<b>{eval(field)}</b></span>
                            }
                        )
                    }
                </div>
            </div>
            {props.selectedIds.map((cat, index) => <button key={index} title={cat.categoryChain && cat.categoryChain.slice(1, cat.categoryChain.length).map(c => `${c.name} > `)} onClick={() => props.onRemove(index)} type="button" style={{marginRight: "2px", marginTop: "2px"}} className="btn btn-primary btn-sm">
                {`${cat.name}`}<MinusIcon/>
            </button>)}
        </div>
    );
}

export const LocationCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group">
                <input id="dropdownMenuButton" data-toggle="dropdown" autoComplete={props.autocomplete == "off" ? "off" : "on"} className="form-control" type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       placeholder={"Search " + props.title}/>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                                const field = option;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(option)}
                                             style={{cursor: "pointer"}} className="dropdown-item"><b>{option}</b></span>
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export const CategoryChainAutoCompleteCMSComponent = (props) => {

    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.searchText !== ""){
            const timeout = setTimeout(() => {
                props.entityServiceSearchCall(props.searchText, 0, 5).then(
                    items => {
                        setOptions(items.data.content)
                    }
                )
            }, 1000)
            return () => clearTimeout(timeout);
        }
    }, [props.searchText]);

    return (
        <div className="col-md-6" style={{marginTop: "10px"}}>
            <label htmlFor="dropdownMenuButton">{props.title}{props.required?"*":null}</label>
            {/*<ErrorFormItem touched={props.touched} message={props.errors} style={{float: "right"}}/>*/}
            <div style={{color: "#721c24", fontSize: "0.7rem", float: "right"}}>{props.errors}</div>
            <div className="input-group mb-3">
                <input id="dropdownMenuButton" data-toggle="dropdown" className="form-control" type="text"  aria-label="Search" aria-describedby="basic-addon1" style={props.errors? {color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb"} : {}}
                       onFocus={() => setShow(true)}
                       onBlur={() => setTimeout(() => setShow(false), 200)}
                       value={props.searchText}
                       onChange={(event) => props.setSearchText(event.target.value)}
                       disabled={props.selectedId !== -1}
                       placeholder={"Search " + props.title}/>
                <div style={props.searchText == "" || options.length === 0 || !show ? {visibility: "collapse"} : {visibility: "visible"}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        options.map( option => {
                            const field = `option.${props.fieldName}`;
                                return <span key={option.id} onClick={() => props.valueSelectCallback(eval(`option.${props.valueSelectField}`), eval(field))}
                                             style={{cursor: "pointer"}} className="dropdown-item">{option.categoryChain && option.categoryChain.map(cat => <span key={cat.id}>{`${cat.name} > `}</span>)}<b>{eval(field)}</b></span>
                            }
                        )
                    }
                </div>
            </div>


        </div>
    );
}

export const SubmitCMSButton = (props) => {
    return (
        <button onClick={props.onSearch} className="btn btn-primary my-2 my-sm-0" type="submit">Search</button>
    );
}

export const ResetCMSButton = (props) => {
    return (
        <button onClick={props.onResetSearch} className="btn btn-warning my-2 my-sm-0" type="button" style={{marginLeft: "0.5rem"}}>Reset</button>
    );
}

export const ViewComponentLink = (props) => {
    return (
        <Link to={props.url}>
            {props.text} <FontAwesomeComponent name={"fa-link"}/></Link>
    );
}

export const ViewComponentExternalLink = (props) => {
    return (
        <a href={props.url} target="_blank">
            {props.text} <FontAwesomeComponent name={"fa-link"}/></a>
    );
}

export const NepaliCurrency = (props) => {
    return (
        <NumberFormat thousandSeparator={true} displayType={'text'} thousandsGroupStyle="lakh" prefix={'रू '} value={props.value}/>
    );
}

export const DateTimePicker = (props) => {
    const CustomInput = ({ value, onClick }) => (
        <input className="form-control mr-sm-2" type="text" value={value} placeholder={`Pick ${props.label}`} onClick={onClick}/>
    );
    return (
        <>
            <div className="form-inline my-2 my-lg-0" style={{textAlign: "center", display: "block", paddingBottom: "10px"}}>
                <label>{props.label}</label>
                <DatePicker customInput={<CustomInput />} showTimeSelect selected={props.selected} onChange={props.onChange} dateFormat="dd/MM/yyyy h:mm aa"/>
            </div>
        </>
    )
}

export const SelectCMSComponent = (props) => {
    return (
        <div className="form-inline my-2 my-lg-0" style={{textAlign: "center", display: "block", paddingBottom: "10px"}}>
            <label>{props.label}</label>
            <select className="form-control mr-sm-2" onChange={event => props.onChange(event.target.value)}>
                {props.list.map(
                    item => {
                        return (
                            <option key={item} value={item}>{item}</option>
                        );
                    }
                )}
            </select>
        </div>
    );
}

export const DateFormatCMSComponent = (props) => {
    return (
        <>
            {moment(props.value).format("DD/MM/YYYY hh:mm A")}
        </>
    );
}